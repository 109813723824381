import React, {
  createContext,
  useEffect,
  useCallback,
  useReducer
} from 'react';
import { Auth0Client } from '@auth0/auth0-spa-js';
import SplashScreen from 'src/components/SplashScreen';
import { auth0Config, auth0LoginRedirection } from 'src/config';
import GetLoggedInUser from '../api/GetLoggedInUser';

let auth0Client = null;

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  loggedInUser: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user, loggedInUser } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
        loggedInUser
      };
    }
    case 'LOGIN': {
      const { user, loggedInUser } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
        loggedInUser
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        loggedInUser: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'Auth0',
  loginWithPopup: () => Promise.resolve(),
  loginWithRedirect: () => Promise.resolve(),
  afterLoginRedirection: () => Promise.resolve(),
  getAccessToken: () => Promise.resolve(),
  logout: () => { }
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  
  /**
   * Gets access token for querying secured endpoints
   */
  const getAccessToken = async () => {
    try {
          const accessToken = await auth0Client.getTokenSilently();
          return { tokenAvailable: true, accessToken: accessToken };
        } catch(error) {
          console.log('Error getting access token: ', error.message);
          return { tokenAvailable: false, errorText: 'Error: API server is down or sub-account does not exist' };
        }
  }; 

  /**
   * Handles redirection after logging in via Auth0 universal login (basically, takes care of the flow after a user logs in successfully)   
   * @param {*} options represents the payload passed w.r.t redirection by the caller
   */
  const afterLoginRedirection = async (options) => {
    await auth0Client.handleRedirectCallback()
    await auth0Client.checkSession()
    const isAuthenticated = await auth0Client.isAuthenticated();
    if (isAuthenticated) {
      const user = await auth0Client.getUser();
      const {tokenAvailable, accessToken} = await getAccessToken()

      console.log("Access Token: ", accessToken)

      if (!tokenAvailable) {
        console.log('Error:'+accessToken);
      }
     
      //get the user info from the backend endpoint
      const loggedInUser = await GetLoggedInUser(accessToken)
      const defaultGroup = loggedInUser.userInfo.groups.sort((a, b) => a.group.id - b.group.id)[0].group;

      if (!sessionStorage.getItem('groupId')) {
        sessionStorage.setItem('groupId', defaultGroup.id)
      }
      
      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            id: user.sub,
            avatar: user.picture,
            email: user.email,
            name: user.name,
            tier: 'Premium'
          },
          loggedInUser: {
            id: loggedInUser.userInfo.id,
            emailAddress: loggedInUser.userInfo.emailAddress,
            fullName: loggedInUser.userInfo.fullName,
            preferredName: loggedInUser.userInfo.preferredName,
            adminRole: loggedInUser.userInfo.adminRole,
            groupName: defaultGroup.name,
            groups: loggedInUser.userInfo.groups
          }
        }
      });
    }
  }

 
  const loginWithRedirect = async(options) => {
    //here the await does not actually await because it transfers to a different page for fetching credentials, hence the afterLoginRedirect method
    await auth0Client.loginWithRedirect({
        ...options,
        ...auth0LoginRedirection,
    }); 
  }


  const loginWithPopup = async (options) => {
    //here the await actually awaits because it stays in the same page while the popup prompt is answered
    await auth0Client.loginWithPopup(options);
    
    const isAuthenticated = await auth0Client.isAuthenticated();
    
    if (isAuthenticated) {
      const user = await auth0Client.getUser();

      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            id: user.sub,
            avatar: user.picture,
            email: user.email,
            name: user.name,
            tier: 'Premium'
          }
        }
      });
    }
  };

  const logout = () => {
    auth0Client.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

    dispatch({
      type: 'LOGOUT'
    });
  };

  /**
   * initializes the auth context after it is loaded
   */
  const initialize = useCallback(async () => {
    try {
      auth0Client = new Auth0Client({
        ...auth0Config
      });

      await auth0Client.checkSession();

      const isAuthenticated = await auth0Client.isAuthenticated();
      if (isAuthenticated) {
        const user = await auth0Client.getUser();

        const {tokenAvailable, accessToken} = await getAccessToken()
        if (!tokenAvailable) {
          console.log('Error:'+accessToken);
        }
        
        //get the user info from the backend endpoint
        const loggedInUser = await GetLoggedInUser(accessToken)
        const defaultGroup = loggedInUser.userInfo.groups.sort((a, b) => a.group.id - b.group.id)[0].group;

        if (!sessionStorage.getItem('groupId')) {
          sessionStorage.setItem('groupId', defaultGroup.id)
        }

        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated,
            user: {
              id: user.sub,
              avatar: user.picture,
              email: user.email,
              name: user.name,
              tier: 'Premium'
            },
            loggedInUser: {
              id: loggedInUser.userInfo.id,
              emailAddress: loggedInUser.userInfo.emailAddress,
              fullName: loggedInUser.userInfo.fullName,
              preferredName: loggedInUser.userInfo.preferredName,
              adminRole: loggedInUser.userInfo.adminRole,
              groupName: defaultGroup.name,
              groups: loggedInUser.userInfo.groups
            }
          }
        });
      } else {
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated,
            user: null,
            loggedInUser: null
          }
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: 'INITIALISE',
        payload: {
          isAuthenticated: false,
          user: null,
          loggedInUser: null
        }
      });
    }

  },[]);
  useEffect(() => {
    initialize()
  },[initialize]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'Auth0',
        loginWithPopup,
        loginWithRedirect,
        afterLoginRedirection,
        getAccessToken,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;