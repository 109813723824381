import axios from 'axios';
import {apiConfig} from 'src/config';

const API = axios.create({
  baseURL: apiConfig.baseURL
});

export const fetcher = async (url, getToken) => {
  const {accessToken} = await getToken();

  return API
  .get(url, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
    },
  })
  .then((res) => res.data);

}

  export const getAuditLogV2 = (accessToken, coin, startDate, endDate, pageNumber, pageSize, sort, activityType, userEmail, subAccountName, groupIdsList, hideAuthLogs) =>  {
    let params = ''
    if (activityType) params = params + 'activityType=' + activityType + '&'
    if (userEmail) params = params + 'userEmail=' + userEmail.replaceAll('+', "%2B") + '&'
    if (subAccountName) params = params + 'subAccountName=' + subAccountName + '&'
    if (hideAuthLogs) params = params + 'hideAuthLogs=true&'
    return API.get(`v2/activity_log?${params}startDateUnixMs=${startDate}&endDateUnixMs=${endDate}&coin=${coin}&sort=${sort}&pageNumber=${pageNumber}&pageSize=${pageSize}${groupIdsList?.length ?
      `&${groupIdsList.map(i => `groupIdsList=${i.group.id}`).join('&')}` : ''}`, {
      headers:{'Authorization':'Bearer '+accessToken}
    })
  }

export const getActivityTypes = (accessToken) =>  {
  return API.get('/activity_log/activity_types', {
    headers:{'Authorization':'Bearer '+accessToken}
  })
}

export const getSubAccountHourlyHashrate = (subAccount, coin, startDate, endDate, accessToken) =>
  API.get('subaccount_hashrate_hour/' + subAccount + "?coin=" + 
    coin + "&startDateUnixMs=" + startDate + 
    "&endDateUnixMs=" + endDate, {
      headers:{'Authorization':'Bearer '+accessToken}
   });

export const getCumulatedSubAccountHourlyHashrate = (coin, startDate, endDate, accessToken, groupIdsList) => 
  API.get(`cumulated_subaccount_hashrate_hour?coin=${coin}&startDateUnixMs=${startDate}&endDateUnixMs=${endDate}
  ${groupIdsList.length ? `&${groupIdsList.map(i => `groupIdsList=${i.group.id}`).join('&')}` : ''}`, {
      headers:{'Authorization':'Bearer '+accessToken}
  });



export const getWorkerHourlyHashrate = (subAccount, workerId, coin, startDate, endDate, accessToken) =>
  API.get('worker_hashrate_hour/' + subAccount + "/" + workerId + "?coin=" + coin + 
    "&startDateUnixMs=" + startDate + "&endDateUnixMs=" + endDate, 
    {
      headers:{'Authorization':'Bearer '+accessToken}
    });

export const getSubAccountStats = (subAccount, coin, accessToken, tagName) =>
  API.get('subaccount_stats/' + subAccount + "?coin=" + coin +"&tagName="+tagName, {
    headers:{'Authorization':'Bearer '+accessToken}
 });

export const getCumulatedSubAccountStats = (coin, accessToken, groupIdsList) =>
  API.get(`cumulated_granted_subaccount_stats?coin=${coin}${groupIdsList.length ? `&${groupIdsList.map(i => `groupIdsList=${i.group.id}`).join('&')}` : ''}`, {
    headers:{'Authorization':'Bearer '+accessToken}
 });

export const getSubAccountDailyHashrate = (subAccount, coin, startDate, endDate, accessToken) =>
  API.get('subaccount_hashrate_day/' + subAccount + 
    "?coin=" + coin + "&start=" + startDate + 
    "&end=" + endDate, {
      headers:{'Authorization':'Bearer '+accessToken}
   });

export const getCumulatedSubAccountDailyHashrate = (coin, startDate, endDate, accessToken, groupIdsList) =>
  API.get(`cumulated_subaccount_hashrate_day?coin=${coin}&start=${startDate}&end=${endDate}${groupIdsList.length ? 
    `&${groupIdsList.map(i => `groupIdsList=${i.group.id}`).join('&')}` : ''}`, {
      headers:{'Authorization':'Bearer '+accessToken}
  });

export const getWorkerDailyHashrate = (subAccount, workerId, coin, startDate, endDate, accessToken) =>
  API.get('worker_hashrate_day/' + subAccount + "/" + workerId + "?coin=" + coin + 
    "&start=" + startDate + "&end=" + endDate, 
    {
      headers:{'Authorization':'Bearer '+accessToken}
    });


export const getWorkers = (subAccount, coin, sort, status, accessToken, tag,  pageNumber, pageSize, workerNameSearchStr) =>
  API.get('workers/' + subAccount + "?coin=" + coin + "&sort=" + sort+ 
    "&status=" + status
    +"&tag=" + tag
    +'&pageNumber='+pageNumber+'&pageSize='+pageSize
    +'&workerNameSearchStr='+encodeURIComponent(workerNameSearchStr)
    , {headers:{'Authorization':'Bearer '+accessToken}
 });

  
export const viewerGetSubAccountHourlyHashrate = (subAccount, coin, startDate, endDate, apiKey) =>
  API.get('subaccount_hashrate_hour/' + subAccount + "?coin=" + coin + 
    "&startDateUnixMs=" + startDate + "&endDateUnixMs=" + endDate,
  {
    headers:{'X-API-KEY':apiKey}
  });

  
export const viewerGetWorkerHourlyHashrate = (subAccount, workerId, coin, startDate, endDate, apiKey) =>
  API.get('worker_hashrate_hour/' + subAccount + "/" + workerId + "?coin=" + coin + 
    "&startDateUnixMs=" + startDate + "&endDateUnixMs=" + endDate, 
    {
      headers:{'X-API-KEY':apiKey}
    });


export const viewerGetSubAccountStats = (subAccount, coin, apiKey, tagName) =>
  API.get('subaccount_stats/' + subAccount + "?coin=" + coin+"&tagName="+tagName,
  {
    headers:{'X-API-KEY':apiKey}
  });

export const viewerGetSubAccountDailyHashrate = (subAccount, coin, startDate, endDate, apiKey) =>
  API.get('subaccount_hashrate_day/' + subAccount + "?coin=" + coin + 
    "&start=" + startDate + "&end=" + endDate,
  {
    headers:{'X-API-KEY':apiKey}
  });

export const viewerGetWorkerDailyHashrate = (subAccount, workerId, coin, startDate, endDate, apiKey) =>
  API.get('worker_hashrate_day/' + subAccount + "/" + workerId + "?coin=" + coin + 
    "&start=" + startDate + "&end=" + endDate, 
    {
      headers:{'X-API-KEY':apiKey}
    });

export const viewerGetWorkers = (subAccount, coin, sort, status, apiKey, tag, pageNumber, pageSize, workerNameSearchStr) =>
  API.get('workers/' + subAccount + "?coin=" + coin + "&sort=" + sort+ "&status=" + status + "&tag=" + tag+"&pageNumber="+pageNumber+"&pageSize="+pageSize+
  '&workerNameSearchStr='+encodeURIComponent(workerNameSearchStr),
  {
    headers:{'X-API-KEY':apiKey}
  });

export const getCoins = () =>
  API.get('coins');

/**
 * Fetches all the sub accounts in the pool  
 * @param {*} accessToken 
 * @param {*} coin 
 */
export const getAllSubAccounts = (accessToken, coin) =>
  API.get('subaccount_stats?coin='+coin,
   {
      headers:{'Authorization':'Bearer '+accessToken}
   }
  );

  /**
 * Gets all the sub accounts owned by a user/account
 * @param {*} accessToken 
 * @param {*} coin 
 */
export const getAllSubAccountsAssociatedWithAUser = (accessToken, coin, groupIdsList) =>
API.get(`granted_subaccount_stats?coin=${coin}${groupIdsList.length ? `&${groupIdsList.map(i => `groupIdsList=${i.group.id}`).join('&')}` : ''}`,
  {
    headers:{'Authorization':'Bearer '+accessToken}
  }
);

/**
 * Fetches the blocks mined by the pool
 * @param {*} coin 
 * @param {*} accessToken 
 */
export const getBlocks = (coin, accessToken, startDate, pageNumber, pageSize, sort) =>
  API.get('blocks?coin=' + coin+'&startDateUnixMs='+startDate+'&pageNumber='+pageNumber+'&pageSize='+pageSize+'&sort='+sort,
    {
      headers:{'Authorization':'Bearer '+accessToken}
    }
  );


export const getTransactions = (subAccount, coin, accessToken, startDate, endDate) =>
  API.get('transactions/' + subAccount + "?coin=" + coin+ "&startDateUnixMs=" + startDate + "&endDateUnixMs=" + endDate, 
    {
      headers:{'Authorization':'Bearer '+accessToken}
    }
  );

export const getEarnings = (subAccount, coin, accessToken, startDate, endDate) =>
  API.get('earnings/' + subAccount + "?coin=" + coin+ "&startDateUnixMs=" + startDate + "&endDateUnixMs=" + endDate, 
    {
      headers:{'Authorization':'Bearer '+accessToken}
    }
  );

export const getPoolHourlyHashrate = (coin, startDate, endDate, accessToken) =>
  API.get('pool_hashrate_hour?coin=' + coin + "&startDateUnixMs=" + startDate + "&endDateUnixMs=" + endDate, 
    {
      headers:{'Authorization':'Bearer '+accessToken}
    }
  );

export const getPoolDailyHashrate = (coin, startDate, endDate, accessToken) =>
  API.get('pool_hashrate_day?coin=' + coin + "&start=" + startDate + "&end=" + endDate, 
    {
      headers:{'Authorization':'Bearer '+accessToken}
    }
  );

/**
 * Fetches the statistics of the pool (Workers, Avg Hashrate)
 * @param {*} coin 
 * @param {*} accessToken 
 */
export const getPoolStats = (coin, accessToken) =>
  API.get('pool_stats?coin=' + coin, 
    {
      headers:{'Authorization':'Bearer '+accessToken}
    }
  );

export const getSubAccountBalance = (subAccount, coin, accessToken) =>
  API.get('balance/' + subAccount + "?coin=" + coin, 
  {
    headers:{'Authorization':'Bearer '+accessToken}
  }
);

export const getLoggedInUser = (accessToken) =>
API.get('/users/logged-in-user',
  {
    headers:{'Authorization':'Bearer '+accessToken}
  }
);

export const getAutoWithdrawalParams = (subAccount, coin, accessToken) =>
  API.get('/settings/autoWithdrawParams/'+subAccount+'?coin='+coin,
  {
    headers:{'Authorization':'Bearer '+accessToken}
  }
);

export const getActiveWithdrawalAddress = (subAccount, coin, accessToken) =>
  API.get('/wallets/autoWithdrawalAddress/'+subAccount+'?coin='+coin,
  {
    headers:{'Authorization':'Bearer '+accessToken}
  }
);

export const getWithdrawalAddresses = (subAccount, coin, accessToken) =>
  API.get('/wallets/addresses/'+subAccount+'?coin='+coin,
  {
    headers:{'Authorization':'Bearer '+accessToken}
  }
);

export const addWithdrawalAddress = (subAccount, coin, addressLabel, address, accessToken) =>
  API({
      method: 'PUT',    
      url:'/wallets/addresses/'+subAccount+'/'+addressLabel+'/'+address,
      params: {
        coin: coin
      },
      headers: {
        'Authorization': 'Bearer '+accessToken
      }
  });

export const setActiveWithdrawalAddress = (subAccount, coin, address, accessToken) =>
  API({
      method: 'PUT',    
      url:'/wallets/autoWithdrawalAddress/'+subAccount+'/'+address,
      params: {
        coin: coin
      },
      headers: {
        'Authorization': 'Bearer '+accessToken
      }
  });

export const deactivateWithdrawalAddress = (subAccount, coin, address, accessToken) =>
  API({
      method: 'PUT',    
      url:'/wallets/deactivateAutoWithdrawal/'+subAccount+'/'+address,
      params: {
        coin: coin
      },
      headers: {
        'Authorization': 'Bearer '+accessToken
      }
  });

export const setAutoWithdrawParams = (subAccount, coin, auto, accessToken) =>
  API({
      method: 'PUT',    
      url:'/settings/autoWithdrawParams/'+subAccount+'/'+coin,
      params: {
        auto: auto
      },
      headers: {
        'Authorization': 'Bearer '+accessToken
      }
  });

export const deleteWorkers = (subAccount, coin, accessToken, workersIdList,	lastShareTime	) =>
  API.delete(`workers/${subAccount}/${coin}${workersIdList.length?"":`/?lastShareTime=${lastShareTime}`}`,
  {
    headers:{
      'Authorization':'Bearer '+accessToken    
    },
    
    data: workersIdList
  });

export const createSubAccountAndAddWithdrawalAddressV2 = (subAccountName, groupId, coin, addressLabel, address, userSubAccountAccess, accessToken) => {
  if(userSubAccountAccess !== null){
    return (
        API({
          method: 'POST',    
          url: 'v2/sub_account/'+subAccountName+'/group/'+groupId+'/'+addressLabel+'/'+address,
          params: {
            coin: coin
          },
          data:{ userSubAccountAccess },
          headers: {
            'Authorization': 'Bearer '+accessToken
          }
      })
    )
  }
  else {
    return (
      API({
        method: 'POST',    
        url: 'v2/sub_account/'+subAccountName+'/group/'+groupId+'/'+addressLabel+'/'+address,
        params: {
          coin: coin
        },
        headers: {
          'Authorization': 'Bearer '+accessToken
        }
    })
  )
  }

}

export const authenticate = (apiKey) =>
  API.get('/authentication_test',
  {
    headers:{'X-API-KEY':apiKey}
  }
);

export const getAPIKeys = (subAccount, role, accessToken) =>
  API.get('/api_keys/'+subAccount+'?role='+role,
  {
    headers:{'Authorization':'Bearer '+accessToken}
  }
);

export const createAPIKey = (subAccount, linkName, role, accessToken) =>
API({
      method: 'PUT',    
      url:'/api_keys/'+subAccount,
      params: {
        keyName: linkName,
        role: role
      },
      headers: {
        'Authorization': 'Bearer '+accessToken
      }
  });

export const deleteAPIKey = (subAccount, apiKey, accessToken) =>
  API.delete('api_keys/'+subAccount+'/'+apiKey,
  {
    headers:{'Authorization':'Bearer '+accessToken},
    // data: payload
  });

export const getSubAccountFinancials = (accessToken, coin, startDate, endDate) =>
  API.get('financial_stats?coin='+coin+ 
  "&startDateUnixMs=" + startDate + "&endDateUnixMs=" + endDate,
   {
      headers:{'Authorization':'Bearer '+accessToken}
   }
  );

export const getFinancialStatsForSubAccounts = (accessToken, coin, startDate, endDate, groupIdsList) =>
  API.get('financial_overview?coin='+coin+ 
  "&startDateUnixMs=" + startDate + "&endDateUnixMs=" + endDate + `${groupIdsList.length ? `&${groupIdsList.map(i => `groupIdsList=${i}`).join('&')}` : ''}`,
   {
      headers:{'Authorization':'Bearer '+accessToken}
   }
  );

export const getWorkerTags = (coin, subAccount, accessToken) =>
  API.get('/tags/'+coin+'/'+subAccount,
  {
    headers:{'Authorization':'Bearer '+accessToken}
  }
);

export const viewerGetWorkerTags = (coin, subAccount, apiKey) =>
  API.get('/tags/'+coin+'/'+subAccount,
  {
    headers:{'X-API-KEY':apiKey}
  }
);

export const createWorkerTag = (coin, subAccount, tagName, accessToken) =>
API({
      method: 'PUT',    
      url:'/tags/'+coin+'/'+subAccount+'/'+tagName,
      headers: {
        'Authorization': 'Bearer '+accessToken
      }
  });

export const deleteWorkerTag = (coin, subAccount, tagName, accessToken) =>
  API.delete('/tags/'+coin+'/'+subAccount+'/'+tagName,
  {
    headers:{'Authorization':'Bearer '+accessToken},
    // data: payload
  });

export const tagWorkers = (coin, subAccount, tagName, accessToken, payload) =>
  API({
    method: 'PUT',    
    url:'/tags/tag/'+coin+'/'+subAccount+'/'+tagName,
    data: payload,
    headers: {
      'Authorization': 'Bearer '+accessToken
    }
  });

export const untagWorkers = (coin, subAccount, accessToken, payload) =>
  API.delete('/tags/untag/'+coin+'/'+subAccount,
  {
    headers:{'Authorization':'Bearer '+accessToken},
    data: payload
  });

export const getTagAlerts = (coin, subAccount, accessToken) =>
  API.get('/alerts/tagAlerts/'+coin+'/'+subAccount,
  {
    headers:{'Authorization':'Bearer '+accessToken}
  }
);

export const createTagAlert = (coin, subAccount, tagName, hashrateThreshold, activeWorkersThreshold, payload, accessToken) => {
  
  var url = '/alerts/tagAlerts/'+coin+'/'+subAccount+'/'+tagName+'?activeWorkersThreshold='+activeWorkersThreshold+'&hashrateThreshold='+hashrateThreshold
  
  return API({
    method: 'PUT',    
    url:url,
    data: payload,
    headers: {
      'Authorization': 'Bearer '+accessToken
    }
  });

}
  
export const deleteAlert = (coin, subAccount, alertId, accessToken) =>
  API.delete('/alerts/'+coin+'/'+subAccount+'/'+alertId,
  {
    headers:{'Authorization':'Bearer '+accessToken},
  });

export const addEmailToAlert = (coin, subAccount, alertId, emailAddress, accessToken) =>
  API({
    method: 'PUT',    
    url:'/alerts/addEmail/'+coin+'/'+subAccount+'/'+alertId+'/'+emailAddress,
    headers: {
      'Authorization': 'Bearer '+accessToken
    }
  });

export const removeEmailFromAlert = (coin, subAccount, alertId, emailAddress, accessToken) =>
  API.delete('/alerts/removeEmail/'+coin+'/'+subAccount+'/'+alertId+'/'+emailAddress,
  {
    headers:{'Authorization':'Bearer '+accessToken},
  });

export const getRoleInfo = (subAccount, accessToken) =>
  API.get('/get_role/'+subAccount,
  {
    headers:{'Authorization':'Bearer '+accessToken}
  }
);

export const createGroup = (groupName, accessToken) =>
  API({
      method: 'PUT',    
      url:'/group/'+groupName,
      headers: {
        'Authorization': 'Bearer '+accessToken
      }
  });
  
export const getAllUsersAssociatedWithAGroupV2 = (accessToken) => 
  API.get('v2/users/users-by-groups',
    {
      headers:{'Authorization':'Bearer '+accessToken}
    }
)

export const getAllGroupsAndUsersV2 = (accessToken) =>
  API.get('v2/user-groups',
    {
      headers:{'Authorization':'Bearer '+accessToken}
    }
);

export const addUserToMultipleGroups = (accessToken, bodyFormData) => 
  API({
    method: 'POST',
    url: 'v2/users',
    data: bodyFormData,
    headers: {
      'Authorization': 'Bearer '+ accessToken
    }
  });

export const deleteUserFromGroupV2 = (userId, accessToken, groupId) => 
  API.delete(`v2/users/${userId}?groupIdsList=${groupId}`,
  {
    headers:{'Authorization':'Bearer '+accessToken}
  }
  )

export const updateUserRoleNameV2 = (userId, groupId, updatedRole, accessToken) =>
  API({
    method: 'PUT',    
    url:'v2/users/'+userId+'/group/'+groupId+'/role/'+updatedRole,
    headers: {
      'Authorization': 'Bearer '+accessToken
    }
});
  
export const getWorkerCounts = (subAccount, coin, tag, accessToken) =>
API.get('/workers/workerCounts/'+subAccount+"?coin="+coin+"&tagName="+tag,
  {
    headers:{'Authorization':'Bearer '+accessToken}
  }
);

export const viewerGetWorkerCounts = (subAccount, coin, tag, apiKey) =>
API.get('/workers/workerCounts/' + subAccount + "?coin=" + coin + "&tagName=" + tag,
  {
    headers:{'X-API-KEY':apiKey}
  }
);

export const getTaggedWorkerCount = (coin, subAccount, accessToken) =>
API.get('/tags/taggedWorkerCount/'+coin+'/'+subAccount,
  {
    headers:{'Authorization':'Bearer '+accessToken}
  }
);

export const viewerGetTaggedWorkerCount = (coin, subAccount, apiKey) =>
  API.get('/tags/taggedWorkerCount/'+coin+'/'+subAccount,
  {
    headers:{'X-API-KEY':apiKey}
  }
);

export const archiveSubAccount = (subAccount, accessToken) => 
  API({
    method: 'PUT',    
    url:`/sub_account/archive/${subAccount}`,
    headers: {
      'Authorization': 'Bearer '+accessToken
    }
  });

export const saveAutoWithdrawal = (subAccount, coin, accessToken, walletAllocationRequestList) =>
  API({
    method: 'POST',
    url: '/wallets/saveAutoWithdrawalDetails/'+subAccount+'/'+coin,
    data: walletAllocationRequestList,
    headers: {
      'Authorization': 'Bearer '+accessToken
    }
  });
  
export const approvalRequest = (approvalRequestId, accessToken, action) =>
API({
  method: 'POST',
  url: '/approvals/'+approvalRequestId,
  data: { "action": action },
  headers: {
    'Authorization': 'Bearer '+accessToken
  }
});


export const addUserSubAccountRole = (userId, subAccountName, subAccountRoleName, accessToken) => 
  API({
    method: 'PUT',    
    url: `/users/${userId}/sub-account_role/${subAccountName}`,
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'text/plain',
    },
    data: subAccountRoleName
  });

export const updateUserSubAccountRole = (userId, subAccountName, subAccountRoleName, accessToken) => 
  API({
    method: 'PUT',    
    url: `/users/${userId}/sub-account_role/${subAccountName}/${subAccountRoleName}`,
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });

export const deleteUserSubAccountRole = (userId, subAccountName, accessToken) => 
  API({
    method: 'DELETE',    
    url: `/users/${userId}/sub-account_role/${subAccountName}`,
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });

export const getResetUserMFA = (userId, accessToken) => 
  API.get('/users/resetMFA/'+userId,
    {
      headers:{'Authorization':'Bearer '+accessToken}
    }
  );

export const checkIfUserExistsByEmail = (userEmail, accessToken) =>
  API.get('/users/get-mutual-groups?userEmail='+encodeURIComponent(userEmail),
    {
      headers:{'Authorization':'Bearer '+accessToken}
    }
  );

export default API;
