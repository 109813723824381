import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Collapse,
  ListItem,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@emotion/react';
import { icons } from 'lucide-react';


const useStyles = makeStyles()((theme, _params, classes) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      [`& .${classes.title}`]: {
        fontWeight: theme.typography.fontWeightMedium
      }
    }
  },
  metalButtonLeaf: {
    color: theme.palette.text.secondary,
    padding: '8px 8px 8px 0',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover':{
      color: 'var(--accent-contrast-9) !important',
      backgroundColor: 'var(--accent-9)'
    },
    '&.depth-0': {
      [`& .${classes.title}`]: {
        fontWeight: theme.typography.fontWeightMedium
      }
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    minWidth: 18
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    [`& .${classes.title}`]: {
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightMedium
    },
    [`& .${classes.icon}`]: {
      color: theme.palette.secondary.main
    }
  }
}));

const NavItem = ({
  children,
  className,
  depth,
  href,
  target,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
  metal,
  ...rest
}) => {
  const { classes, cx } = useStyles();
  const [open, setOpen] = useState(openProp);
  const theme = useTheme();
  const LucideIcon = icons[Icon];
  const ChevronUp = icons['ChevronUp'];
  const ChevronDown = icons['ChevronDown'];

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        className={cx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          className={classes.button}
          onClick={handleToggle}
          style={style}
        >
          <LucideIcon className={classes.icon} size={20}/>
          <span className={classes.title}>
            {title}
          </span>
          {open ? ( 
            <ChevronUp
              size={20}
              color={theme.palette.primary.main}
            /> 
          ) : (
            <ChevronDown
              size={20}
            />
          )}
        </Button>
        <Collapse in={open}>
          {children}
        </Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem
      className={cx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
    >
      <Button
        className={metal ? cx(classes.metalButtonLeaf, `depth-${depth}`) : cx(classes.buttonLeaf, `depth-${depth}`)}
        component={RouterLink}
        style={({ isActive }) => isActive ? ({ color: metal ? "var(--color-text-accent)" : theme.palette.secondary.main,
        fontWeight: theme.typography.fontWeightMedium, paddingLeft: paddingLeft }) : ({ paddingLeft: paddingLeft })}
        to={href}
        target={target}
      >
        <LucideIcon className={classes.icon} style={{ marginLeft: -1 }} size={20}/>
        <span className={classes.title}>
          {title}
        </span>
        {Info && <Info className={classes.info}/>}
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  target: PropTypes.string,
  icon: PropTypes.string,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  metal: PropTypes.bool
};

NavItem.defaultProps = {
  open: false
};

export default NavItem;
