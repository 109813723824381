export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

// For coinUnit
export const BTC = 'BTC';
export const BCH = 'BCH';
export const SATS = 'Sats';
export const BITCOIN_CONVERSION_NUM = 8;
export const SATOSHI_CONVERSION_NUM = 100000000;

// For date pickers - mm/dd/yyyy (date format compatible with Chrome and Firefox)
export const EARLIEST_DATE_FOR_PICKERS = '01/01/2020'

// For Idle Timeout
// export const IDLE_TIMEOUT = 10000 // 10 seconds for testing
export const IDLE_TIMEOUT = 1*4*60*60*1000; // 4 hours being idle will fire off the session timeout dialog pop up

// For date pickers - time range selection options (Days, Hours, Minutes, Seconds, Milliseconds)
export const TWENTY_FOUR_HOURS = 1*24*60*60*1000;
export const SEVEN_DAYS = 7*24*60*60*1000;
export const THIRTY_DAYS = 30*24*60*60*1000; // Will be current day + last full 30 days 
export const NINETY_DAYS = 90*24*60*60*1000;
export const THREE_HUNDRED_SIXTY_FIVE_DAYS = 365*24*60*60*1000;
export const ALL_DATES = 0;
export const CUSTOM_DATE = -1;
export const TODAYS_DATE = -2;
export const YESTERDAYS_DATE = -3;