import { authenticate } from './API';

/**
 * Fetches coins supported by the mining pool 
 */
const AuthenticateAPIKey = async (apiKey) => {
  try {
    const response = await authenticate(apiKey);
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: error.response.data.message};
  }
};

export default AuthenticateAPIKey;