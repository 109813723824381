import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
    Box,
    Button,
    SvgIcon,
    CircularProgress,
    InputLabel,
    Select,
    FormControl,
    MenuItem,
    inputBaseClasses,
    inputLabelClasses,
  } from '@mui/material';
import { Download } from 'lucide-react';
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { getLocalTimeZoneAndOffset } from 'src/api/DateFormatter';
import { Document, Page, Text, View, pdf, StyleSheet, Font, Path, G, Svg, Link, Circle, Polygon, Rect } from '@react-pdf/renderer';
import {saveAs} from 'file-saver';
import useAuth from 'src/hooks/useAuth';
import { bchInfoWebsite, btcInfoWebsite } from 'src/config';
import PlusJakartaSans from '../assets/fonts/PlusJakartaSans.ttf';
import "../../node_modules/@foundry/metal/dist/css/metal.css";
import useSelectedGroup from 'src/hooks/useSelectedGroup';

const useStyles = makeStyles()((theme) => ({
  actionIcon: {
    marginRight: theme.spacing(0.5)
  },
  pdfBtn: {
    color: 'inherit',
    textDecoration: 'none'
  },
  exportSelect: {
    transform: 'translate(14px, 9px) scale(1) !important',
    pointerEvents: 'none !important',
    maxWidth: 'calc(100% - 24px) !important'
  },
  redesignBtn: {
    backgroundColor: 'rgba(0,0,0,0)',
    boxShadow: 'none',
    color: 'var(--accent-11)',
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: 'var(--accent-5)',
    }
  },
  redesignDropDown: {
    backgroundColor: 'var(--neutral-2)',
    border: '1px solid var(--color-border)',
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'var(--accent-11)'
    },
    '& .MuiMenuItem-root:hover': {
      backgroundColor: 'rgba(255,255,255, 0.1)'
    },
  },
  inputLabel: {
    '&.MuiInputLabel-root.Mui-focused': {
      color: 'var(--accent-11)'
    }
  }
}));

const ExportCSVComponent = (props) => {
    const { t } = useTranslation();
    const { classes, cx } = useStyles(); 
    const [csvData, setCsvData] = useState([])
    const csvLink = useRef();

    //new syntax
    const exportInProcess = props.exportInProcess
    const fileName = props.fileName
    const singleDate = props.singleDate;
    const reportDate = props.reportDate;
    const startDate = props.startDate;
    const endDate = props.endDate;
    const subAccount = props.subAccount;
    const reportName = props.reportName;
    const groupReport = props.groupReport;
    const coin = props.coin;
    const [selectedExportOption, setSelectedExportOption] = useState('');
    const [open, setOpen] = useState(false);
    const [buildingPdf, setBuildingPdf] = useState(false)
    const { loggedInUser } = useAuth();
    const pdfOption = props.pdfOption;
    const fetchCSVData = props.getCSVData;
    const dropdownRef = useRef();
    const labelRef = useRef();
    const [exportFocused, setExportFocused] = useState(false);
    const { selectedGroup } = useSelectedGroup();

    const handleExportButtonClick = () => {
      setSelectedExportOption('csv')
      setCsvData([])
      getCSVData('csv')
    }
    const handleExportMenuUpdate = (event) => {
        setOpen(false)
        if (event.target.value && event.target.value === 'csv') {
          getCSVData('csv')
          setSelectedExportOption('csv')
        } else if (event.target.value && event.target.value === 'pdf') {
          setBuildingPdf(true)
          getCSVData('pdf')
          setSelectedExportOption('pdf')
        }
    }
    const getCSVData = async (exportType) => {
        const data = reportName === 'Financial Overview Report' && exportType === 'pdf' ? await fetchCSVData('pdf') : await fetchCSVData();
        if(data && data.length !== 0 && exportType === 'csv'){
          setCsvData(data)
        } else if (data && data.length !== 0 && exportType === 'pdf' && (reportName === 'Transactions Report')) {
          const pdfData = data.map(d => {
            return {
              ...d,
              'Transaction id':  d['Transaction id'] ? {
                display: `${d['Transaction id'].slice(0,4)}...${d['Transaction id'].slice(-4)}`,
                url: coin === 'BTC' ? `${btcInfoWebsite}/tx/${d['Transaction id']}`:`${bchInfoWebsite}/transaction/${d['Transaction id']}`
              } : { display: '', url: ''},
              Address: d.Address ? { 
                display: `${d.Address.slice(0,4)}...${d.Address.slice(-4)}`, 
                url: coin === 'BTC' ? `${btcInfoWebsite}/address/${d.Address}`:`${bchInfoWebsite}/address/${d.Address}`
              } : { display: '', url: ''}
            }
          })
          setCsvData(pdfData)
        } else if (data && data.length !== 0 && exportType === 'pdf' && (reportName === 'Sub-Accounts Report')) {
          const pdfData = data.map(d => {
            return {
              'Name': d['Name'],
              'Hashrate1hrAvg': d['Hashrate1hrAvg'],
              'Active Workers': d['Active Workers'],
              'Offline (>15 Min)': d['Offline (>15 Min)'],
              'Offline (>24 Hr)': d['Offline (>24 Hr)']
            }
          })
          setCsvData(pdfData)
        } else if (data && data.length !== 0 && exportType === 'pdf' && (reportName !== 'Transactions Report' || reportName === 'Sub-Accounts Report')) {
          setCsvData(data)
        } else {
          setCsvData([])
          setSelectedExportOption('')
          setBuildingPdf(false)
          resetExportDropdown();
        }
    }

    const getFileName = () => {
      if (singleDate) {
        return `${fileName} ${dayjs().format('YYYY-MM-DDTHH_mm_ss')}${getLocalTimeZoneAndOffset().split(' ')[0].replace(':','_')}`;
      } else if (!singleDate && (startDate && endDate) && (startDate === endDate)) {
        return `${fileName} ${startDate}`;
      } else if (startDate && endDate) {
        return `${fileName} ${dayjs(startDate).format('YYYY-MM-DDTHH_mm')} ${getLocalTimeZoneAndOffset()} – ${dayjs(endDate).format('YYYY-MM-DDTHH_mm')} ${getLocalTimeZoneAndOffset()}`;
      } else {
        return `${fileName} ${dayjs().format('YYYY-MM-DDTHH_mm_ss')}${getLocalTimeZoneAndOffset().split(' ')[0].replace(':','_')}`;
      }
    }

    const handleClose = () => {
      setOpen(false);
      setExportFocused(false)
    };
  
    const handleOpen = () => {
      dropdownRef.current?.classList.add(inputBaseClasses.focused);
      labelRef.current?.classList.add(inputLabelClasses.focused);
      labelRef.current?.classList.remove(classes.exportSelect);
      setOpen(true);
    };

    Font.registerHyphenationCallback(word => {
      if (word.length >= 11) {
        const partsArray = []
        for(let i=0; i <= word.trim().length; i = i + 4) {
          partsArray.push(word.substring(i, i+4))
        }
        return partsArray;
      }

      const middle = Math.floor(word.length / 2);
      const parts = word.length === 1 || word.includes('@') ? [word] : [word.substring(0, middle), word.substring(middle)];

      return parts;
    });

    const getMultipleDatesStr = () => {
      const start = dayjs(startDate);
      const end = dayjs(endDate);
      return `${start.format('MMMM DD, YYYY')} - ${end.format('MMMM DD, YYYY')}`      
    }
    
    Font.register({ family: 'Plus Jakarta Sans', src: PlusJakartaSans, format: 'truetype'});

    const pdfStyles = StyleSheet.create({
      page: {
        fontSize: 9,
        fontFamily: 'Plus Jakarta Sans'
      },
      tableHeaderRow: { 
        display: "flex", 
        flexDirection: "row",
        borderTop: '1px solid #000',
        borderBottom: '1px solid #000',
        paddingRight: '8pt'
      },
      evenRow: {
        display: "flex", 
        flexDirection: "row",
        backgroundColor: 'rgb(239, 240, 241)',
        paddingRight: '8pt'
      },
      oddRow: {
        display: "flex", 
        flexDirection: "row",
        paddingRight: '8pt'
      },
      smallColumn: { display: "flex", flexDirection: "column", width: 140},
      medColumn: { display: "flex", flexDirection: "column", width: 200 },
      lgColumn: { display: "flex", flexDirection: "column", width: 210},
      xlColumn: { display: "flex", flexDirection: "column", width: 240}, 
      th: {
        display: "flex",
        justifyContent: "flex-start",
        alignContent: 'center',
        padding: '8pt 0 8pt 8pt',
      },
      td: {
        display: "flex",
        justifyContent: "flex-start",
        padding: '8pt 0 8pt 8pt',
       },
      header: {
        padding: '8pt',
      },
      headerRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      footer: {
        borderTop: '1px solid #000',
        padding: '8pt 16pt 8pt 8pt',
        marginTop: 'auto',
      },
      footerTxt: {
        fontSize: 10.5
      },
    })

    const createPdf = async () => {
     if (csvData && csvData.length && !open) {
        const doc = <Report 
          data={csvData} 
          columns={csvData.length === 1 ? Object.keys(csvData[0]) : Object.keys(csvData[1])}
        />;
        const asPdf = pdf([]);
        asPdf.updateContainer(doc)
        const blob = await asPdf.toBlob();
        saveAs(blob, `${getFileName()}.pdf`);
        setBuildingPdf(false);
        setSelectedExportOption('');
        resetExportDropdown();
      } else {
        return
      }
    }

    useEffect(() => {
      if (!open && csvData?.length && selectedExportOption === 'pdf') {
        setTimeout(() => {
          createPdf()
        }, 1000)
      }

      if (csvData.length && selectedExportOption === 'csv') {
        setTimeout(() => {
          csvLink.current.link.click()
          setSelectedExportOption('')
          resetExportDropdown()
        })
      }
    }, [csvData])

    useEffect(() => {
      if (exportFocused) {
        labelRef.current?.classList.add(inputLabelClasses.focused);
      }

    }, [exportFocused])

    const resetExportDropdown = () =>{
      setTimeout(() => {
        dropdownRef.current?.classList.remove(inputBaseClasses.focused);
        labelRef.current?.classList.remove(inputLabelClasses.focused);
        labelRef.current?.classList.add(classes.exportSelect);
      }, 50)
      setCsvData([])
      setExportFocused(false)
    }

    function Report (props) {
        const data = props.data;
        const columns = props.columns;
        const fileName = getFileName();
        return (
          <Document style={pdfStyles.page}>
            <Page size="A4" orientation={ reportName === 'Financial Overview Report' || reportName === 'Transactions Report' ? 'landscape' : 'portrait'}>
              <View style={pdfStyles.header} fixed>
                  <View style={pdfStyles.headerRow}>
                    <View>
                      <Svg
                        height='40'
                        viewBox="30 -10 200 110"
                        style={{ width: '95' }}
                      >
                      <G>
                        <Circle fill='black' cx="24" cy="8" r="8"/>
                        <Circle fill='black' cx="8" cy="20" r="8"/>
                        <Circle fill='black' cx="8" cy="44" r="8"/>
                        <Circle fill="#ff8200" cx="24" cy="32" r="8"/>
                        <Circle fill="#ff8200" cx="24" cy="56" r="8"/>
                        <Circle fill="#ff8200" cx="8" cy="68" r="8"/>
                        <G>
                          <Path fill='black' d="M53.5,8.3c-.12.02-.24.04-.36.06-.89.14-1.55.57-1.86,1.45-.23.67-.17,1.37-.18,2.06v.53h-3.11v2.22h3.13v8.82h3.08v-8.83h4.28v-2.22h-4.3v-1.28c0-.65.16-.8.81-.8h3.52v-2.01h-5.01Z"/>
                          <Path fill='black' d="M115.37,14.32c-.04-.12-.08-.18-.09-.24-.17-.99-.74-1.69-1.57-2.21-.91-.56-1.93-.77-2.97-.82-1.34-.06-2.67.05-3.92.6-1.21.53-2.09,1.39-2.57,2.61-.69,1.75-.72,3.55-.25,5.35.53,2.02,1.89,3.21,3.89,3.69,1.26.3,2.54.34,3.82.12.93-.16,1.8-.47,2.52-1.1.58-.51.94-1.14,1.14-1.9v3.01h3.04v-15.05h-3.04v5.94Zm0,4.84c0,.76-.29,1.15-1,1.4-.74.26-1.51.34-2.29.39-1.03.07-2.04.05-3.04-.21-1.17-.3-1.91-1.04-2.14-2.23-.17-.88-.17-1.76.04-2.63.3-1.22,1.16-1.85,2.34-2.1.99-.21,1.98-.22,2.99-.14.76.06,1.52.13,2.23.43.56.23.86.62.86,1.24v2h.01v1.85Z"/>
                          <Path fill='black' d="M73.29,15.38c-.27-1.28-.94-2.33-2.02-3.09-.96-.67-2.07-.99-3.22-1.14-1.04-.14-2.08-.16-3.12-.07-1.26.11-2.46.37-3.57,1.01-1.3.75-2.05,1.89-2.37,3.33-.26,1.22-.26,2.44,0,3.66.34,1.56,1.16,2.75,2.58,3.51,1.07.57,2.23.79,3.43.89,1.76.14,3.48.04,5.14-.61,1.27-.5,2.23-1.34,2.8-2.6.43-.94.58-1.94.61-2.98h-.01c-.08-.64-.12-1.28-.25-1.91Zm-3.05,3.36c-.3,1.05-1.06,1.64-2.09,1.86-1.48.31-2.96.34-4.42-.15-1.04-.35-1.58-1.12-1.75-2.17-.05-.34-.06-.68-.09-1.02,0-.73.1-1.43.47-2.06.47-.81,1.27-1.15,2.14-1.3,1.19-.2,2.39-.21,3.58.06,1.4.32,2.17,1.15,2.31,2.66.06.72.04,1.43-.15,2.12Z"/>
                          <Polygon fill='black' points="87.95 23.45 87.96 23.43 87.95 23.43 87.95 23.45"/>
                          <Path fill='black' d="M84.87,11.13v7.86c0,.13,0,.26-.03.39-.16.67-.68,1.01-1.28,1.19-1.39.42-2.81.46-4.22.12-1.41-.34-2-1.16-2.01-2.55-.02-2.23,0-4.45,0-6.68v-.35h-3.05v7.41c0,.32.02.64.06.96.23,1.83,1.21,3.06,2.95,3.66,1.47.51,2.98.54,4.48.2.97-.22,1.82-.68,2.42-1.51.36-.51.55-1.08.69-1.68v3.28h3.07v-12.3h-3.08Z"/>
                          <Path fill='black' d="M141.26,11.1c-.09,0-.21.09-.26.18-.9,1.7-1.8,3.4-2.69,5.1-.4.76-.8,1.53-1.22,2.33h0c-.06-.06-.08-.08-.1-.1-.69-1.11-1.38-2.22-2.08-3.33-.83-1.33-1.66-2.66-2.51-3.98-.06-.1-.23-.2-.35-.2h-3.04c-.06,0-.13.02-.21.02.13.21.24.4.36.59,1.77,2.77,3.53,5.54,5.29,8.31.09.14.17.19.33.19h1.47c-.33.45-.68.78-1.12,1.03-.63.35-1.31.46-2.01.48-.77.02-1.54,0-2.32,0v2.58h1.05c1.12-.04,2.24-.09,3.34-.34,1.28-.29,2.38-.9,3.13-2,.48-.7.91-1.42,1.33-2.16.78-1.38,1.54-2.78,2.31-4.18.57-1.03,1.14-2.07,1.71-3.11.25-.46.5-.92.76-1.41-1.08,0-2.13-.01-3.17,0Z"/>
                          <Path fill='black' d="M102.26,13.34c-.64-1.12-1.65-1.76-2.89-2.06-1-.25-2.01-.31-3.04-.2-.76.08-1.48.25-2.15.6-1.06.56-1.79,1.67-1.88,2.81v-3.38h-3.14v12.31h3.09v-7.53c0-.28.05-.56.08-.84,0,0,0,0,0,0,.24-.48.62-.8,1.13-.98,1.39-.48,2.81-.51,4.23-.21,1.32.27,2.12,1.08,2.11,2.62,0,2.2,0,4.41,0,6.61v.33h3.06c.02-.07.02-.12.02-.17-.01-2.64-.01-5.28-.04-7.92-.01-.7-.23-1.38-.58-1.99Z"/>
                          <Polygon fill='black' points="122.7 23.43 122.69 23.43 122.69 23.46 122.7 23.43"/>
                          <Path fill='black' d="M125.29,11.38c-1,.35-1.78.98-2.19,1.97-.19.44-.28.92-.41,1.37v-3.6h-3.05v12.31h3.05v-.34c0-2.38.02-4.75,0-7.13-.01-.79.34-1.29,1.1-1.63.75-.33,1.55-.41,2.34-.41.74,0,1.48.11,2.24.17v-2.98c-1.06-.14-2.08-.08-3.08.27Z"/>
                        </G>
                        <G>
                          <Path fill='black' d="M69.29,62.19c0,1.37-.3,2.61-.91,3.72-.61,1.11-1.43,1.98-2.48,2.6-1.05.63-2.29.94-3.63.94s-2.54-.32-3.6-.94c-1.06-.63-1.89-1.49-2.5-2.6-.61-1.11-.91-2.35-.91-3.72v-21.63h-7.26v22.1c0,2.56.62,4.85,1.85,6.86,1.23,2.01,2.93,3.6,5.08,4.75,2.15,1.15,4.6,1.73,7.34,1.73,2.75,0,5.2-.58,7.34-1.73,2.15-1.15,3.85-2.74,5.08-4.75,1.23-2.01,1.85-4.3,1.85-6.86v-22.1h-7.26v21.63Z"/>
                          <Polygon fill='black' points="62.28 76 62.28 76 62.27 76 62.28 76"/>
                          <Path fill='black' d="M98.17,55.82l-6.7-2.15c-1.06-.35-1.86-.82-2.39-1.43-.54-.61-.8-1.32-.8-2.13,0-.75.2-1.41.61-1.99.4-.57.98-1.02,1.73-1.33s1.61-.47,2.58-.47c1.68,0,3.18.46,4.49,1.38,1.31.92,2.3,2.2,2.95,3.82l5.9-2.67c-.66-1.84-1.64-3.42-2.93-4.73s-2.83-2.32-4.61-3.04c-1.77-.72-3.71-1.08-5.8-1.08-2.38,0-4.47.44-6.3,1.31-1.83.87-3.25,2.09-4.26,3.65s-1.52,3.37-1.52,5.43c0,2.34.71,4.35,2.11,6.02,1.4,1.67,3.49,2.96,6.27,3.86l6.84,2.25c1.06.34,1.84.8,2.34,1.38.5.57.75,1.27.75,2.08,0,.75-.2,1.39-.61,1.94-.41.55-.97.98-1.69,1.29s-1.58.47-2.58.47c-1.88,0-3.58-.55-5.13-1.64-1.55-1.09-2.76-2.59-3.63-4.49l-5.95,2.67c.68,2,1.72,3.73,3.11,5.2,1.39,1.46,3.04,2.59,4.96,3.39,1.92.79,3.98,1.19,6.16,1.19h.02c2.47,0,4.63-.44,6.49-1.33,1.86-.89,3.32-2.12,4.38-3.7,1.06-1.58,1.59-3.38,1.59-5.41,0-2.34-.69-4.33-2.08-5.95s-3.49-2.88-6.3-3.79Z"/>
                          <Path fill='black' d="M119.49,40.56l-11.8,34.88h7.87l2.34-7.12h12.78l2.34,7.12h7.91l-11.8-34.88h-9.64Zm.43,21.44l4.39-13.45,4.36,13.45h-8.75Z"/>
                          <Path fill='black' d="M170.87,41.82c-1.86-.84-3.99-1.26-6.39-1.26h-13.39v34.88h7.27v-12.64h6.13c2.37,0,4.48-.43,6.34-1.29,1.86-.86,3.32-2.12,4.4-3.77,1.07-1.65,1.61-3.61,1.61-6.04s-.53-4.48-1.59-6.13c-1.06-1.66-2.52-2.91-4.38-3.75Zm-1.91,12.36c-.43.72-1.01,1.28-1.76,1.69s-1.64.61-2.67.61h-6.18v-9.61h6.18c1.03,0,1.92.2,2.67.61.75.41,1.34.97,1.76,1.69.42.72.63,1.59.63,2.53s-.21,1.76-.63,2.48Z"/>
                          <Path fill='black' d="M198.66,51.05c-2.06-1.15-4.35-1.73-6.88-1.73s-4.83.58-6.91,1.73c-2.07,1.16-3.73,2.73-4.96,4.73s-1.85,4.32-1.85,6.88.62,4.85,1.85,6.86,2.88,3.6,4.96,4.75c2.08,1.15,4.38,1.73,6.91,1.73s4.82-.58,6.88-1.73c2.06-1.15,3.71-2.74,4.94-4.75,1.23-2.01,1.85-4.27,1.85-6.86s-.62-4.88-1.85-6.88c-1.23-2-2.88-3.58-4.94-4.73Zm-1.28,15.26c-.55,1.06-1.3,1.89-2.25,2.48-.96.59-2.07.89-3.35.89s-2.4-.3-3.37-.89c-.97-.59-1.73-1.42-2.27-2.48-.55-1.06-.82-2.28-.82-3.65s.27-2.58.82-3.63c.54-1.04,1.3-1.87,2.27-2.48.97-.61,2.09-.91,3.37-.91s2.4.3,3.35.91c.95.61,1.7,1.43,2.25,2.48.55,1.05.82,2.26.82,3.63s-.28,2.59-.82,3.65Z"/>
                          <Path fill='black' d="M228.2,51.05c-2.06-1.15-4.35-1.73-6.88-1.73s-4.83.58-6.91,1.73c-2.07,1.16-3.73,2.73-4.96,4.73-1.23,2-1.85,4.32-1.85,6.88s.62,4.85,1.85,6.86c1.23,2.01,2.88,3.6,4.96,4.75,2.08,1.15,4.38,1.73,6.91,1.73s4.82-.58,6.88-1.73c2.06-1.15,3.71-2.74,4.94-4.75s1.85-4.27,1.85-6.86-.62-4.88-1.85-6.88-2.88-3.58-4.94-4.73Zm-1.28,15.26c-.55,1.06-1.3,1.89-2.25,2.48-.96.59-2.07.89-3.35.89s-2.4-.3-3.37-.89c-.97-.59-1.73-1.42-2.27-2.48-.55-1.06-.82-2.28-.82-3.65s.27-2.58.82-3.63c.54-1.04,1.3-1.87,2.27-2.48.97-.61,2.09-.91,3.37-.91s2.4.3,3.35.91c.95.61,1.7,1.43,2.25,2.48.55,1.05.82,2.26.82,3.63s-.28,2.59-.82,3.65Z"/>
                          <Rect fill='black' x="238.17" y="40" width="7.02" height="35.44"/>
                        </G>
                      </G>
                      </Svg>
                    </View>
                    <Text style={{ fontSize: 10.6 }}>Foundry USA Pool</Text>
                  </View>
                  <View style={{ padding: '8pt 0'}}>
                    <View style={pdfStyles.headerRow}>
                      <Text style={{ fontSize: 8, letterSpacing: 2, textTransform: 'uppercase' }}>{ groupReport ? `${selectedGroup?.length > 1 ? selectedGroup?.map(g => g.group.name).join(', ') : selectedGroup?.map(g => g.group.name)[0]}` : `${loggedInUser?.groupName ? loggedInUser.groupName + ':' : '' }` }</Text>
                      <Text style={{ fontSize: 8, letterSpacing: 2, textTransform: 'uppercase' }}>{ reportName }</Text>
                    </View>
                    <View style={pdfStyles.headerRow}>
                      <Text style={{ fontSize: 22 }}>{subAccount}</Text>
                      <Text style={{ fontSize: 14 }}>{ singleDate ? reportDate : getMultipleDatesStr()}</Text>
                    </View>
                  </View>
              </View>
                <View style={pdfStyles.tableHeaderRow} fixed>
                  {
                    data && data.length ? 
                    columns.map((colName) => {
                      return (
                        <View style={colName.length >= 20 ? 
                          pdfStyles.xlColumn : 
                          (colName.length >= 15 && colName.length < 20) ? 
                          pdfStyles.lgColumn :
                          (colName.length >= 10 && colName.length < 15) ? 
                          pdfStyles.medColumn :
                          pdfStyles.smallColumn}
                          key={colName}
                        >
                          <Text style={pdfStyles.th}>{colName}</Text>
                        </View>
                      )
                    }) : ''
                  }
                </View>
                  {
                      data.map((item, index) => {
                        return (
                          <View style={!(index % 2) ? pdfStyles.evenRow : pdfStyles.oddRow} key={`${fileName}-row-${index}`}>
                            {
                              Object.keys(item).map((key, index) => {
                                return (
                                  <View style={key.length >= 20 ? 
                                    pdfStyles.xlColumn : 
                                    (key.length >= 15 && key.length < 20) ? 
                                    pdfStyles.lgColumn :
                                    (key.length >= 10 && key.length < 15) ? 
                                    pdfStyles.medColumn :
                                    pdfStyles.smallColumn} 
                                    key={`${fileName}-item-${index}-${key}`
                                    }
                                    wrap={false}
                                  >
                                    { (key === 'Transaction id' || key === 'Address') && reportName === 'Transactions Report' ?
                                       <Link style={pdfStyles.td} src={item[key].url}>{item[key].display}</Link> :  <Text style={pdfStyles.td}>{item[key]}</Text>
                                    }
                                   
                                  </View>
                                )
                              })
                            }
                          </View>
                        )
                      })
                    }
                <View style={pdfStyles.footer} render={({ pageNumber, totalPages}) => (
                  <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <View>
                      <Text style={pdfStyles.footerTxt}>
                        FOUNDRYDIGITAL.COM | PO BOX 436 PITTSFORD, NY 14534
                      </Text>
                    </View>
                    <View>
                      <Text style={pdfStyles.footerTxt}>{`${pageNumber}`}</Text>
                    </View>
                  </View>
                )} fixed />
            </Page>
        </Document>
        )
      };

    return(
        <Box display="flex" justifyContent="flex-end" sx={{ minWidth: '125px !important'}}>
          {exportInProcess && !pdfOption 
          ?
            (<Button 
              disabled 
              sx={props.style}
              variant={props.variant ? props.variant : "text"} 
              color={props.color ? props.color : "inherit"} 
              className={props.variant === 'contained' ? cx(classes.redesignBtn) : ''}>
                <CircularProgress size={20} className={classes.actionIcon}></CircularProgress>
                {t(reportName === "Audit Log Report" ? 'exportTsv' :'export')}
            </Button>)

            : !exportInProcess && !pdfOption 
            ? 
            (<Button
              sx={props.style}
              // sx={{ padding: '0' }}
              variant={props.variant ? props.variant : "text"} 
              color={props.color ? props.color : "inherit"} 
              onClick={handleExportButtonClick} 
              className={props.variant === 'contained' ? cx(classes.redesignBtn) : ''}>
                <SvgIcon className={classes.actionIcon}>
                  <Download size={20} />
                </SvgIcon>
                {t(reportName === "Audit Log Report" ? 'exportTsv' :'export')}
            </Button>): 

        <FormControl size="small" fullWidth>
            <InputLabel id="export-label" className={classes.inputLabel} disabled={buildingPdf || exportInProcess} ref={labelRef}>Export As</InputLabel>
            <Select
              labelId="export-label"
              label="Export As"
              className={props.variant === 'contained' ? classes.redesignDropDown : {}}
              value={selectedExportOption}
              onChange={handleExportMenuUpdate}
              onOpen={(event) => handleOpen(event)}
              onClose={handleClose}
              open={open}
              notched={open || (buildingPdf || exportInProcess) || exportFocused}
              disabled={buildingPdf || exportInProcess}
              ref={dropdownRef}
              onFocus={(event) => {
                if (event.relatedTarget !== null && event._reactName === 'onFocus') {
                  setExportFocused(true)
                } else {
                  setExportFocused(false)
                }
              }}
              MenuProps={props.variant === 'contained' ? {
                PopoverClasses: {
                  paper: classes.redesignDropDown
                }
              }: {}}
              onBlur={() => setExportFocused(false)}
            >
              <MenuItem value={'csv'}>{ exportInProcess ? 'Loading...' : 'CSV'}</MenuItem>
              <MenuItem value={'pdf'}>
                { buildingPdf ? 'Loading...' : 'PDF'}
              </MenuItem>
            </Select>
          </FormControl> 
        }
        <CSVLink
          uFEFF={false}
          data={csvData}
          filename={reportName === "Audit Log Report" ?  `${getFileName()}.tsv` : `${getFileName()}.csv`}
          ref={csvLink}
          target='_blank'
          separator={reportName === "Audit Log Report" ? '\t' : ','}
        />
        </Box>
    );
}

export default ExportCSVComponent
