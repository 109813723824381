import useAuth from 'src/hooks/useAuth';
import useSWR from 'swr';
import { HASHRATE, getHashrateUnit } from 'src/api/HashrateFormatter';
import { fetcher } from 'src/api/API';
import { getDateFromDateString } from 'src/api/DateFormatter';

const formatHashrate = hashrate => {
    const hashrateUnit = getHashrateUnit(hashrate, HASHRATE.GHS);
    return hashrateUnit.formatToHashrateString(hashrate * HASHRATE.GHS.one);
}

const useGetAllSubAccounts = (coin, allSubAccounts, archived, selectedGroups, archivedSubAccounts = false) => {
    const { getAccessToken, loggedInUser } = useAuth()
    const isAdmin = loggedInUser.adminRole.adminRead 
    const requestUrl = isAdmin && allSubAccounts ? `subaccount_stats?coin=${coin}` : `granted_subaccount_stats?coin=${coin}`;
    const { data, error, mutate } = useSWR([requestUrl, getAccessToken], fetcher, { revalidateOnFocus: false });
    let subAccounts = []
    let dropdownList = []
    if (data && isAdmin && allSubAccounts) {
      subAccounts = data.map((i, index) => ({
        uniqueId: index,
        name: i.name,
        hashrate1hrAvg: formatHashrate(i.hashrate1hrAvg),
        hashrate1hrAvgRaw: i.hashrate1hrAvg,
        activeWorkers: i.activeWorkers,
        offline15MinWorkerCount: i.offline15MinWorkerCount,
        offline24HrWorkerCount: i.offline24HrWorkerCount,
        subAccountUsers: i.subAccountUsers,
        groupName: i.groupName,
        feeRatePercent: i.feeRatePercent.toFixed(2) + ' %',
        feeRatePercentRaw: i.feeRatePercent,
        createdAt: getDateFromDateString(i.subAccountCreationDate),
        createdAtRaw: i.subAccountCreationDate
      }));
  
      dropdownList = subAccounts.map(subAccountInfo => {
        return {
          "text":subAccountInfo.name+", "+subAccountInfo.groupName,
          "value":subAccountInfo.name+", "+subAccountInfo.groupName
          }
      });
      if (!sessionStorage.getItem('selectedSubAccount') && dropdownList && dropdownList.length > 0) {
        sessionStorage.setItem('selectedSubAccount', dropdownList[0].value)
      }
    } else if (data && (!isAdmin || (isAdmin && !allSubAccounts))) {
      subAccounts = selectedGroups?.length ? data.filter(acct => selectedGroups.map(g => g.id).includes(acct.groupId)).map((i, index) => ({
        uniqueId: index,
        name: i.name,
        groupName: i.groupName,
        groupId: i.groupId,
        hashrate1hrAvg: formatHashrate(i.hashrate1hrAvg),
        rawHashrate1hrAvg: i.hashrate1hrAvg,
        activeWorkers: i.activeWorkers,
        offline15MinWorkerCount: i.offline15MinWorkerCount,
        offline24HrWorkerCount: i.offline24HrWorkerCount,
        owners: i.owners,
        subAccountUsers: i.subAccountUsers.filter(user => user.roleName !== 'no access'),
        noSubAcctAccessUsers: i.subAccountUsers.filter(user => user.roleName === 'no access').length ? i.subAccountUsers.filter(user => user.roleName === 'no access') : [],
        role: i.role, //role of the logged in user
        subAccountUsersPermission: i.subAccountUsersPermission,
        active: archivedSubAccounts ? i.isActive : null //for archived sub-accounts feature to allow distinguishing between archived and active
      })) : data.map((i, index) => ({
        uniqueId: index,
        name: i.name,
        groupName: i.groupName,
        groupId: i.groupId,
        hashrate1hrAvg: formatHashrate(i.hashrate1hrAvg),
        rawHashrate1hrAvg: i.hashrate1hrAvg,
        activeWorkers: i.activeWorkers,
        offline15MinWorkerCount: i.offline15MinWorkerCount,
        offline24HrWorkerCount: i.offline24HrWorkerCount,
        owners: i.owners,
        subAccountUsers: i.subAccountUsers.filter(user => user.roleName !== 'no access'),
        noSubAcctAccessUsers: i.subAccountUsers.filter(user => user.roleName === 'no access').length ? i.subAccountUsers.filter(user => user.roleName === 'no access') : [],
        role: i.role, //role of the logged in user
        subAccountUsersPermission: i.subAccountUsersPermission,
        active: archivedSubAccounts ? i.isActive  : null //for archived sub-accounts feature to allow distinguishing between archived and active
      }));
  
      dropdownList = subAccounts.map(subAccountInfo => {
        return {
          "text":subAccountInfo.name+", "+subAccountInfo.role.charAt(0).toUpperCase()+subAccountInfo.role.slice(1),
          "value":subAccountInfo.name+", "+subAccountInfo.role.charAt(0).toUpperCase()+subAccountInfo.role.slice(1)
        }
      })

      // filtering for archived sub-accounts feature
      if (archivedSubAccounts) {
        if (archived && subAccounts?.filter(acct => !acct.active).length) {
          subAccounts = subAccounts?.filter(acct => !acct.active)
        } else {
          subAccounts = subAccounts?.filter(acct => !!acct.active)
        }
      }

    }

    return {
      data: subAccounts,
      dropdownList,
      isLoading: !error && !data,
      isError: error,
      mutate
    }
  }

  export default useGetAllSubAccounts;
