import { Button, Dialog } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { React } from 'react';
import useAuth from 'src/hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth:"500px",
    width: "100%",
    padding: "50px",
    fontSize: "20px"
  },  
};

export const SessionTimeoutDialog = ({isOpen, props}) => {  
 
  const { t }  = useTranslation();
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async () => {
    try {

      //clearing the selected drop down values from session storage
      sessionStorage.clear()
      await logout();
      window.location.href = "/login"
    } catch (err) {
      enqueueSnackbar(t('unableToLogout'), {
        variant: 'error'
      });
    }
  };

  return (
    <div> 
      <Dialog
        open={isOpen}
        style={customStyles}
      >
        <DialogTitle sx={{backgroundColor: 'var(--neutral-3)'}}>
          Session Timeout
        </DialogTitle>
        <DialogContent sx={{backgroundColor: 'var(--neutral-3)'}}>
          <DialogContentText>
          Your session timed out due to inactivity. Click OK to go back to the Login screen.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{backgroundColor: 'var(--neutral-3)'}}>
          <Button style={{backgroundColor: 'var(--accent-9)', color: 'var(--accent-9-contrast)'}} color="secondary" variant="contained"  onClick={handleLogout}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>  
  );
}