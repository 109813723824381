import React, { useState } from 'react';
import { 
  Divider,
  Box,
  Button,
  ListItemText,
  OutlinedInput,
  SvgIcon,
  FormControl,
  Select, 
  MenuItem,
  InputLabel,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ArrowDownWideNarrow } from 'lucide-react';

  const useStyles = makeStyles()((theme) => ({ 
    sortDropDown: {
      background: 'var(--neutral-2)',
      border: '1px solid var(--color-border)',
      '& .MuiMenuItem-root:hover': {
        backgroundColor: 'rgba(255,255,255, 0.1)'
      },
    },
  }))

export const TableSortControls = ({
  selectorValues,
  onChange,
  defaultSort,
  defaultOrder,
  disabled,
  metal
} = props) => {
  const { classes } = useStyles();
  const [sortDirection, setSortDirection] = useState(
    defaultOrder === 'desc' ? true : false
  );
  const [displayValue, setDisplayValue] = useState(defaultSort ?? 'clear');

  const handleOnChange = async event => {
    const {
      target: { value }
    } = event;
    if (value === 'clear') {
      // if sorting is cleared, reset to default sort
      onChange(defaultSort ?? 'clear', defaultOrder);
      setSortDirection(defaultOrder === 'desc' ? true : false);
      setDisplayValue(defaultSort ?? 'clear');
      return;
    }
    onChange(value, sortDirection === true ? 'desc' : 'asc');
    setDisplayValue(value);
  };

  const handleSortDirection = async () => {
    onChange(displayValue, sortDirection === true ? 'asc' : 'desc');
    setSortDirection(state => !state);
  };

  return (
    <Box>
      <Box display="flex" alignItems='center' gap='5px'>
        <FormControl sx={{ width: '100%' }}>
          <InputLabel>Sort By</InputLabel>
          <Select
            SelectDisplayProps={{
              style: { paddingTop: 12, paddingBottom: 12 }
            }}
            disabled={disabled}
            value={displayValue}
            onChange={handleOnChange}
            input={
              <OutlinedInput
                label="Sort By"
              />
            }
            renderValue={selected => {
              return selected === 'clear' ? '' : selectorValues[selected];
            }}
            MenuProps={{
              PopoverClasses: {
                paper: metal ? classes.sortDropDown : {}
              }
            }}
            sx={
              metal
                ? {
                    ':after': { borderBottomColor: 'var(--accent-11)' }
                  }
                : {}
            }
          >
            <MenuItem value={'clear'}>
              <ListItemText primary={'Clear Sorting'} />
            </MenuItem>
            <Divider />
            {Object.keys(selectorValues).map((key, index) => {
              return (
                key !== 'clear' && (
                  <MenuItem key={key + index} value={key}>
                    <ListItemText primary={selectorValues[key]} />
                  </MenuItem>
                )
              );
            })}
          </Select>
        </FormControl>
        <Button
          disabled={disabled}
          variant="outline"
          onClick={handleSortDirection}
          color="inherit"
          sx={{
            padding: 0,
            height: '47px',
          }}
        >
          <SvgIcon
            sx={{
              position: 'absolute',
              transition: 'transform .3s cubic-bezier(.42,1.86,.80,1), opacity .3s cubic-bezier(.42,1.86,.80,1)',
              transform: sortDirection ? 'translate(0, 12px) rotate(180deg)' : 'translate(0, 0) rotate(180deg)',
              opacity: sortDirection ? '0' : '1',
              }}
          >
            {displayValue !== 'clear' && displayValue !== '' ? (
              <ArrowDownWideNarrow strokeWidth={1} color={disabled ? 'var(--neutral-8)' : 'var(--color-text)'} />
            ) : null}
          </SvgIcon>

          <SvgIcon
            sx={{
              position: 'absolute',
              transition: 'transform .3s cubic-bezier(.42,1.86,.80,1), opacity .3s cubic-bezier(.42,1.86,.80,1)',
              transform: sortDirection ? 'translate(0, 0)' : 'translate(0, -12px)',
              opacity: sortDirection ? '1' : '0',
            }}
          >
            {displayValue !== 'clear' && displayValue !== '' ? (
              <ArrowDownWideNarrow strokeWidth={1} color={disabled ? 'var(--neutral-8)' : 'var(--color-text)'} />
            ) : null}
          </SvgIcon>
        </Button>
      </Box>
    </Box>
  );
};