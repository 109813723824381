import React from 'react';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { Navigate, Outlet } from 'react-router-dom';

const AuthGuard = () => {
  const { isAuthenticated, afterLoginRedirection } = useAuth();
  if (!isAuthenticated) {
    
    if (window.location.search)
    {
      afterLoginRedirection() 
    }
    
    return <Navigate to="login" />;
    
  }

  return <Outlet />;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
