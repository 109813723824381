
class Hashrate {
    constructor(id, pow10, unitName) {
        this.id = id;
        this.one = Math.pow(10, pow10);
        this.unitName = unitName;
    }   
    format(value) {
        return this.formatToFixed(value, 2);
    }
    formatToHashrateString(value) {
        return (value / this.one).toLocaleString() + " " + this.unitName;
    }
    formatToHashrateStatsBoxString(value) {
        return (value / this.one).toFixed(3).toLocaleString() + " " + this.unitName;
    }
    toString() {
        return this.id;
    }
}
  
export const HASHRATE = {
    HS  : new Hashrate("HS", 0, "H/s"),
    KHS : new Hashrate("KHS", 3, "KH/s"),
    MHS : new Hashrate("MHS", 6, "MH/s"),
    GHS : new Hashrate("GHS", 9, "GH/s"),
    THS : new Hashrate("THS", 12, "TH/s"),
    PHS : new Hashrate("PHS", 15, "PH/s"),
    EHS : new Hashrate("EHS", 18, "EH/s")
};
  
const HASHRATES = [
    HASHRATE.HS ,
    HASHRATE.KHS,
    HASHRATE.MHS,
    HASHRATE.GHS,
    HASHRATE.THS,
    HASHRATE.PHS,
    HASHRATE.EHS
];
  
export const getHashrateUnit = (value, startUnit) => {
    var referenceHashrate = HASHRATES[0];
    for (var i = HASHRATES.length - 1; i >= 0; i--) {
        var hashrate = HASHRATES[i];
        if (value * startUnit.one >= hashrate.one) {
            referenceHashrate = hashrate;
            break;
        }
    }
    return referenceHashrate;
}

export const formatAsPercentageOfTotal = (value, total) => {
    if (total !== 0){
      return (value * 100 / total).toFixed(3) + ' %'
    }
    return '0.000 %'
}

export const calculatePercentage = (value, total) => {
    if (total !== 0){
        return (value * 100 / total)
    }
    return 0
}

export const convertTo = (value, startUnit, endUnit) => {
    return ((value * startUnit.one) / endUnit.one).toFixed(3)
}
  
export default getHashrateUnit;