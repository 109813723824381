import React from 'react';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { Navigate, Outlet } from 'react-router-dom';

const AdminGuard = () => {
  const { loggedInUser } = useAuth();

  return loggedInUser.adminRole.adminRead ? <Outlet /> : <Navigate to="/app/account-overview" />;
  
};

AdminGuard.propTypes = {
  children: PropTypes.node
};

export default AdminGuard;
