/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  List,
  ListSubheader,
  ListItemButton,
  ListItemText,
  Collapse,
  Switch,
  useMediaQuery,
  useTheme
} from '@mui/material';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import LanguageSwitcher from 'src/components/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { useLocation } from 'react-router-dom';
import { useFlag } from '@unleash/proxy-client-react';

import { ChevronUp, ChevronDown } from 'lucide-react';

const sections = [
  {
    subheader: ' ',
    items: [
      {
        title: 'dashboard',
        icon: 'AreaChart',
        href: 'dashboard'
      },
      {
        title: 'workers',
        icon: 'Server',
        href: 'workers'
      },
      {
        title: 'dailyStatistics',
        icon: 'BarChart3',
        href: 'daily-hashrate'
      }
    ]
  }
];

function renderNavItems({
  items,
  pathname,
  depth = 0,
  translation,
  metal
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth, translation, metal }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
  translation,
  metal
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={translation(item.title)}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href + window.location.search}
        icon={item.icon}
        info={item.info}
        key={key}
        title={translation(item.title)}
        metal={metal}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles()(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
    zIndex: 1000
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
   logoSmall: {
    width: '75%'
  },
  metalMenu: {
    width: 256,
    height: '100%',
    zIndex: 1000,
    backgroundColor:" var(--uishell-color-surface)",
    borderTop: "1px solid var(--color-border)"
  }
}));

const NavBar = (props) => {
  const { onMobileClose, openMobile, metal, setMetal} = props
  const redesignFlag = useFlag("metal-redesign")
  const { t } = useTranslation();
  const { classes, cx } = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));
  const isFirefox = typeof InstallTrigger !== 'undefined';
  
  const [previewOpen, setPreviewOpen] = useState(false);

  const handleClick = () => {
    setPreviewOpen(!previewOpen);
  };

  const handleMetalToggle = () => {
    localStorage.setItem('metalEnabled', `${!metal}`)
    setMetal(!metal)
    setPreviewOpen(false)
  }
  
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        {
          mobileDevice && 
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to={{ pathname: window.location.host === "foundryusapool.com" ? "https://foundrydigital.com/mining-service/foundry-usa-pool/" : "/", search: window.location.search }} style={isFirefox ? {width: "100%"} : {}}>
              <Logo className={classes.logoSmall}/>
            </RouterLink>
          </Box>
        }        
        
        <Divider />
        {
          redesignFlag && 
          <List
            sx={{width: '100%', bgcolor: metal ? 'var(--neutral-1)' : '#282C34' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <ListItemButton disableRipple onClick={handleClick}>
              <ListItemText disableTypography>
                <div className='m-heading' style={{padding: "0 8px"}}>
                  {
                    metal ? "This is Foundry's new look!" : "Try Foundry's new look!"
                  }
                </div>    
              </ListItemText>
              {previewOpen ? <ChevronUp size={20} style={{color: 'var(--color-text-accent)'}} /> : <ChevronDown size={20}/>}
            </ListItemButton>
            <Collapse in={previewOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding sx={{marginLeft: "1.5em"}}>
                <label className='m-text' style={{marginRight: 5}}>Off</label>
                  <Switch
                    size={metal ? "small" : "medium"}
                    onChange={handleMetalToggle}
                    checked={metal}>
                  </Switch>
                <label className='m-text' style={{marginLeft: 5}}>On</label>
              </List>
            </Collapse>
          </List>
                  
        }
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
                translation: t,
                metal
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
      <LanguageSwitcher/>
    </Box>
  );

  return <>
    { 
      mobileDevice ?
      <Drawer
        anchor="left"
        classes={metal ? { paper: cx(classes.mobileDrawer, classes.metalMenu) } : { paper: classes.mobileDrawer }}
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
      >
        {content}
      </Drawer> :
      <Drawer
        anchor="left"
        classes={metal ? { paper: cx(classes.desktopDrawer, classes.metalMenu) } : { paper: classes.desktopDrawer }}
        open
        variant="persistent"
      >
        {content}
      </Drawer>
    }
  </>;
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  metal: PropTypes.bool
};

export default NavBar;
