import React, {useState, useEffect, useCallback} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  TextField,
  InputAdornment,
  Typography,
  SvgIcon,
  useMediaQuery,
  useTheme,
  FormControlLabel,
  Switch
} from '@mui/material';
import { Menu as MenuIcon } from 'lucide-react';
import { THEMES } from 'src/constants';
import Autocomplete from '@mui/material/Autocomplete';
import GetCoins from 'src/api/GetCoins';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBitcoin } from '@fortawesome/free-brands-svg-icons'
import Logo from 'src/components/Logo';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles()((theme) => ({
  root: {
    ...(theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      background: theme.palette.primary.main
    } : {}),
    ...(theme.name === THEMES.ONE_DARK ? {
      background: theme.palette.background.default
    } : {})
  },
  metalRoot: {
    backgroundColor: "var(--neutral-1)",
    boxShadow: 'none',
    paddingRight: 0
  },
  toolbar: {
    height: 64
  },
  autoCompleteElem: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1.5),
    width: theme.spacing(20),
  },
  logoSmall: {
    width: '50%',
  },
  listBox: {
    background: theme.palette.background.default
  },
  metalHeader: {
    paddingLeft: "0 !important",
    backgroundColor: 'var(--neutral-1)',
    boxShadow: "none",
    paddingRight: `${theme.spacing(2)} !important`
  },
  metalBackground: {
    backgroundColor: "var(--neutral-2)"
  },
  metalLogo: {
    width: "50%",
    minWidth: "150px",
    height: "40px",
    marginLeft: '16px'
  }
}));

const applyInitialIconStyle = coin => {
  if(coin){
    if(coin === 'BTC'){
      return({color: "#f7931a"})
    }
    if(coin === 'BCH'){
      return({transform:"rotate(-30deg)",color:"#8dc351"})
    }
  }
  return({color: "#f7931a"})
}

const TopBar = (props) => {
  const className = props.className
  const theme = useTheme()
  const onMobileNavOpen = props.onMobileNavOpen
  const coin = props.coin
  const subAccount = props.subAccount
  const { t }  = useTranslation();
  const { classes, cx } = useStyles();
  const [coins, setCoins] = useState([])
  const [iconStyle, setIconStyle] = useState(applyInitialIconStyle(coin))
  const navigate = useNavigate();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));
  const smallMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const metal = props.metal;

  /**
   * Handes the event of onchange on coin autocomplete. Currently invokes the change state function of the parent
   * @param {*} event onChange
   * @param {*} values 
   */
  const handleCoinAutocompleteChange = (event, values) => { //only fires when an option is selected with the option value
    let searchParams = new URLSearchParams(window.location.search); 
    searchParams.set('coin', values.value)
    navigate({
      pathname: window.location.pathname,
      search: searchParams.toString()
    })
    
    if(values){
      setIconStyle(applyInitialIconStyle(values.value))
    }
    // onCoinChng(values? values.value:'BTC')

  };

  const fetchData = useCallback(async () => {
    
    const {success, data} = await GetCoins();
    if (success) {
      
      var coinsFromDB = []
      data.map(coinInstance => 
        coinsFromDB.push({"text":coinInstance, "value":coinInstance})
        )
      setCoins(coinsFromDB)      
    } else {
      console.log('error getting coin list from the database error is '+data)
      console.log(data);
    }
  }, []);
  
  useEffect(() => {
    fetchData()
  }, [fetchData]);
  return (
    <AppBar
      enableColorOnDark
      className={metal ? cx(classes.metalRoot, className): cx(classes.root, className)}
      // {...rest}
    >
      <Toolbar 
        className={metal 
          ? cx(classes.metalHeader) 
          : classes.toolbar} 
        style={metal || mobileDevice ? { paddingLeft: 0 } : {paddingLeft: theme.spacing(2)}}>
          { mobileDevice ? <>
            <IconButton color="textPrimary" onClick={onMobileNavOpen} size="large">
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
            </IconButton> </> : (<>
            <RouterLink style={metal ? {
              minWidth: "256px",
              width: "256px",
              borderRight: "1px solid var(--uishell-color-border)",
              height: "67px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              backgroundColor: "var(--neutral-2)"
            } : {}} to={window.location.host === "foundryusapool.com" ? "https://foundrydigital.com/mining-service/foundry-usa-pool/" : "/"}>
              <Logo className={metal ? classes.metalLogo : classes.logoSmall}/>
            </RouterLink>
            </>)
          }        
      
        { !mobileDevice && <Box
          ml={2}
          flexGrow={1}
        /> }
            <TextField
              disabled
              label={t('subAccount')}
              name="subAccounts"
              variant="outlined"
              className={classes.autoCompleteElem}
              value={subAccount}
              inputProps={{
                style: { paddingTop: "12px", paddingBottom:"12px" }
              }}
            />
            
            <Autocomplete
                    getOptionLabel={(option) => option.text}
                    isOptionEqualToValue={(option, value) => option.text === value.text }
                    onChange={handleCoinAutocompleteChange}
                    disableClearable={true}
                    options={coins}
                    value={{ text: coin, value: coin }}
                    className={classes.autoCompleteElem}
                    ListboxProps={{
                      className: metal ? classes.metalBackground : classes.listBox
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        label={t('coin')}
                        name="coins"
                        variant="outlined"
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          style: { paddingTop: "3px", paddingBottom:"3px", width: theme.spacing(2) }
                        }}
                        InputProps={!smallMobileDevice ? {
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon size="lg" icon={faBitcoin} style={iconStyle}/>
                            </InputAdornment>
                          )
                        }: {...params.InputProps}}
                      />
                      
                    )}
                    renderOption={(props, option) => {
                      return ( <li {...props}>
                                <FontAwesomeIcon icon={faBitcoin} 
                                  style={{...applyInitialIconStyle(option.text), marginRight:theme.spacing(1)}}/>
                                <Typography>{option.text}</Typography>                                
                                </li>);
                    }}
            />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
