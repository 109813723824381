import React, {useEffect, useState} from "react";
import { SessionTimeoutDialog } from "./SessionTimeoutDialog";
import { addEventListeners,  removeEventListeners } from '../utils/sessionEventListenerUtil'
import { IDLE_TIMEOUT } from '../constants'

export const TimeoutLogic = () => { 

  const [isWarningModalOpen, setWarningModalOpen] = useState(false);

  useEffect(() => {
    const createTimeout1 = () => setTimeout(()=>{ 
      setWarningModalOpen(true);
    }, IDLE_TIMEOUT)

    const listener = () => {
      if(!isWarningModalOpen){
        clearTimeout(timeout)
        timeout = createTimeout1();
      }
    } 

    let timeout = isWarningModalOpen  ? createTimeout1() : null
    addEventListeners(listener);

    return () => {
      removeEventListeners(listener);
      clearTimeout(timeout);
    }
  },[isWarningModalOpen])

  return (
    <div>
      {isWarningModalOpen && (
        <SessionTimeoutDialog 
          isOpen={isWarningModalOpen}
          onRequestClose={() => setWarningModalOpen(false)}
        />
        )
      }
    </div>
  ) 
}