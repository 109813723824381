import { getCoins } from './API';

/**
 * Fetches coins supported by the mining pool 
 */
const GetCoins = async () => {
  try {
    const response = await getCoins();
    return { success: true, data: response.data };
  } catch (error) {
    console.log('Error getting coin list: ', error.message);
    return { success: false, data: 'Error: API server is down or sub-account does not exist' };
  }
};

export default GetCoins;
