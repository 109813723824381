import React, {useState} from 'react';
import { TextField } from '@mui/material';
import { THEMES } from 'src/constants';
import { useTranslation } from 'react-i18next';
import useSettings from 'src/hooks/useSettings';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main
    } : {}),
    ...(theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default
    } : {})
  },
  toolbar: {
    height: 64
  },
  logo: {
    marginRight: theme.spacing(2)
  },
  logoSmall: {
    width: '50%',
  },
  textFieldElem: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: theme.spacing(25)
  }
}));

const languages = [
  {
    code: "en-US",
    label: "English"
  },
  {
    code: "zh-CN",
    label: "简体中文"
  }
]

const LanguageSwitcher = () => {
  const getInitialLanguageState = () => {
    var initialLanguageState = 'en-US';
    if (localStorage.getItem('language')){
      initialLanguageState = localStorage.getItem('language');
    }

    return initialLanguageState;
  }

  /**
   * Handles the event of onChange on language selection.
   * @param {*} event onChange 
   */
  const handleLanguageChange = (event) => {
    if(event) {
      i18n.changeLanguage(event.target.value);
      saveSettings({
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
        theme: settings.theme,
        language: event.target.value
      });
      localStorage.setItem('language', event.target.value);
      setLanguage(event.target.value);
    }
  };

  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const { settings, saveSettings } = useSettings();
  const [language, setLanguage] = useState(getInitialLanguageState());

  return (
    <TextField
      className={classes.textFieldElem}
      label={t("languageSwitcher")}
      name="languages"
      onChange={handleLanguageChange}
      select
      SelectProps={{ native: true }}
      value={language}
      variant="outlined"

      inputProps={{
        style: { paddingTop: "14px", paddingBottom:"14px" }
      }}
    >
      {languages.map((language) => (
        <option
          key={language.code}
          value={language.code}
        >
          {language.label} 
        </option>
      ))}
    </TextField>
  );
}

export default LanguageSwitcher;