import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import {
  Box,
  LinearProgress,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3)
  }
}));

const LoadingScreen = (props) => {
  const { classes } = useStyles();
  const metal = props.metal;

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div className={classes.root} style={metal ? { marginTop: '45vh'} : {}}>
      <Box width={350}>
        <LinearProgress />
      </Box>
    </div>
  );
};

export default LoadingScreen;
