import React, {createContext, useState} from "react";

const SideNavContext = createContext({
    sideNavOpen: false,
    handleSideNavUpdate: () => {} 
});


export const SideNavProvider = ({ children }) => {
    const [navOpen, setNavOpen] = useState(false)
    const handleSideNavUpdate = (status) => {
        setNavOpen(status);
    }

	return (
	<>
		<SideNavContext.Provider value={{ navOpen, handleSideNavUpdate }}>
            {children}
		</SideNavContext.Provider>
	</>
	);
}

export default SideNavContext;

