import React, {createContext, useState} from "react";

const SelectedSubAccountContext = createContext({
    subAccountSelection: null,
    handleSelectedSubAccountUpdate: () => {},
    handleSelectedSubAccountNavUpdate: () => {},
});


export const SelectedSubAccountProvider = ({ children }) => {
    const [selectedSubAccount, setSelectedSubAccount] = useState('No Sub-Account Selected')
    const [selectedNavTab, setSelectedNavTab] = useState('0')
    const handleSelectedSubAccountUpdate = (selectedSubAccountUpdate) => {
        setSelectedSubAccount(selectedSubAccountUpdate);
        if (selectedSubAccountUpdate !== 'No Sub-Account Selected') {
            sessionStorage.setItem('selectedSubAccount', selectedSubAccountUpdate);
        }
    }
    const handleSelectedSubAccountNavUpdate = (navUpdate) => {
        setSelectedNavTab(navUpdate);
    }


	return (
	<>
		<SelectedSubAccountContext.Provider 
            value={{ selectedSubAccount, selectedNavTab, handleSelectedSubAccountUpdate, handleSelectedSubAccountNavUpdate }}
        >
            {children}
		</SelectedSubAccountContext.Provider>
	</>
	);
}

export default SelectedSubAccountContext;

