import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  useMediaQuery,
  useTheme
} from '@mui/material';
import Logo from 'src/components/Logo';
import LanguageSwitcher from 'src/components/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
const isFirefox = typeof InstallTrigger !== 'undefined';

const useStyles = makeStyles()((theme) => ({
  toolbar: {
    height: 64
  },
  logo: {
    marginRight: theme.spacing(2),
    width: '75%',
  },
  logoFirefox:{
    marginRight: theme.spacing(2),
    width: '75%',
    minWidth: "273px !important"
  },
  logoSmall: {
    width: '90%',
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));

const TopBar = ({ className, ...rest }) => {
  const { t }  = useTranslation();
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const smallMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <AppBar
      enableColorOnDark
      className={cx(classes.root, className)}
      style={{background: 'transparent', boxShadow: 'none', paddingTop: 32, paddingRight: 16, paddingLeft: 16, paddingBottom: 24}}
      {...rest}
    >
      <Toolbar className={classes.toolbar} >
          { smallMobileDevice ? 
          <RouterLink to={window.location.host === "foundryusapool.com" ? "https://foundrydigital.com/mining-service/foundry-usa-pool/" : "/"}>
            <Logo className={classes.logoSmall}/>
          </RouterLink> :
          <RouterLink to={window.location.host === "foundryusapool.com" ? "https://foundrydigital.com/mining-service/foundry-usa-pool/" : "/"}>
            <Logo className={isFirefox ? classes.logoFirefox : classes.logo} />
          </RouterLink>
        }
        <Box flexGrow={1} />
        <LanguageSwitcher/>
        { !smallMobileDevice && <Button
          color="secondary"
          component="a"
          href="app"
          variant="contained"
          size="large"
          style={{ marginLeft: 5}}
        >
          {t('login')}
        </Button> }
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
