export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  authorizationParams: {
    redirect_uri: window.location.origin,
    cacheLocation: "localstorage",
    useRefreshTokens: true,
    audience: process.env.REACT_APP_AUTH0_POOL_AUDIENCE
  }
};

export const auth0Audience = {
  audience: process.env.REACT_APP_AUTH0_POOL_AUDIENCE,
  scope: process.env.REACT_APP_AUTH0_POOL_SCOPE
}

export const auth0LoginRedirection = {
  authorizationParams: {
    redirect_uri : window.location.origin + '/app'
  }
}

export const blockInfoWebsite = "https://www.blockchain.com/";
export const btcInfoWebsite = "https://mempool.space";
export const bchInfoWebsite = "https://blockchair.com/bitcoin-cash";

export const websiteTitle = "Foundry USA Pool";

export const noSubAccountSelectionErrorMsg = "No Sub-Account selected"

export const apiConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
}

export const hubConfig = {
  hubURL: process.env.REACT_APP_HUB_URL
}