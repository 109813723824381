import React, {
  Suspense,
  lazy
} from 'react';
import DashboardLayout from 'src/layouts/DashboardLayout';
import ViewerDashboardLayout from 'src/layouts/viewerDashboardLayout';
import Calculator from 'src/views/calculator';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import { Route, Routes, Navigate } from 'react-router-dom';

const LoginComp = lazy(() => import('src/views/auth/LoginView'))
const NotFoundComp = lazy(() => import('src/views/errors/NotFoundView'));


export const renderRoutes = () => (
  <Suspense fallback={<LoadingScreen />}>
    <Routes>
      <Route element={<AuthGuard />}>
        <Route path="app/*" element={<DashboardLayout />} />
      </Route>
      <Route element={<GuestGuard />}>
        <Route path="login" element={<LoginComp />} />
      </Route>
      <Route path="calculator-beta" element={<Calculator />}/>
      <Route path="viewer/*" element={<ViewerDashboardLayout />}/>
      <Route path="login-unprotected"  element={<LoginComp />} />
      <Route element={<MainLayout />}>
        <Route index element={<HomeView />} exact />
        <Route path="*" element={<Navigate to="/404" />}/>
      </Route>
      <Route path="404" element={<NotFoundComp />} />
    </Routes>
  </Suspense>
);
