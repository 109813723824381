import React from 'react';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { Navigate, Outlet } from 'react-router-dom';

const GuestGuard = () => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <Navigate to="/app"/> : <Outlet /> ;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
