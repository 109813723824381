const eventTypes = [
    'mousemove',
    'keydown',
    'wheel',
    'DOMMouseScroll',
    'mousewheel',
    'mousedown',
    'touchstart',
    'touchmove',
    'MSPointerDown',
    'MSPointerMove',
    'visibilitychange'
  ]
  export const addEventListeners = (listener) => {
    eventTypes.forEach((type) => {
      window.addEventListener(type, listener, false)
    })
  }
  export const removeEventListeners = (listener) => {
    if (listener) {
      eventTypes.forEach((type) => {
        window.removeEventListener(type, listener, false)
      })
    }
  }