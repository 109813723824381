import {getLoggedInUser} from './API';

const GetLoggedInUser = async(accessToken) => {
    try{
        const response = await getLoggedInUser(accessToken);
        return {success:true, userInfo:response.data}
    } catch(error){
        console.error('Error in getting logged in user: ', error.message)
        return {success:false, data:'Error: API server is down or account does not exist'}
    }
}

export default GetLoggedInUser;