/**
 * Configuring I18next framework to perform language localization 
 */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

const options = {
    order: ['localStorage', 'navigator'],
    lookupLocalStorage: 'language',
}

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: options,
        fallbackLng: 'en-US',
        supportedLngs: ['en-US', 'zh-CN'],
        load: 'currentOnly',
        debug: false,
        ns: ['translation'],
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;