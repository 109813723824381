export const applyFilters = (rows, query, sortByValue, selectedGroups) => {
    if (rows === undefined || rows.length === 0) {
      return []
    }

    let lowerCaseGroupNames = []
  
    if (selectedGroups && selectedGroups.length) {
      lowerCaseGroupNames = selectedGroups.map(g => g.group.name.toLowerCase());
    }
  
    return rows.filter((row) => {
      let matches = true;
      if (row[sortByValue] === null) return false
      if (query && !row[sortByValue ?? 'name'].toLowerCase().includes(query.toLowerCase())) {
        matches = false;
      }
  
      if (lowerCaseGroupNames?.length && 
        !lowerCaseGroupNames?.includes(row.groupName.toLowerCase())) 
      {
        matches = false;
      }
  
      return matches;
    });
  };
  
  export function descendingComparator(a, b, orderBy) {
    if(typeof a[orderBy] === 'number'){
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    }
    if(typeof a[orderBy] === 'string'){
      if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
        return -1;
      }
      if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
        return 1;
      }
    }
    if(typeof a[orderBy] === 'boolean'){
      if (b[orderBy] === false) {
        return -1;
      }
      if (b[orderBy] === true) {
        return 1;
      }
    }
    return 0;
  }
  
  export function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  export const applySorting = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };