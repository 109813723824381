import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@emotion/react';

const useStyles = makeStyles()((theme) => ({
  root: {
    paddingTop: 200,
    paddingBottom: 200,
    [theme.breakpoints.down('xl')]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
  technologyIcon: {
    height: 40,
    margin: theme.spacing(1)
  },
  image: {
    perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[16]
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  },
  loginBtn: {
    padding: '8px 60px'
  }
}));

const Hero = ({ className, ...rest }) => {
  const { t }  = useTranslation();
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const smallMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div
      className={cx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
          style={{marginTop: "36px"}}
        >
          <Grid
            item
            xs={12}
            md={5}
          >
            
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography
                variant="h1"
                color="textPrimary"
              >
                Foundry USA Pool
              </Typography>
              <Box mt={3}>
                <Typography
                  variant="h5"
                  color="textPrimary"
                >
                  {t('introduction')}
                </Typography>
              </Box>
              { smallMobileDevice && <Box mt={4} display="flex" justifyContent="space-around" >
                <Button
                  color="secondary"
                  component="a"
                  href="login"
                  variant="contained"
                  className={classes.loginBtn}
                  >
                {t('login')}
                </Button> 
              </Box> }
            </Box> 
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
