import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(relativeTime);

export const getLocalTimeZoneAndOffset = (value) => {
    const zone = new Date().toLocaleTimeString('en-us',{timeZoneName:'shortOffset'}).split(' ')[2];
    return `${zone}`; //return example: EST -04:00
}

export const setDefaultTimeZone = (timeZoneString) => {
    return dayjs.tz.setDefault(timeZoneString); 
}

export const convertToLocal = (value) => {
    return dayjs(new Date(value))
}

export const convertToLocalISO = dateString => {
   return dayjs(new Date(dateString)).format('YYYY-MM-DD HH:mm');
}

export const getDateFromDateString = dateString => {
    return dateString.slice(0, -19)
}

export const timeSinceDate = dateString => {
    const date = new Date(dateString)
    return dayjs(date).valueOf() === 0 ? "Never" : dayjs(date).fromNow();
}

export const getLocalHoursAndMinutes = utcHourAndMinuteString => {
    var offsetHours = dayjs().utc().tz(dayjs.tz.guess()).format('Z');
    var localHour = ( parseInt(utcHourAndMinuteString.split(':')[0]) + parseInt(offsetHours.split(':')[0]) + 24 ) % 24
    var localMinutes = utcHourAndMinuteString.split(':')[1]
    return String(localHour).padStart(2,'0')+":"+String(localMinutes).padStart(2,'0')
}

export const parseToUTC = value => {
    return dayjs.utc(value)
}