import React, { Fragment, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  FormControl,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  OutlinedInput,
  Checkbox,
  Toolbar,
  Divider,
  TextField,
  Typography,
  useMediaQuery,
  Tooltip,
  SvgIcon,
  InputAdornment,
  useTheme,
} from '@mui/material';
import { Menu as MenuIcon } from 'lucide-react';
import { THEMES } from 'src/constants';
import Account from './Account';
import Autocomplete from '@mui/material/Autocomplete';
import GetCoins from 'src/api/GetCoins';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBitcoin } from '@fortawesome/free-brands-svg-icons';
import Logo from 'src/components/Logo';
import { useTranslation } from 'react-i18next';
import useGetAllSubAccounts from 'src/hooks/useGetAllSubAccounts';

import useSideNav from 'src/hooks/useSideNav';
import useSelectedSubAccount from 'src/hooks/useSelectedSubAccount';
import useSelectedGroup from 'src/hooks/useSelectedGroup';

import useAuth from 'src/hooks/useAuth';
import { makeStyles } from 'tss-react/mui';
import { useLocation, useNavigate, Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles()((theme) => ({
  root: {
    ...(theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      background: theme.palette.primary.main
    } : {}),
    ...(theme.name === THEMES.ONE_DARK ? {
      background: theme.palette.background.default
    } : {})
  },
  toolbar: {
    height: 64
  },
  logo: {
    marginRight: theme.spacing(2)
  },
  logoSmall: {
    width: '50%',
  },
  autoCompleteElem: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1.5),
    width: theme.spacing(26),
    minWidth: 80
  },
  subAccountElem: {
    width: theme.spacing(35),
    paddingRight: theme.spacing(0.5),
  },
  listBox: {
    background: theme.palette.background.default
  },
  metalListBox: {
    background: "var(--neutral-1)",
    padding: 1
  },
  metalRoot: {
    backgroundColor: "var(--neutral-1)",
    boxShadow: 'none',
    paddingRight: 0
  },
  metalLogo : {
    width: '50%',
    minWidth: "150px",
    height: "40px",
    marginLeft: "16px"
  },
  metalTopBar: {
    paddingLeft: "0 !important",
    backgroundColor: 'var(--neutral-1)',
    boxShadow: "none",
    paddingRight: `${theme.spacing(2)} !important`
  },
  smallMetalTopBar: {
    backgroundColor: 'var(--neutral-1)',
    boxShadow: "none",
    paddingRight: `${theme.spacing(2)} !important`
  }

}));

const applyInitialIconStyle = coin => {
  if(coin){
    if(coin === 'BTC'){
      return({color: "#f7931a"})
    }
    if(coin === 'BCH'){
      return({transform:"rotate(-30deg)",color:"#8dc351"})
    }
  }
  return({color: "#f7931a"})
}

const TopBar = (props) => {
  const className = props.className
  const theme = useTheme()
  const onCoinChng = props.onCoinChng
  const onCoinUnitSelectionChange = props.onCoinUnitSelectionChange
  const coin = props.coin
  const coinUnit = props.coinUnit
  const metal = props.metal
  // ...rest

  const { t }  = useTranslation();
  const { classes, cx } = useStyles();
  const [coins, setCoins] = useState([])
  const [iconStyle, setIconStyle] = useState(applyInitialIconStyle(coin))

  const { selectedSubAccount, handleSelectedSubAccountUpdate } = useSelectedSubAccount();
  const { selectedGroup, handleSelectedGroupUpdate, groupDataLoading } = useSelectedGroup();
  const smallMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const mediumMobileDevice = useMediaQuery(theme.breakpoints.down('md'));
  const { navOpen, handleSideNavUpdate } = useSideNav();
  const { loggedInUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const selectedGroups = selectedGroup !== undefined || null ? selectedGroup.map(g => g.group) : JSON.parse(sessionStorage.getItem('selectedGroups'));
  const { dropdownList: allSubAccounts } = useGetAllSubAccounts(coin, true, false, selectedGroups);
  const [ groupNames, setGroupNames]  = useState(loggedInUser.groups.map(groupInfo => groupInfo.group.name).sort());
  
  /**
   * Handes the event of onchange on coin autocomplete. Currently invokes the change state function of the parent
   * @param {*} event onChange
   * @param {*} values 
   */
  const handleCoinAutocompleteChange = (event, values) => { //only fires when an option is selected with the option value
    if(values){
      setIconStyle(applyInitialIconStyle(values.value))
    }
    onCoinChng(values? values.value:'BTC')
  };

  const handleSubAccountAutocompleteChange = (event, values) => {
    if (values?.value) {
      const subAccountDetailRoute =  
        loggedInUser?.adminRole?.adminRead
        ? {pathname: `/app/admin/all-sub-accounts/${values.value.split(',')[0]}/dashboard`, state: { root: t('allSubAccounts')}}
        : { pathname: `/app/sub-accounts/${values.value.split(',')[0]}/dashboard`, state: {root: t('subAccounts')}};
      handleSelectedSubAccountUpdate(values.value);
      navigate(subAccountDetailRoute);
    }
   };

  /**
   * Handes the event of onchange on groups dropdown. Currently invokes the change state function of the parent
   * @param {*} event onChange
   * @param {*} value 
   */
   const handleGroupAutocompleteChange = (event) => {
    //Grabbing value of onChange event
    const {
      target: { value },
    } = event;
    let newGroups = []
      if(!value.length || (value[value.length - 1] === "All Groups")) {
        handleSelectedGroupUpdate(loggedInUser.groups.map(i => {
          return {
            group: {
              id: i.group.id,
              name: i.group.name,
              viewPaymentsPermission: i.defaultSubAccountRole.viewPayments
            }
          }
        }), loggedInUser.groups) //Context
        setGroupNames(loggedInUser.groups.map(groupInfo => groupInfo.group.name).sort()) //State
      }
      else {
        newGroups = value.filter(group => group !== "All Groups") //Removing string value of "All Groups"
        // Find the Group Obj based on Group Name that is selected
        let allGroupObjArr = loggedInUser.groups.filter(g => newGroups.includes(g.group.name)).map(j => {
          return {
            group: {
              id: j.group.id,
              name: j.group.name,
              viewPaymentsPermission: j.defaultSubAccountRole.viewPayments
            }
          }
        })
        handleSelectedGroupUpdate(allGroupObjArr, loggedInUser.groups) //Storing Group Obj (not just Group Name) in Context
        setGroupNames(
          typeof value === 'string' ? value.split(',') : newGroups.length ? newGroups : value,
        )//State value for Dropdown
    }


  };

  const fetchData = useCallback(async () => {
    
    const {success, data} = await GetCoins();
    
    if (success) {
      var coinsFromDB = []
      data.map(coinInstance => 
        coinsFromDB.push({"text":coinInstance, "value":coinInstance})
        )
      setCoins(coinsFromDB)      
    } else {
      console.log('error getting coin list from the database error is '+data)
      console.log(data);
    }

    if(!selectedGroup.length){
      handleSelectedGroupUpdate(loggedInUser.groups.map(i => {
        return {
          group: {
            id: i.group.id,
            name: i.group.name,
            viewPaymentsPermission: i.defaultSubAccountRole.viewPayments
          }
        }
      }), loggedInUser.groups) //Initial Context
    }
  }, []);
  
  useEffect(() => {
    fetchData()
  }, [fetchData]);

  useEffect(()=>{
    let sessionGroupsValue = sessionStorage.getItem('selectedGroups') || JSON.stringify([])
    if(JSON.parse(sessionGroupsValue).length) {
      sessionGroupsValue = loggedInUser.groups.filter(i => JSON.parse(sessionGroupsValue).filter(j => i.group.id === j.group.id))
      if(loggedInUser.groups.length === sessionGroupsValue.length){
        let arr = loggedInUser.groups.filter(i => {
          return selectedGroup.some(j => {
            return j.group.id === i.group.id
          })
        })
        setGroupNames(arr.map(groupInfo => groupInfo.group.name).sort())
      }
      else {
        setGroupNames(
          JSON.parse(sessionGroupsValue).map(i => i.group.name).sort()
        )
      }
    }
    else {
      sessionStorage.setItem('selectedGroups', JSON.stringify(loggedInUser.groups.map(i => {
        return {
          group: {
            id: i.group.id,
            name: i.group.name,
            viewPaymentsPermission: i.defaultSubAccountRole.viewPayments
          }
        }
      })))
    }
  }, [loggedInUser])

  useEffect(() => {
    const r1 = /\/app\/admin\/all-sub-accounts\/\w/;
    const r2 = /\/app\/sub-accounts\/\w/;

    if(!location.pathname.match(r1) && !location.pathname.match(r2)) {
      handleSelectedSubAccountUpdate('No Sub-Account Selected')
    }

    if(location.pathname.match(r2) && location.pathname.split('\/').length >= 4 &&
      allSubAccounts.length && !allSubAccounts.map(acct => acct.text.split('\,')[0]).includes(location.pathname.split('\/')[3])) {
        navigate('/app/sub-accounts', {replace: true})
        handleSelectedSubAccountUpdate('No Sub-Account Selected')
    }
  },[location, allSubAccounts])

  return (
    <AppBar
      enableColorOnDark={true}
      className={metal ? cx(classes.metalRoot, className): cx(classes.root, className)}
      // {...rest}
    >
      <Toolbar 
        className={metal ? mediumMobileDevice ? classes.smallMetalTopBar : classes.metalTopBar : ""}
        style={
          smallMobileDevice
            ? {paddingLeft: theme.spacing(1)}
            : {paddingLeft: theme.spacing(2)} 
          }>
          { mediumMobileDevice ? 
            <IconButton
              color="textPrimary"
              onClick={() => handleSideNavUpdate(!navOpen)}
              size="large"
              style={{ padding: 10}}>
              <SvgIcon fontSize="small">
                <MenuIcon />
              </SvgIcon>
            </IconButton> :
            <RouterLink style={metal ? {
              minWidth: "256px",
              width: "256px",
              borderRight: "1px solid var(--uishell-color-border)",
              height: "67px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              backgroundColor: "var(--neutral-2)"
            } : {}} to={window.location.host === "foundryusapool.com" ? "https://foundrydigital.com/mining-service/foundry-usa-pool/" : "/"}>
              <Logo className={metal ? classes.metalLogo  : classes.logoSmall}/>
            </RouterLink>}
        {!smallMobileDevice && <Box
          ml={2}
          flexGrow={1}
        />}
          
          {
            !smallMobileDevice && loggedInUser.groups.length > 1
              ? (
                <FormControl sx={{ m: 1, mt:1.5,  width: 300, minWidth: 90 }}>
                  <InputLabel id="multiple-checkbox-label">Groups</InputLabel>
                  <Select
                    SelectDisplayProps={{ style: { paddingTop: 12, paddingBottom: 12 } }}
                    labelId="multiple-checkbox-label"
                    id="multiple-checkbox"
                    multiple
                    value={groupNames.sort()}
                    onChange={handleGroupAutocompleteChange}
                    input={<OutlinedInput label="Groups" />}
                    renderValue={(selected) => {
                      return selected.join(', ')
            
                    }}
                    MenuProps={{
                      PaperProps: metal ? {
                        sx: {
                          bgcolor: 'var(--neutral-1)',
                          '& .MuiMenuItem-root': {
                            paddingTop: 1,
                          },
                        },
                      }: {},
                    }}

                  >
                    <MenuItem disabled={(groupNames.length === loggedInUser.groups.length) || groupDataLoading} value={"All Groups"}>
                      <ListItemText primary={["All Groups"]} />
                      <Checkbox 
                        checked={JSON.stringify(groupNames) === JSON.stringify(['All Groups']) || groupNames.length === loggedInUser.groups.length} 
                        disabled={groupDataLoading}
                        sx={metal ? {
                          color: "rgb(128,128,128)",
                          '& .Mui-checked': {
                            color: "var(--color-menu-selected)"
                          },
                        } : {}}
                      />
                    </MenuItem>
                    <Divider />
                      {loggedInUser.groups.map(groupInfo => groupInfo.group.name).sort().map((group) => {
                        return (
                          <MenuItem key={group} value={group} disabled={groupDataLoading}>
                            <ListItemText primary={group} />
                            <Checkbox 
                              checked={(groupNames.indexOf(group) > -1 || (JSON.stringify(groupNames) === JSON.stringify(['All Groups']) || groupNames.length === loggedInUser.groups.length))}
                              sx={metal ? {
                                color: "rgb(128,128,128)",
                                '& .Mui-checked': {
                                  color: "var(--color-menu-selected)"
                                },
                              } : {}}
                            />
                          </MenuItem>
                        )
                      }
                    )}
                  </Select>
                </FormControl>                      
              )
              : smallMobileDevice && loggedInUser.groups.length > 1
                ?
                <FormControl sx={{ m: 1, mt:1.5, width: theme.spacing(35) }}>
                  <Select
                    SelectDisplayProps={{ style: { paddingTop: 12, paddingBottom: 12 } }}
                    labelId="multiple-checkbox-label"
                    id="multiple-checkbox"
                    multiple
                    value={groupNames.sort()}
                    onChange={handleGroupAutocompleteChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                      return selected.join(', ')
            
                    }}
                    
                  >
                    <MenuItem disabled={(groupNames.length === loggedInUser.groups.length) || groupDataLoading} value={"All Groups"}>
                      <ListItemText primary={["All Groups"]} />
                      <Checkbox 
                        checked={JSON.stringify(groupNames) === JSON.stringify(['All Groups']) || groupNames.length === loggedInUser.groups.length}
                        sx={metal ? {
                          color: "rgb(128,128,128)",
                          '& .Mui-checked': {
                            color: "var(--color-menu-selected)"
                          },
                        } : {}}
                      />
                    </MenuItem>
                    <Divider />
                      {loggedInUser.groups.map(groupInfo => groupInfo.group.name).sort().map((group) => {
                        return (
                          <MenuItem key={group} value={group} disabled={groupDataLoading}>
                            <ListItemText primary={group} />
                            <Checkbox 
                              checked={(groupNames.indexOf(group) > -1) || JSON.stringify(groupNames) === JSON.stringify(['All Groups']) || groupNames.length === loggedInUser.groups.length}
                              sx={metal ? {
                                color: "rgb(128,128,128)",
                                '& .Mui-checked': {
                                  color: "var(--color-menu-selected)"
                                },
                              } : {}}
                            />

                          </MenuItem>
                        )
                      }
                    )}
                  </Select>
                </FormControl>
                : null
              }
            {!smallMobileDevice ?(
              <Autocomplete
                getOptionLabel={(option) => option.text}
                isOptionEqualToValue={(option, value) => option.value === value.value }
                onChange={(event, value) => handleSubAccountAutocompleteChange(event, value)}
                options = {allSubAccounts}
                noOptionsText={allSubAccounts.length ? 'No results found' : 'Loading...' }
                disableClearable={true}
                value={selectedSubAccount?{ text: selectedSubAccount, value: selectedSubAccount }:null}
                className={`${classes.autoCompleteElem} ${classes.subAccountElem}`}
                ListboxProps={{
                  className: metal ? classes.metalListBox : classes.listBox
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    label={t('subAccount')}
                    name="subAccounts"
                    variant="outlined"
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      style: { paddingTop: "3px", paddingBottom:"3px" }
                    }}
                  />
                )}
              />
            ):<Autocomplete
                    getOptionLabel={(option) => option.text}
                    isOptionEqualToValue={(option, value) => option.value === value.value }
                    onChange={handleSubAccountAutocompleteChange}
                    options = {allSubAccounts}
                    noOptionsText={allSubAccounts.length ? 'No results found' : 'Loading...' }
                    disableClearable={true}
                    value={selectedSubAccount?{ text: selectedSubAccount, value: selectedSubAccount }:null}
                    className={`${classes.autoCompleteElem} ${classes.subAccountElem}`}
                    ListboxProps={{
                      className: metal ? classes.metalListBox : classes.listBox
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        name="subAccounts"
                        variant="outlined"
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          style: { paddingTop: "3px", paddingBottom:"3px" }
                        }}
                      />
                    )}
            />}
            
            {!smallMobileDevice?(<Autocomplete
                    getOptionLabel={(option) => option.text}
                    isOptionEqualToValue={(option, value) => option.text === value.text }
                    onChange={handleCoinAutocompleteChange}
                    disableClearable={true}
                    options={coins}
                    value={{ text: coin, value: coin }}
                    className={classes.autoCompleteElem}
                    ListboxProps={{
                      className: metal ? classes.metalListBox : classes.listBox
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        label={t('coin')}
                        name="coins"
                        variant="outlined"
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          style: { paddingTop: "3px", paddingBottom:"3px", width: theme.spacing(2) }
                        }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                           !mediumMobileDevice ? <InputAdornment position='start'>
                              <FontAwesomeIcon size="lg" icon={faBitcoin} style={iconStyle}/>
                            </InputAdornment> : null 
                          )
                        }}
                      />
                      
                    )}
                    renderOption={(props, option) => {
                      return ( <li {...props}>
                                <FontAwesomeIcon icon={faBitcoin} 
                                  style={{...applyInitialIconStyle(option.text), marginRight:theme.spacing(1)}}/>
                                <Typography>{option.text}</Typography>                                
                                </li>);
                    }}
            />):(<Autocomplete
                    getOptionLabel={(option) => option.text}
                    isOptionEqualToValue={(option, value) => option.text === value.text }
                    onChange={handleCoinAutocompleteChange}
                    disableClearable={true}
                    options={coins}
                    value={{ text: coin, value: coin }}
                    className={classes.autoCompleteElem}
                    ListboxProps={{
                      className: metal ? classes.metalListBox : classes.listBox
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        name="coins"
                        variant="outlined"
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          style: { paddingTop: "3px", paddingBottom:"3px", width: theme.spacing(2) }
                        }}
                      />
                      
                    )}
            />)}
        {
          !metal && <Divider orientation="vertical" flexItem style={smallMobileDevice ? {marginLeft:theme.spacing(0.5)} : {marginLeft:theme.spacing(1)}}/>
        }
        <Box ml={2}>
          <Account 
            coinUnit={coinUnit} 
            onCoinUnitSelectionChange={onCoinUnitSelectionChange} 
            coin={coin}
            metal={metal}
            />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
