import React, {createContext, useState} from "react";
import { BTC } from "src/constants";

const SelectedCoinContext = createContext({
    coinSelection: null,
    handleSelectedCoinUpdate: () => {}
});


export const SelectedCoinProvider = ({ children }) => {
    
    const storageValue = sessionStorage.getItem('selectedCoin') || BTC
    const [selectedCoin, setSelectedCoin] = useState(storageValue)
    const handleSelectedCoinUpdate = (selectedCoinUpdate) => {
        setSelectedCoin(selectedCoinUpdate);
    }

	return (
	<>
		<SelectedCoinContext.Provider 
            value={{ selectedCoin, handleSelectedCoinUpdate }}
        >
            {children}
		</SelectedCoinContext.Provider>
	</>
	);
}

export default SelectedCoinContext;
