import React, {createContext, useState} from "react";

const SelectedGroupContext = createContext({
    groupSelection: null,
    handleSelectedGroupUpdate: () => {},
    groupDataLoading: false
});


export const SelectedGroupProvider = ({ children }) => {
    
    const storageValue = JSON.parse(sessionStorage.getItem('selectedGroups')) || []
    const [selectedGroup, setSelectedGroup] = useState(storageValue)
    const [ groupDataLoading, setGroupDataLoading] = useState(false)
    const handleSelectedGroupUpdate = (selectedGroupUpdate, userGroups) => {
        setSelectedGroup(userGroups.filter(i => selectedGroupUpdate.some(j => j.group.id === i.group.id)));
        sessionStorage.setItem('selectedGroups', JSON.stringify(selectedGroupUpdate))
    }
    const handleGroupDataLoadingUpdate = (update) => {
        setGroupDataLoading(update)
    } 

	return (
	<>
		<SelectedGroupContext.Provider 
            value={{ selectedGroup, handleSelectedGroupUpdate, groupDataLoading, handleGroupDataLoadingUpdate }}
        >
            {children}
		</SelectedGroupContext.Provider>
	</>
	);
}

export default SelectedGroupContext;

