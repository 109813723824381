/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  CircularProgress,
  Divider,
  Drawer,
  List,
  ListSubheader,
  ListItemButton,
  ListItemText,
  Collapse,
  useMediaQuery,
  useTheme,
  ButtonBase,
  Switch,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import useAuth from 'src/hooks/useAuth';
import LanguageSwitcher from 'src/components/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import useSideNav from 'src/hooks/useSideNav';
import { makeStyles } from 'tss-react/mui';
import { useFlag } from "@unleash/proxy-client-react"
import { datadogRum } from '@datadog/browser-rum';

import { ChevronUp, ChevronDown } from 'lucide-react';


function renderNavItems({
  items,
  pathname,
  depth = 0,
  translation,
  metal
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth, translation, metal }),
        []
      )}
    </List>
  );
}

const poolHashrateNav = {title: 'poolHashrate',icon: 'AreaChart',href: '/app/admin/pool-statistics/pool-hashrate'}
const blocksNav = {title: 'blocks', icon: 'Boxes', href: '/app/admin/pool-statistics/blocks'}
const poolDailyAvgStatsNav = {title: 'poolDailyStatistics', icon: 'BarChart3', href: '/app/admin/pool-statistics/pool-daily-hashrate'}
const allSubAccountsNav = {title: 'allSubAccounts', icon: 'Users', href: '/app/admin/all-sub-accounts'}
const allSubAccountFinancialsNav = {title: 'allSubAccountFinancials',icon: 'Coins',href: '/app/admin/sub-account-financials'}
const groupUserNav = {title: 'groupUserManagement',icon: 'Users',href: '/app/admin/group-user'}
const faqNav = {title: 'faqs',icon: 'HelpCircle',href: '/app/help/faqs'}
const statusNav = {title: 'status',icon: 'ActivitySquare',href: '/app/help/status'}
const apiDocNav = {title: 'apiDocumentation',icon: 'FileCode2',href: '/app/help/api-documentation'}
const tosNav = {title: 'termsOfService',icon: 'Scale',href: '/app/help/terms-of-service'}
const accountOverviewNav = {title: 'accountOverview',icon: 'LayoutGrid',href: '/app/account-overview'}
const subAccountsNav = {title: 'subAccounts',icon: 'Network',href: '/app/sub-accounts'}
const userManagementNav = {title: 'userManagement',icon: 'Users',href: '/app/user-management'}
const financialOverviewNav = {title: 'financialOverview',icon: 'Wallet',href: '/app/financial-overview'}
const auditLog = {title: 'auditLog',icon: 'ScrollText',href: '/app/audit-log'}
const calculator = {title: 'fppsCalculator',icon: 'Calculator',href: '/calculator-beta', target: "_blank"}


const poolStatisticsCollapsibleNav = {
  title: 'poolStatistics', 
  icon: 'AreaChart', 
  href: '/app/admin/pool-statistics',
  items: [
    poolHashrateNav,
    poolDailyAvgStatsNav,
    blocksNav
  ]
}

const helpCollapsibleNav = {
  title: 'help', 
  icon: 'HelpCircle',
  href: '/app/help',
  items: [
    faqNav,
    statusNav,
    tosNav
  ]
}

const adminSection = [poolStatisticsCollapsibleNav, allSubAccountsNav, allSubAccountFinancialsNav, groupUserNav]
const supportSection = [apiDocNav, calculator, helpCollapsibleNav]
const ungroupedSection1 = [accountOverviewNav, subAccountsNav]
const ungroupedSection2 = [financialOverviewNav, userManagementNav]
const auditLogSection = [auditLog]

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
  translation,
  metal
}) {
  const key = item.title + depth;
  
  var title = translation(item.title);

  if (item.items) {
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={title === 'Pool Statistics' ? true : false}
        title={title}
        metal={metal}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          translation: translation,
          metal
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        target={item.target}
        icon={item.icon}
        info={item.info}
        key={key}
        title={title}
        metal={metal}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles()(() => ({
  mobileDrawer: {
    width: 256,
    backgroundImage: 'unset'
  },
  metalMobileDrawer:{
    width: 256,
    backgroundColor: "var(--neutral-2)"
  },

  desktopDrawer: {
    width: 230,
    top: 64,
    height: 'calc(100% - 64px)',
    zIndex: 1000
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  logoSmall: {
    width: '75%'
  },
  metalDesktop: {
    width: 256,
    top: 67,
    height: 'calc(100% - 64px)',
    zIndex: 1000,
    backgroundColor:" var(--uishell-color-surface)",
    borderTop: "2px solid var(--color-border)"
  }
}));

const formSections = (user, fpps) => {
  const sectionWithoutCalculator = supportSection.filter((calc) => calc.title !== "fppsCalculator");

  var sections = []
  if (user.adminRole.adminRead){
    sections.push({
      subheader: 'Ungrouped',
      items: ungroupedSection1
    })
    sections.push({
      subheader: 'Ungrouped',
      items: ungroupedSection2
    })
    sections.push({
      subheader: 'Ungrouped',
      items: auditLogSection
    })
    sections.push({
      subheader: 'Support',
      items: fpps ? supportSection : sectionWithoutCalculator
    })
    sections.push({
      subheader: 'admin',
      items: adminSection
    })
  }
  else {
    const ungroupedSection2Items = [financialOverviewNav]
    const manageUsersPermission = user.groups?.filter(group => !!group.userRole.manageUsers)?.length > 0;
    if (manageUsersPermission) {
      ungroupedSection2Items.push(userManagementNav)
    }
    
    sections.push({
      subheader: 'Ungrouped',
      items: ungroupedSection1
    })
    sections.push({
      subheader: 'Ungrouped',
      items: ungroupedSection2Items
    })

    const viewPaymentPermission = user?.groups?.map(g => g.defaultSubAccountRole.viewPayments)?.filter(payPermission => !!payPermission)?.length > 0;

    if (viewPaymentPermission) {
      sections.push({
        subheader: 'Ungrouped',
        items: auditLogSection
      })
    }

    sections.push({
      subheader: 'Support',
      items: fpps ? supportSection : sectionWithoutCalculator
    })
  }
  return sections
}

const NavBar = (props) => {
  const { navLoading, metal, setMetal} = props
  const redesignFlag = useFlag("metal-redesign")
  const fpps = useFlag("fpps")
  const { t } = useTranslation();
  
  const { classes } = useStyles();
  const location = useLocation();
  const { user, loggedInUser } = useAuth();
  const { navOpen, handleSideNavUpdate } = useSideNav();
  var sections = formSections(loggedInUser, fpps)
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));
  const isFirefox = typeof InstallTrigger !== 'undefined';

  const [previewOpen, setPreviewOpen] = useState(false);

  const handleClick = () => {
    setPreviewOpen(!previewOpen);
  };

  const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 70,
    border: "1px solid #00E67E",
    borderRadius: 4,
    overflow: "hidden",
    '&:hover, &.Mui-focusVisible': {
      zIndex: 1,
      '& .MuiImageBackdrop-root': {
        opacity: 0,
      },
      '& .MuiImageMarked-root': {
        opacity: 0,
      },
      '& .MuiTypography-root': {
        border: '4px solid currentColor',
        opacity: 0
      },
    },
  }));
  
  const ImageSrc = styled('span')({
    height: 70,
    width: 180,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: "no-repeat"
  });
  
  const Image = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white
  }));
  
  const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.8,
    transition: theme.transitions.create('opacity'),
  }));
  
  const ImageMarked = styled('span')(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: 4,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  }));

  useEffect(() => {
    if (handleSideNavUpdate) {
      handleSideNavUpdate(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleMetalToggle = () => {
    datadogRum.addAction('metal-toggle', {
        'metal-set-to': !metal,
        "user": user.id
    })
    localStorage.setItem('metalEnabled', `${!metal}`)
    setMetal(!metal)
    setPreviewOpen(false)
  }

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        {
          mobileDevice &&
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to={window.location.host === "foundryusapool.com" ? "https://foundrydigital.com/mining-service/foundry-usa-pool/" : "/"} style={isFirefox ? {width: "100%"} : {}}>
              <Logo className={classes.logoSmall}/>
            </RouterLink>
          </Box>
        }
        
        {!metal && <Divider />}
        {
          redesignFlag && 
          <List
            sx={{width: '100%', bgcolor: metal ? 'var(--neutral-1)' : '#282C34' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <ListItemButton disableRipple onClick={handleClick}>
              <ListItemText disableTypography>
                <div className='m-heading' style={{padding: "0 8px"}}>
                  {
                    metal ? "This is Foundry's new look!" : "Try Foundry's new look!"
                  }
                </div>    
              </ListItemText>
              {previewOpen ? <ChevronUp size={20} style={{color: 'var(--color-text-accent)'}} /> : <ChevronDown size={20}/>}
            </ListItemButton>
            <Collapse in={previewOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding sx={{marginLeft: "1.5em"}}>
                <label className='m-text' style={{marginRight: 5}}>Off</label>
                  <Switch
                    size={metal ? "small" : "medium"}
                    onChange={handleMetalToggle}
                    checked={metal}>
                  </Switch>
                <label className='m-text' style={{marginLeft: 5}}>On</label>
              </List>
            </Collapse>
          </List>
                  
        }
        <Divider />
        {navLoading?(<Box display="flex" justifyContent="center" mt={25}><CircularProgress></CircularProgress></Box>):(<Box pl={2}
            pr={metal ? 2 : 0}
            pt={2}
            pb={2}>
          {sections?sections.map((section, index) => (
            <List
              key={index}
              style={{paddingBottom: 0}}
              subheader={(
                <ListSubheader
                  style={metal ? {lineHeight: "36px", fontWeight: "700", color: "var(--color-text)"} : {}}
                  disableGutters
                  disableSticky
                >
                  {section.subheader.includes('admin') || section.subheader.includes('Support') ?t(section.subheader):''}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
                translation: t,
                metal
              })}
            </List>
          )):null}
        </Box>)}
          <RouterLink style={{ textDecoration: 'none', cursor: 'pointer', padding: metal ? theme.spacing(2) : '1rem' }} to={"https://services.foundrydigital.com/foundryx-machine-inventory-1?utm_campaign=FoundryX&utm_source=pool&utm_medium=clicklink"} target="_blank" rel="noopener noreferrer">
            <ImageButton
              focusRipple
              style={{
                width: metal ? 223 : 200,
                marginBottom: 20
              }}
            >
              <ImageSrc style={{ backgroundImage: `url(https://foundrydigital.com/wp-content/uploads/2023/05/Foundry-X-2.svg)` }} />
              <ImageBackdrop className="MuiImageBackdrop-root" />
              <Image>
                <Typography
                  component="span"
                  variant="subtitle1"
                  color="inherit"
                  sx={{
                    position: 'relative',
                    p: 4,
                    pt: 2,
                    pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                    fontSize: "14px"
                  }}
                >
                  Buy Machines
                  <ImageMarked className="MuiImageMarked-root" />
                </Typography>
              </Image>
            </ImageButton>
          </RouterLink>
      </PerfectScrollbar>
      <LanguageSwitcher/>
    </Box>
  );

  return <>
    {
      !mobileDevice ? 
      <Drawer
        anchor="left"
        classes={metal ? {paper: classes.metalDesktop} :{ paper: classes.desktopDrawer }}
        open
        variant="persistent"
      >
        {content}
      </Drawer> :
      <Drawer
        anchor="left"
        classes={{ paper: metal ? classes.metalMobileDrawer : classes.mobileDrawer }}
        onClose={() => handleSideNavUpdate(false)}
        open={navOpen}
        variant="temporary"
      >
      {content}
    </Drawer>
    }
  </>;
};

export default NavBar;
