import React, { useState, lazy, useEffect, useCallback, Suspense } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import NavBar from './NavBar';
import TopBar from './TopBar';
import AuthenticateAPIKey from  'src/api/AuthenticateAPIKey';
import { Helmet } from 'react-helmet';
import LoadingScreen from 'src/components/LoadingScreen';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Container } from '@mui/material';
import "../../../node_modules/@foundry/metal/dist/css/metal.css";
import { useFlag } from '@unleash/proxy-client-react';

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    padding: "0"
  },
  metalBackground: {
    backgroundColor: 'var(--neutral-1)'
  },
  metalWrapper: {
    backgroundColor: "var(--neutral-1)",
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 256
    }
  }
}));

const ViewerDashboardLayout = ({ children }) => {

  function getInitialWorkerStatus(urlParams, subAccount, coin, apiKey){
    if(urlParams.get('selectedWorkerStatus')){      
      //removes the unnecessary parameters in the URL
      window.history.replaceState(null, null, window.location.pathname+"?subaccount="+subAccount+"&coin="+coin+"&api_key="+apiKey);

      return urlParams.get('selectedWorkerStatus')
    }
    
    return null;
  }

  function getInitialMetalState() {
    let initialMetalState = true;
    if(localStorage.getItem('metalEnabled')) {
      initialMetalState = localStorage.getItem('metalEnabled') === 'true' ? true : false
    }
    return initialMetalState;
  }

  const { classes, cx } = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  
  const urlParams = new URLSearchParams(window.location.search);
  const subAccount = urlParams.get('subaccount');
  const coin = urlParams.get('coin');
  const apiKey = urlParams.get('api_key');
  const workerStatus = getInitialWorkerStatus(urlParams, subAccount, coin, apiKey);

  const [authenticated, setAuthenticated] = useState(null)
  const redesignFlag = useFlag("metal-redesign")
  const [metal, setMetal] = useState(getInitialMetalState() && redesignFlag);

  // components for viewer dashboards
  var AppRedirectionComp = () => <Navigate to={{pathname: "dashboard", search:urlParams.toString()}} />  
  var DashboardViewerComp = lazy(() => import('src/views/reports/HashrateView')) 
  var WorkersViewerComp = lazy(() => import('src/views/viewerDashboards/WorkersDashboard')) 
  var DailyHashrateViewerComp = lazy(() => import('src/views/viewerDashboards/DailyHashrateDashboard'))

  const fetchData = useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const apiKey = urlParams.get('api_key');
    const response = await AuthenticateAPIKey(apiKey)
    if(response.success){
      setAuthenticated(true)
    }
    else{
      setAuthenticated(false)
    }
    
  }, []);

  useEffect(() => {
    fetchData()
  }, [fetchData]);

  return (
    <div className={metal ?  cx(classes.root, classes.metalBackground) : classes.root}>
      <TopBar 
        onMobileNavOpen={() => setMobileNavOpen(true)} 
        coin={coin} 
        subAccount={subAccount}
        metal={metal}
      />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        metal={metal}
        setMetal={setMetal}
      />
      <div className={metal ? classes.metalWrapper : classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Container maxWidth="lg">
              <Suspense fallback={<LoadingScreen metal={metal} />}>
                <Routes>
                  <Route path='dashboard' element={<DashboardViewerComp {...children} coin={coin} apiKey={apiKey} authenticated={authenticated} subAccountSelection={subAccount} isViewerComponent={true} metal={metal} />}/>
                  <Route path='workers' element={<WorkersViewerComp coin={coin} apiKey={apiKey} authenticated={authenticated} subAccount={subAccount} workerStatus={workerStatus} metal={metal} />}/>
                  <Route path='daily-hashrate' element={<DailyHashrateViewerComp coin={coin} apiKey={apiKey} authenticated={authenticated} subAccount={subAccount} metal={metal} />}/>  
                  <Route index element={<AppRedirectionComp coin={coin} apiKey={apiKey} authenticated={authenticated} subAccount={subAccount} />}/>
                </Routes>
              </Suspense>
            </Container>
          </div>
        </div>
      </div>
      <Helmet>
        <script src="https://cy3d3zcbp37t.statuspage.io/embed/script.js"></script>
      </Helmet>
    </div>
  );
};

ViewerDashboardLayout.propTypes = {
  children: PropTypes.node
};

export default ViewerDashboardLayout;
