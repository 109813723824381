import React from 'react';
import { StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import GlobalStyles from '@mui/material/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import { AuthProvider } from 'src/contexts/Auth0Context';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import { renderRoutes } from 'src/routes';

const App = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
    language: settings.language
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <SnackbarProvider dense maxSnack={3}>
              <BrowserRouter>
                  <AuthProvider>
                    <GlobalStyles styles={{
                      '*': {
                        boxSizing: 'border-box',
                        margin: 0,
                        padding: 0,
                      },
                      html: {
                        WebkitFontSmoothing: 'antialiased',
                        MozOsxFontSmoothing: 'grayscale',
                        height: '100%',
                        width: '100%'
                      },
                      body: {
                        height: '100%',
                        width: '100%'
                      },
                      '#root': {
                        height: '100%',
                        width: '100%'
                      }
                    }} />
                    <ScrollReset />
                    { renderRoutes() }
                  </AuthProvider>
              </BrowserRouter>
            </SnackbarProvider>
          </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
