import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'prismjs/prism';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'nprogress/nprogress.css';
import 'src/assets/css/prism.css';
import 'src/mixins/chartjs';
import React from 'react';
import { Provider } from 'react-redux';
import { enableES5 } from 'immer';
import * as serviceWorker from 'src/serviceWorker';
import store from 'src/store';
import { SettingsProvider } from 'src/contexts/SettingsContext';
import App from 'src/App';
import 'src/i18n';
import { SideNavProvider } from './contexts/SideNavContext';
import { SelectedSubAccountProvider } from './contexts/SelectedSubAccountContext';
import { SelectedGroupProvider } from './contexts/SelectedGroupContext';
import { FlagProvider } from'@unleash/proxy-client-react';
import { createRoot } from 'react-dom/client';
import { SelectedCoinProvider } from './contexts/SelectedCoinContext';
import { datadogRum } from '@datadog/browser-rum'

datadogRum.init({
  applicationId: 'c2675e2b-51da-4489-b59f-4565456b1a5c',
  clientToken: process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'foundry-usa-pool',
  env: 'prod',
  version: '1.0.0', 
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});


const config = {
  url: `https://${process.env.REACT_APP_UNLEASH_PROXY_URL}`,
  clientKey: `pool-client-app`,
  refreshInterval: 15,
  appName: process.env.REACT_APP_UNLEASH_APP_NAME
}

enableES5();

const rootContainer =  document.getElementById('root');
const root = createRoot(rootContainer);

root.render(
  <Provider store={store}>
    <SettingsProvider>
      <SideNavProvider>
        <SelectedCoinProvider>
          <SelectedGroupProvider>
            <SelectedSubAccountProvider>
              <FlagProvider config={config}>
                <App />
              </FlagProvider>
            </SelectedSubAccountProvider>
          </SelectedGroupProvider>
        </SelectedCoinProvider>
      </SideNavProvider>
    </SettingsProvider>
  </Provider>
);

serviceWorker.register();
