import React from 'react';
import {  useMediaQuery, useTheme } from '@mui/material';

const Logo = (props) => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <img
      alt="Logo"
      src="/static/logo-foundry-usa-pool-auth0-inverse.svg"
      style={mobileDevice ? {minWidth: "128px"} : {minWidth: "150px"}}
      {...props}
    />
  );
}

export default Logo;
