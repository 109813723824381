import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import NavBar from './NavBar';
import TopBar from './TopBar';
import React, {
  useState,
  lazy,
  useEffect,
  Suspense
} from 'react';
import AdminGuard from 'src/components/AdminGuard';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Helmet } from 'react-helmet';
import { BCH, BTC, SATS } from '../../constants'
import { TimeoutLogic } from 'src/components/TimeoutLogic';
import useSelectedSubAccount from 'src/hooks/useSelectedSubAccount';
import { useUnleashContext } from '@unleash/proxy-client-react';
import useAuth from 'src/hooks/useAuth';

import LoadingScreen from 'src/components/LoadingScreen';
import { Route, useLocation, useNavigate, Routes, Outlet, Navigate } from 'react-router-dom';
import { useFlag } from '@unleash/proxy-client-react';
import useSelectedCoin from 'src/hooks/useSelectedCoin';
import "../../../node_modules/@foundry/metal/dist/css/metal.css";

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 256
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  contentContainerMobile: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    padding: '0',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    paddingBottom: 60,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 0
    },
  },
  metalContent:{
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    paddingLeft: 0
  },
  redesignBackground: {
    backgroundColor: "var(--neutral-1)"
  },
  metalWrapper: {
    backgroundColor: "var(--neutral-1)",
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 256
    },
  }
}));

const DashboardLayout = ({ children }) => {
  /**
   * Changes the coin (state). The function is / can be invoked by children of dashboard layout
   * @param {*} newValue 
   */
  const { handleSelectedCoinUpdate} = useSelectedCoin();
  function handleCoinChange(newValue) {
    sessionStorage.setItem('selectedCoin', newValue);
    setCoin(newValue)
    handleSelectedCoinUpdate(newValue);
    handleCoinUnitChange(coinUnit == SATS ? SATS : newValue)
  }

  function getInitialCoinState() {
    let initialCoinState = BTC;
    if (sessionStorage.getItem('selectedCoin')) {
      initialCoinState = sessionStorage.getItem('selectedCoin')
    }
    return initialCoinState
  }

  function handleCoinUnitChange(newValue) {
    sessionStorage.setItem('selectedCoinUnit', newValue);
    setCoinUnit(newValue)
  }

  function getInitialCoinUnitState() {
    let initialCoinUnitState = BTC;
    if (sessionStorage.getItem('selectedCoinUnit')) {
      initialCoinUnitState = sessionStorage.getItem('selectedCoinUnit')
    }
    return initialCoinUnitState
  }

  function getInitialMetalState() {
    let initialMetalState = true;
    if(localStorage.getItem('metalEnabled')) {
      initialMetalState = localStorage.getItem('metalEnabled') === 'true' ? true : false
    }
    return initialMetalState;
  }

  const { classes, cx } = useStyles();
  const [coin, setCoin] = useState(getInitialCoinState()) //represents the coin value in the layout state corresponding to the coins dropdown in topbar 
  const navigate = useNavigate();
  const location = useLocation();
  const [coinUnit, setCoinUnit] = useState(getInitialCoinUnitState())
  const { handleSelectedSubAccountNavUpdate } = useSelectedSubAccount();
  const { loggedInUser } = useAuth();
  const updateUnleashContext = useUnleashContext();

  const redesignFlag = useFlag("metal-redesign")
  const [ metal, setMetal ] = useState(getInitialMetalState() && redesignFlag)

  useEffect(() => {
    if(location && location.pathname?.match('sub-accounts') && !location.state?.view) {
      handleSelectedSubAccountNavUpdate('0')
    } else {
      handleSelectedSubAccountNavUpdate('4')
    }
    window.scrollTo(0,0)
  }, [location])

  // Update Unleash context with user's group name when they login
  useEffect(() => {
    const groupId = sessionStorage.getItem('groupId')
    updateUnleashContext({ userId: groupId });
}, [loggedInUser])


  // components for child routes of dashboard layout  
  const AppRedirectionComp = () => <Navigate to="admin/pool-statistics/pool-hashrate" />
  const PoolHashrateComp = lazy(() => import('src/views/reports/PoolHashrateView'))
  const AccountOverviewComp = lazy(() => import('src/views/reports/Dashboard'))
  const BlocksComp = lazy(() => import('src/views/reports/BlocksView'))
  const PoolDailyHashrateComp = lazy(() => import('src/views/reports/PoolDailyHashrateView'))
  const AllSubAccountsComp = lazy(() => import('src/views/reports/AllSubAccountsView'))
  const AllSubAccountsFinancialComp = lazy(() => import('src/views/reports/AllSubAccountsFinancialView'))
  const GroupUserComp = lazy(() => import('src/views/reports/GroupUserView'))
  const SubAccountsComp = lazy(() => import('src/views/reports/SubAccountsView'))
  const FinancialOverviewComp = lazy(() => import('src/views/reports/FinancialOverview'))
  const UserManagementComp = lazy(() => import('src/views/reports/UserManagementView'))
  const SubAccountDetailComp = lazy(() => import('src/views/reports/SubAccountDetailView'))
  const AuditLogView = lazy(() => import('src/views/reports/AuditLogView'))

  return (
    <div className={metal ? cx(classes.root, classes.redesignBackground) : classes.root}>
      <TimeoutLogic />
      <TopBar onCoinChng={handleCoinChange} coin={coin} coinUnit={coinUnit} onCoinUnitSelectionChange={handleCoinUnitChange} metal={metal}/>
      <NavBar metal={metal} setMetal={setMetal}/>
         <div className={metal ? classes.metalWrapper : classes.wrapper}>
          <div 
            style={location.pathname === '/app/account-overview' ? {paddingRight: 0} : {}}
            className={ useMediaQuery('(min-width:900px)') ? classes.contentContainer : classes.contentContainerMobile }>
          <div className={location.pathname === '/app/account-overview' ? classes.metalContent : classes.content}>
          <Suspense fallback={<LoadingScreen />}>
          <Routes>
              <Route path='account-overview' element={<AccountOverviewComp coin={coin} metal={metal}/>} />
              <Route path='sub-accounts/:id/*' element={<SubAccountDetailComp coin={coin} coinUnit={coinUnit} onCoinUnitSelectionChange={handleCoinUnitChange} metal={metal} />} />
              <Route path='sub-accounts' element={<SubAccountsComp metal={metal} coin={coin} />} />
              <Route path='user-management' element={<UserManagementComp coin={coin} metal={metal}/>} />
              <Route path='financial-overview' element={<FinancialOverviewComp metal={metal} coin={coin} coinUnit={coinUnit} onCoinUnitSelectionChange={handleCoinUnitChange} />} />
              <Route path='audit-log' element={<AuditLogView metal={metal}/>} />
              <Route path='help/faqs' Component={() => { window.open('https://faq.foundryusapool.com', '_blank'); navigate(-1); return null; }} />
              <Route path='help/api-documentation' Component={() => { window.open('https://api.foundryusapool.com', '_blank'); navigate(-1); return null; }} />
              <Route path='help/terms-of-service' Component={() => { window.open('https://foundryusapool.com/static/Foundry_USA_Pool_Service_Agreement.pdf', '_blank'); navigate(-1); return null; }} />
              <Route path='help/status' Component={() => { window.open('https://status.foundryusapool.com', '_blank'); navigate(-1); return null; }} />
              {/* Admin access views */}
              <Route element={<AdminGuard />}>
                <Route path='admin/pool-statistics/pool-hashrate' element={<PoolHashrateComp coin={coin} metal={metal} />} />
                <Route path='admin/pool-statistics/blocks' element={<BlocksComp coin={coin} metal={metal} />} />
                <Route path='admin/pool-statistics/pool-daily-hashrate' element={<PoolDailyHashrateComp coin={coin} metal={metal} />} />
                <Route path='admin/all-sub-accounts/:id/*' element={<SubAccountDetailComp coin={coin} coinUnit={coinUnit} onCoinUnitSelectionChange={handleCoinUnitChange} metal={metal} />} />
                <Route path='admin/all-sub-accounts' element={<AllSubAccountsComp coin={coin} metal={metal} />} />
                <Route path='admin/sub-account-financials' element={<AllSubAccountsFinancialComp coin={coin} metal={metal} />} />
                <Route path='admin/group-user' element={<GroupUserComp coin={coin} metal={metal} />} />
              </Route>
              {/* entry point */}
              <Route index element={<AppRedirectionComp coin={coin} />}/>
              <Route path="*" element={<Navigate to="/404 "/>}/>
            </Routes>
          </Suspense>
          </div>
        </div>
      </div>
    
      <Helmet>
      <script src="https://cy3d3zcbp37t.statuspage.io/embed/script.js"></script> 
      <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/8422806.js"></script>
      </Helmet>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default DashboardLayout;
