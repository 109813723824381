import './style.css'
import React, { useState, useEffect } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { EARLIEST_DATE_FOR_PICKERS } from 'src/constants';
import { 
    X,
    Divide,
    Equal,
    ArrowDownWideNarrow,
    ArrowDownCircle,
    Minus,
    Calculator as CalculatorIcon,
    FlaskConical,
    AlertTriangle,
    Info
} from 'lucide-react';
import { 
    Box,
    TextField,
    MenuItem,
    InputAdornment,
    useMediaQuery,
    useTheme,
    Tooltip,
    ClickAwayListener,
    Link,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Skeleton
} from '@mui/material';
import Logo from 'src/components/Logo';
import ExportCSVComponent from 'src/components/ExportCSVComponent';
import { useSnackbar } from 'notistack';
import axios from 'axios'
import { applySorting, getComparator } from 'src/utils/filterAndSortData';
import { TableSortControls } from 'src/components/TableSortControls';
import { makeStyles } from 'tss-react/mui';
import dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc)
dayjs.extend(timezone)

import { useFlag } from "@unleash/proxy-client-react"
import { Navigate } from 'react-router-dom';

const units = [
    {
      value: 'TH/s',
    },
    {
      value: 'PH/s',
    },
    {
      value: 'EH/s',
    },
  ];

  const useStyles = makeStyles()((theme) => ({
    inputField: {
      '& .MuiOutlinedInput-root.Mui-focused': {
        '& fieldset': {
          borderColor: 'var(--accent-11)',
        }
      },
      '& .MuiInputLabel-root.Mui-focused': {
        color: 'var(--accent-11)',
      },
    },
    tagDropDown: {
      background: 'var(--neutral-2)',
      border: '1px solid var(--color-border)'
    },
    dialog: {
        background: 'var(--neutral-2)'
      },
      actionBtn: {
        backgroundColor: "var(--accent-9)",
        color: "var(--accent-9-contrast)",
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: 'var(--accent-9)',
        }
      },
  }));

const Calculator = () => {
    const fpps = useFlag('fpps')
    const [ hashrate, setHashrate ] = useState("100.000")
    const [ hashrateUnit, setHashrateUnit ] = useState("TH/s")
    const [ poolFee, setPoolFee ] = useState("1.73")
    const [ date, setDate ] = useState(dayjs().utc().startOf('day'))
    const [ blockSubsidy, setBlockSubsidy ] = useState(3.125)
    const [ halvingDate, setHalvingDate ] = useState(false)
    const [ collapse, setCollapse ] = useState(false)
    const [ order, setOrder ] = useState('desc');
    const [ orderBy, setOrderBy] = useState('height');
    const [ secondsPerDayHover, setSecondsPerDayHover ] = useState(false)
    const [ twoHover, setTwoHover ] = useState(false)
    const [ networkHover, setNetworkHover ] = useState(false)
    const [ subsidyHover, setSubsidyHover ] = useState(false)
    const [ TOSModal, setTOSModal ] = useState(true)
    const [exportInProcess, setExportInProcess] = useState(false)
    const [loading, setLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const mediumDevice = useMediaQuery(theme.breakpoints.down('md'));
    const {classes} = useStyles();
    const blockSubsidyValues = [50, 25, 12.5, 6.25, 3.125, 1.5625, 0.78125, 0.390625];

    const [ hashRateError, setHashRateError ] = useState(false)
    const [ poolFeeError, setPoolFeeError ] = useState(false)
    
    const [ blocksOfDay, setBlocksOfDay ] = useState([])
    const [ difficultyChangeBlock, setDifficultyChangeBlock ] = useState({
        "block_timestamp": "0",
        "block_height": "0",
        "difficulty": "0",
        "difficulty_change": "0",
    })

    const [totalFees, setTotalFees ] = useState(0)
    const [totalFeesMinusExcluded, setTotalFeesMinusExcluded ] = useState(0)

    const [diffChangeDates, setDiffChangeDates] = useState([])

    useEffect(()=>{ handleColumnSort('height') },[])

    const blockSubsidyByBlockHeight = (blockHeight) => {
        if (typeof blockHeight !== 'number') blockHeight = parseInt(blockHeight)
        switch (true) {
            case (blockHeight >= 1470000 && blockHeight < 1680000):
                return 0.390625;
            case (blockHeight >= 1260000 && blockHeight < 1470000):
                return 0.78125;
            case (blockHeight >= 1050000 && blockHeight < 1260000):
                return 1.5625;
            case (blockHeight >= 840000 && blockHeight < 1050000):
                return 3.125;
            case (blockHeight >= 630000 && blockHeight < 840000):
                return 6.25;
            case (blockHeight >= 420000 && blockHeight < 630000):
                return 12.5;
            case (blockHeight >= 210000 && blockHeight < 420000):
                return 25;
            default:
                return 50;
        }
    }

    const handleColumnSort = tableColumn => {
        const isAsc = order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(tableColumn)
        setBlocksOfDay(data => applySorting(data, getComparator( isAsc ? 'asc' : 'desc', tableColumn)))
    }

    const handleMobileCardsSort = (tableColumn, columnOrder) => {
        setOrder(columnOrder)
        setOrderBy(tableColumn)
        setBlocksOfDay(data => applySorting(data, getComparator( columnOrder, tableColumn)))
    }

    const handleDateChange = (event) => {
        if (event !== null && event.isValid()) setDate(dayjs(event).startOf('day'))
    };
    
    const handleHashrate = (event) => {
        if (isNaN(Number(event.target.value))) return setHashRateError(true)
        if (event.target.value !== '' && !/^\d{0,9}(\.\d{0,3})?$/.test(event.target.value)) return setHashRateError(true)
        else {
            setHashRateError(false)
            setHashrate(event.target.value)
        }
    }

    const handleHashrateUnit = (event) => {
        setHashrateUnit(event.target.value)
    }

    const handlePoolFee = (event) => {
        if (event.target.value > 100) return setPoolFeeError(true)
        if (event.target.value !== '' && !/^\d+(\.\d{0,2})?$/.test(event.target.value)) return setPoolFeeError(true)
        else {
            setPoolFeeError(false)
            setPoolFee(event.target.value)
        }
    }

    const handleUnitCoversion = () => {
        switch (hashrateUnit) {
            case "TH/s":
                return 1000000000000 * hashrate
            case "PH/s":
                return 1000000000000000 * hashrate
            case "EH/s":
                return 1000000000000000000 * hashrate
            default:
                break;
        }
    }

    const handleAggShareDiffCalc = () => {
        return (((handleUnitCoversion() / (2**32)) * 86400).toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 }))
    }

    const handlePPSCalc = () => {
        return (((handleUnitCoversion() / (2**32)) * 86400 / difficultyChangeBlock.difficulty) * blockSubsidy).toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 })
    }

    const handleFeePertCalc= () => {
        return (
            dayjs(date).isBefore(dayjs('2023/11/30'))
            ? ((totalFees / 100000000) / (blocksOfDay.length * blockSubsidy)) * 100
            : ((totalFeesMinusExcluded / 100000000) / ((blocksOfDay.length - 6) * blockSubsidy)) * 100
        )
    }

    const handleFPPSCalc = () => {
        return (
            dayjs(date).isBefore(dayjs('2023/11/30'))
            ? ((totalFees / 100000000) / (blocksOfDay.length * blockSubsidy) + 1 ) * 100
            : ((totalFeesMinusExcluded / 100000000) / ((blocksOfDay.length - 6) * blockSubsidy) + 1 ) * 100
        )
    }

    const handlePayout = () => {
        return (
            handlePPSCalc() * (handleFPPSCalc()/100).toLocaleString('en-US', { minimumFractionDigits: 5, maximumFractionDigits: 5 }) * (1 - (poolFee/100))
        )
    }

    const getBlockBasedOnTime = async () => {
        let block
        try {
            const response = await axios.get(`https://mempool.reddfive.com/api/v1/mining/difficulty-adjustments/`)
            const diffChangeDates = (await response.data.map(i => i[0]).map(j=>dayjs.unix(j).tz('UTC').format('YYYY/MM/DD')))
            await response.data
                .filter((item) => {
                    return (
                        dayjs(date).unix() > item[0]
                    )
                })
                .map((mapItem)=>{
                    if(!block){
                        block = {
                        "block_timestamp": mapItem[0],
                        "block_height": mapItem[1],
                        "difficulty": mapItem[2],
                        "difficulty_change": mapItem[3],
                    }
                }
                    return block
            })
            if (response.status === 200) {
                return {block, diffChangeDates}
            } else {
                throw new Error('FPPS is currently offline');
            } 
        } catch (error) {
            console.error('Error:', error.message);
            enqueueSnackbar('FPPS is currently offline', {
                variant: 'error'
            });
        }
    }

    const getStartingBlockByTimeSelected = async () => {
        let blockHeight;
        try {
            const response = await axios.get(`https://mempool.reddfive.com/api/v1/mining/blocks/timestamp/${dayjs(date).unix()}`)
            if (response.status === 200) {
                if(!(dayjs(date).isSame(response.data, 'day'))){
                    return blockHeight = response.data.height + 1
                }
                else {
                    return blockHeight = response.data.height
                }
            } else {
                throw new Error('FPPS is currently offline');
            } 
        } catch (error) {
            console.error('Error:', error.message);
            enqueueSnackbar('FPPS is currently offline', {
                variant: 'error'
            });
        }
    }

    const getEndingBlockByTimeSelected = async () => {
        let blockHeight;
        try {
            const response = await axios.get(`https://mempool.reddfive.com/api/v1/mining/blocks/timestamp/${dayjs(date).endOf("day").unix()}`)
            if (response.status === 200) {
                if(!(dayjs(date).isSame(response.data, 'day'))){
                    return blockHeight = response.data.height
                }
                else {
                    return blockHeight = response.data.height - 1
                }
            } else {
                throw new Error('FPPS is currently offline');
            } 
        } catch (error) {
            console.error('Error:', error.message);
            enqueueSnackbar('FPPS is currently offline', {
                variant: 'error'
            });
        }
    }
    
    const getBlocksOfDay = async (height)  => {
        let minHeight = height[0] 
        let maxHeight = height[1]
        try {
            const response = await axios.get(`https://mempool.reddfive.com/api/v1/blocks-bulk/${minHeight}/${maxHeight}`);
            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error('FPPS is currently offline');
            }
        } catch (error) {
            console.error('Error:', error.message);
            enqueueSnackbar('FPPS is currently offline', {
                variant: 'error'
            });
        }
    }

    const getCSVData = async () => {
        setExportInProcess(true)
        
        if (blocksOfDay) {
          if(blocksOfDay.length === 0){
            enqueueSnackbar(t('noDataToExport'), {
              variant: 'warning',
            });
            setExportInProcess(false)
            return []
          }
          else{
            setExportInProcess(false)
            return blocksOfDay.map(i => {
              return {
                'Mining Pool': i.pool_slug === "foundryusa" ? "Foundry Pool USA" : 'Other',
                'Blockheight': i.height,
                'Status': i.included ? "Included" : "Excluded",
                'Transaction Fees Per Block': i.total_fee_amt / 100000000,
                'Block Subsidy': blockSubsidyByBlockHeight(i.height),
              }
            });
          }
        }
        else{
          enqueueSnackbar(t('somethingWentWrong'), {
            variant: 'error',
          });
          
          setExportInProcess(false)
          return []
        }
    }

    useEffect(()=>{
        setLoading(true)
        setHalvingDate(false)
        const blockPromise = Promise.resolve(getBlockBasedOnTime());
        blockPromise.then(({block, diffChangeDates}) => {
            setDiffChangeDates(diffChangeDates)
            setDifficultyChangeBlock(block)
        });

        const blockHeightPromise = Promise.all([getStartingBlockByTimeSelected(), getEndingBlockByTimeSelected()]);
        blockHeightPromise.then((value) => {
            return getBlocksOfDay(value)
        }).then(results=> {
            let top3 = results
                .slice()
                .sort((a, b) =>  b.total_fee_amt - a.total_fee_amt)
                .slice(0, 3);
            
            let bot3 = results
            .slice()
            .sort((a, b) =>  b.total_fee_amt - a.total_fee_amt)
            .slice(-3);

            let resultsArr = results.map(item => {
                return (
                    {
                        height: item.height,
                        pool_id: item.pool_id,
                        pool_slug: item.pool_slug,
                        total_fee_amt: item.total_fee_amt,
                        included: true
                    }
                )
            })

            top3.map(x => {
                let item = resultsArr.find(item => {
                    return item.height === x.height
                });
                if (item) {
                    item.included = false
                }   
                return item   
            }); 
            
            bot3.map(x => {
                let item = resultsArr.find(item => {
                    return item.height === x.height
                });
                if (item) {
                    item.included = false
                }   
                return item   
            }); 
            const feeResult = resultsArr.reduce( ( sum, { total_fee_amt } ) => sum + total_fee_amt , 0)
            const excludedResult = resultsArr.filter(item => item.included).reduce( ( sum, { total_fee_amt } ) => sum + total_fee_amt , 0)
            setTotalFees(feeResult)
            setTotalFeesMinusExcluded(excludedResult)
            const sortedBlocksOfDay = applySorting(resultsArr, getComparator('desc', 'height'))
            setBlocksOfDay(sortedBlocksOfDay)
            let blockSubsidySum = sortedBlocksOfDay.reduce((accumulator, currentValue) => accumulator + blockSubsidyByBlockHeight(currentValue.height), 0);
            const avgBlockSubsidy = blockSubsidySum / sortedBlocksOfDay.length
            setBlockSubsidy(avgBlockSubsidy)
            if (!blockSubsidyValues.includes(avgBlockSubsidy)) setHalvingDate(true)
            setLoading(false)
        })
    }, [date])
    
    return (
        <div className='ui-background metal-background'>
        {TOSModal && <div style={{ position: 'absolute', top: '0', right: '0', bottom: '0', left: '0', backdropFilter: 'blur(3px)', zIndex: '1'}}></div>}
        <Dialog
            open={TOSModal }
            PaperProps={{
                className: classes.dialog
            }}
        >
            <div className='m-box'>
                <DialogTitle>
                    <div style={{marginBottom: "10px", display: 'flex', alignItems: 'center'}}>
                        <AlertTriangle size={25} style={{ color: 'var(--color-text-warning)', marginRight: 'var(--space-s)'}} /> 
                        <p className="m-heading is-size-xl is-warning" >Be Advised</p>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div margin={'var(--space-default)'}>
                        <p className='m-text'>By clicking &quot;Agree&quot;, you acknowledge that this tool is for auditing and informational purposes only. By providing this tool, Foundry does not guarantee or warrant any rewards or particular results from the use of this information, nor does it guarantee that this tool is free from errors or bugs. Inaccuracies may occur based on a few factors, including but not limited to difficulty adjustments. Nothing herein shall be construed as legal advice, tax advice, investment advice, financial advice or any other kind of advice. Foundry shall in no way be liable for any losses, damages or costs of any kind, including but not limited to direct or indirect, consequential, incidental or other costs or damages resulting from any actions taken on the basis of this information.</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center',  }}>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className='m-button' onClick={() => setTOSModal(false)}>Agree</button>
                    <button className='m-button is-ghost' onClick={() => window.location.href = '/'}>Decline</button>
                </DialogActions>
            </div>
        </Dialog>
        <div className='m-container'>
            <header className="m-section">
                <div className="m-stack">
                    <Logo style={{width: "150px", marginBottom: 'calc(var(--space-l) * 2)'}}/>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center',  }}>
                        <h2 className="is-size-xxxl m-heading">Payout Calculator</h2> 
                        <div className="m-chip" data-color-status="info" style={{ margin: '0 var(--space-default)'}}>
                            Beta
                        </div>
                    </div>
                    <p className="m-text is-size-l">The Payout Calculator is an auditing tool that helps Foundry USA Pool customers understand daily mining rewards. Through our open-source and data-driven calculator, we aim to simplify payout audits and educate and empower pool members through the breakdown of each calculation component for enhanced transparency and informed decision-making.</p>
                </div>
            </header>
            <section className='m-section'>
                <div style={{marginBottom: "10px", display: 'flex', alignItems: 'center'}}>
                    <p className="m-heading is-size-xl is-accent" style={{ marginRight: 'var(--space-s)'}} >Calculator</p>
                    <CalculatorIcon className='m-text is-accent' size={25} strokeWidth={1} />
                </div>
                {mediumDevice ?
                    <div className='m-box results-box-mobile' style={{ position: 'relative'}}>
                        <p className='m-text is-size-l results-title-mobile' style={{margin: "0 0 var(--space-s) 0"}}>Difficulty Date: </p>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div style={{ display: 'flex', justifyContent: 'center'}}>
                                <DatePicker
                                    value={date}
                                    onChange={handleDateChange}
                                    inputFormat="YYYY-MM-DD"
                                    disableFuture
                                    minDate={dayjs(EARLIEST_DATE_FOR_PICKERS)}
                                    renderInput={(params) => <TextField {...params} />}
                                    sx={{ margin: "var(--space-s)"}}
                                    slotProps={{
                                        textField: {
                                            helperText: halvingDate
                                            ?   
                                                <Link 
                                                    className={"datepicker-warning"}
                                                    target='_blank'
                                                    href="https://explorer.btc.com/en/btc/halving">
                                                    <p style={{
                                                        maxWidth: "230px",
                                                        color: "var(--color-status-warning)",
                                                        margin: "0.5em", 
                                                        transition: 'max-height .3s ease-in-out, opacity .3s ease-in-out',
                                                        maxHeight: halvingDate ? '5rem' : '0',
                                                        opacity: halvingDate ? 1 : 0,
                                                        cursor: halvingDate ? 'pointer' : 'default'
                                                    }}>You have selected a date in which the BTC subsidy changed; this may affect your FPPS Payout calculation. Click to see more.</p>
                                                </Link>
                                            :   diffChangeDates.includes(dayjs(date).format('YYYY/MM/DD')) &&
                                                <Link 
                                                    className={"datepicker-warning"}
                                                    target='_blank'
                                                    href="https://btc.com/stats/diff">
                                                    <p style={{
                                                        maxWidth: "230px",
                                                        color: "var(--color-status-warning)",
                                                        margin: "0.5em", 
                                                        transition: 'max-height .3s ease-in-out, opacity .3s ease-in-out',
                                                        maxHeight: diffChangeDates.includes(dayjs(date).format('YYYY/MM/DD')) ? '5rem' : '0',
                                                        opacity: diffChangeDates.includes(dayjs(date).format('YYYY/MM/DD')) ? 1 : 0,
                                                        cursor: diffChangeDates.includes(dayjs(date).format('YYYY/MM/DD')) ? 'pointer' : 'default'
                                                    }}>You have selected a date in which the difficulty changed; this may affect your FPPS Payout calculation. Click to see more.</p>
                                                </Link>
                                            },
                                        popper: {
                                            sx: {
                                                '& .MuiDateCalendar-root': {
                                                    backgroundColor: 'var(--neutral-2)',
                                                    borderRadius: 'var(--radius-l)'
                                                }
                                            }
                                        },
                                        mobilePaper: {
                                            sx: {
                                                '& .MuiPickersLayout-root': {
                                                    backgroundColor: 'var(--neutral-2)',
                                                }
                                            }
                                        }
                                        }}
                                />
                            </div>
                        </LocalizationProvider>
                            <p className='m-text is-size-l results-title-mobile' style={{margin: "var(--space-xxl) 0 var(--space-s) 0"}}>Aggregate Share Difficulty: </p>
                            <div className='results-equation results-equation-vertical'>
                            <Box
                                component="form"
                                sx={{
                                    display: 'flex',
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexWrap: 'nowrap',
                                }}
                                noValidate
                                autoComplete="off"
                            >
                            <div className='form-input-container'>
                                <TextField
                                    disabled={loading}
                                    sx={{
                                        maxWidth: '18ch'
                                    }}
                                    className={hashRateError ? "errorAnime" : ""}
                                    error={hashRateError}
                                    label="Hashrate"
                                    value={hashrate}
                                    onChange={handleHashrate}
                                    onBlur={() => {
                                        if (!isNaN(Number(hashrate))) setHashRateError(false)
                                        if (/^\d+(\.\d{0,3})?$/.test(hashrate)) setHashRateError(false)
                                    }}
                                    helperText={<p style={{ transition: 'max-height .3s ease-in-out, opacity .3s ease-in-out', maxHeight: hashRateError ? '5rem' : '0', opacity: hashRateError ? 1 : 0 }}>Maximum nine digits and three decimal places</p>}
                                />
                                <TextField
                                    disabled={loading}
                                    sx={{ padding: '0 var(--space-s)'}}
                                    label="Unit"
                                    value={hashrateUnit}      
                                    onChange={handleHashrateUnit}
                                    className={classes.inputField}
                                    variant="outlined"
                                    select
                                    SelectProps={{
                                        native: false,
                                        MenuProps: {
                                        PopoverClasses: {
                                            paper: classes.tagDropDown
                                        }
                                        }
                                    }}
                                    >
                                    {units.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.value}
                                        </MenuItem>
                                    ))}
                                </TextField>

                            </div>
                        </Box>
                            <div className='division-bar' />
                            {loading ? 
                                    <Skeleton variant="rounded" style={{maxWidth: "300px" }} width={"200px"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                :
                                    <div className='multiply-horizontal'>
                                            <span className='m-text is-size-l is-muted' style={{marginLeft: "20px", margin: '0 1.2rem 0 0', position: 'relative' }}>
                                                2
                                                <span className='m-text is-size-l is-muted' style={{position: "absolute", marginBottom: "12px", fontSize: "10px"}}>
                                                    32
                                                </span>
                                            </span> 
                                        <X size={16} className='m-text is-muted' style={{margin: '0 .5rem'}}/> 
                                        <p className='m-text is-size-l is-muted'>86,400</p> 
                                    </div>
                            }
                        </div>
                        <div className='results-equals-mobile'>
                            <p className='m-text is-size-l' style={{display: "flex", justifyContent: "flex-start", alignItems: "center", padding: '0 var(--space-s) 0 0'}}><Equal className='m-text is-muted' size={16}/></p>
                            {loading ? 
                                    <Skeleton variant="rounded" style={{maxWidth: "300px" }} width={"200px"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                :
                                    <div className='m-text is-size-l' style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}><p className='m-text is-size-l is-accent'>{handleAggShareDiffCalc()}</p></div>
                            }
                        </div>
                            
                        <p className='m-text is-size-l results-title-mobile' style={{margin: "var(--space-xxl) 0 0 0"}}>PPS Base: </p>
                        <div className='results-equation results-equation-vertical'>
                            {loading ? 
                                <Skeleton variant="rounded" style={{maxWidth: "300px" }} width={"200px"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                            :
                                <p className='m-text is-size-l' style={{marginRight: '.5rem'}}>{ hashrate ? handleAggShareDiffCalc() : "Aggregate Share Difficulty"}</p> 
                            }
                            <div className='division-bar' />
                            {loading ? 
                                    <Skeleton variant="rounded" style={{maxWidth: "300px" }} width={"200px"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                :
                                    <div className='multiply-horizontal'>
                                        <p className='m-text is-size-l is-muted'>{difficultyChangeBlock.difficulty.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p> 
                                        <X size={16} className='m-text is-muted' style={{margin: '0 .5rem'}} /> 
                                        <p className='m-text is-size-l is-muted' style={{ whiteSpace: 'nowrap'}}>{blockSubsidy.toFixed(8).replace(/\.?0+$/, '')} BTC</p>
                                    </div>
                            }
                        </div>
                        <div className='results-equals-mobile'>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0 var(--space-s) 0 0'}}><Equal size={16} className='m-text is-muted' /> </div>
                            {loading ? 
                                    <Skeleton variant="rounded" style={{maxWidth: "300px" }} width={"200px"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                :
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><p className='m-text is-size-l is-accent' >{handlePPSCalc()}</p></div>
                            }
                        </div>
                            
                        <p className='m-text is-size-l results-title-mobile' style={{margin: "var(--space-xxl) 0 0 0"}}>FPPS Payout Amount:</p>
                        <div className='results-equation results-equation-vertical' style={{ justifyContent: 'center' }}>
                        {loading ? 
                                    <Skeleton variant="rounded" style={{maxWidth: "300px" }} width={"200px"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                :
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <p className='m-text is-size-l'>{hashrate ? handlePPSCalc() : "PPS Base"}</p> 
                                        <X size={16} className='m-text is-muted' style={{margin: '0 var(--space-s)'}}/> 
                                        <p className='m-text is-size-l is-muted'>{(handleFPPSCalc()/100).toLocaleString('en-US', { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</p> 
                                    </div>
                        }
                            <X size={16} className='m-text is-muted' style={{margin: 'var(--space-s) 0'}}/> 
                            <div style={{ position: 'relative'}}>
                                <div style={{ position: 'absolute', display: 'flex', flexDirection: 'row', alignItems: 'center', top: '20px', left: '-5ch' }}>
                                    <p className='m-text is-size-xl is-muted' style={{margin: '0 .25rem 0 0'}}>&#10222;</p>
                                    <p className='m-text is-size-l is-muted' style={{ marginRight: '.25rem'}}>1</p>
                                    <Minus size={16} className='m-text is-size-l is-muted' />
                                </div>
                                    <TextField
                                        disabled={loading}
                                        sx={{
                                            width: '14ch',
                                            margin: 'var(--space-s)'
                                        }}
                                        className={poolFeeError ? "errorAnime" : ""}
                                        error={poolFeeError}
                                        label="Pool Fee"
                                        value={poolFee}
                                        onChange={handlePoolFee}
                                        onBlur={() => {
                                            if (poolFee < 100) return setPoolFeeError(false)
                                        }}
                                        helperText={<p style={{ transition: 'max-height .3s ease-in-out, opacity .3s ease-in-out', maxHeight: poolFeeError ? '5rem' : '0', opacity: poolFeeError ? 1 : 0 }}>Less than 100% and maximum two decimal places</p>}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        }}
                                    />
                                    <div style={{ position: 'absolute', display: 'flex', flexDirection: 'row', alignItems: 'center', top: '20px', right: '-8ch' }}>
                                        <Divide size={16} className='m-text is-muted' style={{marginRight: '.5rem'}}/> 
                                        <p className='m-text is-size-l is-muted'>100</p>
                                        <p className='m-text is-size-xl is-muted' style={{margin: '0 0 0 var(--space-s)'}}>&#10223;</p>
                                    </div>
                            </div>
                        </div>
                        <div className='results-equals-mobile'>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0 var(--space-s) 0 0'}}><Equal size={16} className='m-text is-muted'/> </div>
                            {loading ? 
                                    <Skeleton variant="rounded" style={{maxWidth: "300px" }} width={"200px"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                :
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><p className='m-text is-size-l is-accent'>{handlePayout().toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 })} BTC</p></div>
                            }
                        </div>
                        
                    </div> 
                :
                    <div className='m-box results-box' style={{ position: 'relative'}}>
                        <div className='results-equation'>
                            <p className='m-text is-size-l results-title'>Difficulty Date: </p>
                            <div className='results-value flex-row'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={date}
                                        onChange={handleDateChange}
                                        inputFormat="YYYY-MM-DD"
                                        disableFuture
                                        minDate={dayjs(EARLIEST_DATE_FOR_PICKERS)}
                                        renderInput={(params) => <TextField {...params} />}
                                        sx={{ minWidth: '35ch'}}
                                        slotProps={{
                                            popper: {
                                                sx: {
                                                    '& .MuiDateCalendar-root': {
                                                        backgroundColor: 'var(--neutral-2)',
                                                        borderRadius: 'var(--radius-l)'
                                                    }
                                                }
                                            },
                                            mobilePaper: {
                                                sx: {
                                                    '& .MuiPickersLayout-root': {
                                                        backgroundColor: 'var(--neutral-2)',
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                                    <Link 
                                        sx={{ 
                                            padding: '0 0 0 var(--space-s)'
                                        }}
                                        className={"datepicker-warning"}
                                        target='_blank'
                                        underline={'none'}
                                        href={
                                            halvingDate 
                                            ? "https://explorer.btc.com/en/btc/halving" 
                                            : diffChangeDates.includes(dayjs(date).format('YYYY/MM/DD')) 
                                            ? "https://btc.com/stats/diff" 
                                            : ''}
                                    >
                                        <p 
                                            className='m-text'
                                            style={{
                                                color: "var(--color-status-warning)",
                                                margin: "0.5em", 
                                                transition: 'max-height .3s ease-in-out, opacity .3s ease-in-out',
                                                maxHeight: halvingDate || diffChangeDates.includes(dayjs(date).format('YYYY/MM/DD')) ? '5rem' : '0',
                                                opacity: halvingDate || diffChangeDates.includes(dayjs(date).format('YYYY/MM/DD')) ? 1 : 0,
                                                cursor: halvingDate || diffChangeDates.includes(dayjs(date).format('YYYY/MM/DD')) ? 'pointer' : 'default'
                                            }}
                                        >
                                            { 
                                                halvingDate 
                                                ? 'You have selected a date in which the BTC subsidy changed; this may affect your FPPS Payout calculation. Click to see more.'
                                                : diffChangeDates.includes(dayjs(date).format('YYYY/MM/DD'))
                                                ? 'You have selected a date in which the difficulty changed; this may affect your FPPS Payout calculation. Click to see more.' 
                                                : '' 
                                            }
                                        </p>
                                    </Link>
                            </div>
                        </div>
                        <div className='results-equation'>
                            <div className='results-title' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <p className='m-text is-size-l'>Aggregate Share Difficulty: </p>
                            </div>
                        
                        <div className='flex-row'>
                                <TextField
                                    disabled={loading}
                                    sx={{
                                        width: '25ch',
                                        minWidth: '25ch'
                                    }}
                                    className={hashRateError ? "errorAnime" : ""}
                                    error={hashRateError}
                                    label="Hashrate"
                                    value={hashrate}
                                    onChange={handleHashrate}
                                    onBlur={() => {
                                        if (!isNaN(Number(hashrate))) setHashRateError(false)
                                        if (/^\d+(\.\d{0,3})?$/.test(hashrate)) setHashRateError(false)
                                    }}
                                    helperText={<p style={{ transition: 'max-height .3s ease-in-out, opacity .3s ease-in-out', maxHeight: hashRateError ? '3rem' : '0', opacity: hashRateError ? 1 : 0 }}>Maximum nine digits and three decimal places</p>}
                                />
                                <TextField
                                    disabled={loading}
                                    sx={{ 
                                        padding: '0 var(--space-s)',
                                        width: '15ch',
                                        minWidth: '15ch'
                                    }}
                                    label="Unit"
                                    value={hashrateUnit}      
                                    onChange={handleHashrateUnit}
                                    className={classes.inputField}
                                    variant="outlined"
                                    select
                                    SelectProps={{
                                        native: false,
                                        MenuProps: {
                                            PopoverClasses: {
                                                paper: classes.tagDropDown
                                            }
                                        }
                                    }}
                                    >
                                    {units.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.value}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                    {loading ? 
                                        <Skeleton variant="rounded" style={{maxWidth: "300px" }} width={"200px"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                    :
                                        <>
                                            <Divide size={16} className='m-text is-muted' style={{marginRight: '.5rem'}} />
                                            <span className='m-text is-size-l is-muted' style={{marginLeft: "20px", margin: '0 var(--space-s) 0 0', position: 'relative'}}>
                                                2
                                                <span className='m-text is-size-l is-muted' style={{position: "absolute", marginBottom: "12px", fontSize: "10px"}}>
                                                    32
                                                </span>
                                            </span> 
                                            <X size={16} className='m-text is-muted' style={{margin: '0 .5rem'}}/> 
                                            <p className='m-text is-size-l is-muted'>86,400</p> 
                                            <p className='m-text is-size-l is-muted' style={{display: "flex", justifyContent: "center", alignItems: "center", padding: '0 var(--space-s)'}}><Equal className='m-text is-muted' size={16}/></p>
                                            <div className='m-text is-size-l' style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}><p className='m-text is-size-l is-accent' >{handleAggShareDiffCalc()}</p></div>
                                        </>
                                    }
                            </div>
                        </div>
                            
                        <div className='results-equation'>
                            <p className='m-text is-size-l results-title'>PPS Base: </p>
                            <div className='flex-row'>
                            {loading ? 
                                <Skeleton variant="rounded" style={{maxWidth: "300px" }} width={"200px"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                            :
                                <>
                                    <p className='m-text is-size-l' style={{marginRight: '.5rem'}}>{ hashrate ? handleAggShareDiffCalc() : "Aggregate Share Difficulty"}</p> 
                                    <Divide size={16} className='m-text is-muted' style={{marginRight: '.5rem'}}/> 
                                    <p className='m-text is-size-l is-muted'>{difficultyChangeBlock.difficulty.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p> 
                                    <X size={16} className='m-text is-muted' style={{margin: '0 .5rem'}} /> 
                                    <p className='m-text is-size-l is-muted' style={{ whiteSpace: 'nowrap'}}>{blockSubsidy.toFixed(8).replace(/\.?0+$/, '')} BTC</p> 
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "flex-end", padding: '0 var(--space-s)'}}><Equal size={16} className='m-text is-muted' /> </div>
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><p className='m-text is-size-l is-accent' >{handlePPSCalc()}</p></div>
                                </>
                            }
                            </div>
                        </div>
                            
                        <div className='results-equation'>
                            <p className='m-text is-size-l results-title'>FPPS Payout Amount:</p>
                            <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                {loading ? 
                                    <Skeleton variant="rounded" style={{maxWidth: "300px" }} width={"200px"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                :
                                    <>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                            <p className='m-text is-size-l' style={{marginRight: '.5rem', }}>{hashrate ? handlePPSCalc() : "PPS Base"}</p> 
                                            <X size={16} className='m-text is-muted' style={{marginRight: '.5rem', }}/> 
                                            <p className='m-text is-size-l is-muted'>{(handleFPPSCalc()/100).toLocaleString('en-US', { minimumFractionDigits: 5, maximumFractionDigits: 5 })}</p> 
                                            <X size={16} className='m-text is-muted' style={{margin: '0 .5rem', }}/> 
                                            <p className='m-text is-size-l is-muted' style={{margin: '0 var(--space-s) 0 0', }}>&#10222;</p>
                                            <p className='m-text is-size-l is-muted'>1</p>
                                            <Minus size={16} className='m-text is-muted' style={{margin: '0 .5rem', }}/>
                                        </div>
                                    </>
                                }
                                    <TextField
                                        disabled={loading}
                                        sx={{
                                            width: '20ch',
                                            margin: '0 var(--space-s)',
                                            position: 'relative'
                                        }}
                                        className={poolFeeError ? "errorAnime" : ""}
                                        error={poolFeeError}
                                        label="Pool Fee"
                                        value={poolFee}
                                        onChange={handlePoolFee}
                                        onBlur={() => {
                                            if (poolFee < 100) return setPoolFeeError(false)
                                        }}
                                        helperText={<p style={{ transition: 'max-height .3s ease-in-out, opacity .3s ease-in-out', maxHeight: poolFeeError ? '5rem' : '0', opacity: poolFeeError ? 1 : 0 }}>Less than 100% and maximum two decimal places</p>}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        }}
                                    />
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                {loading ? 
                                    <Skeleton variant="rounded" style={{maxWidth: "300px" }} width={"200px"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                :
                                    <>
                                        <Divide size={16} className='m-text is-muted' style={{marginRight: '.5rem'}}/> 
                                        <p className='m-text is-size-l is-muted'>100</p>
                                        <p className='m-text is-size-l is-muted' style={{margin: '0 0 0 var(--space-s)'}}>&#10223;</p>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "flex-end", padding: '0 var(--space-s)'}}><Equal size={16} className='m-text is-muted'/> </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}><p className='m-text is-size-l is-accent' style={{ whiteSpace: 'nowrap' }}>{handlePayout().toLocaleString('en-US', { minimumFractionDigits: 8, maximumFractionDigits: 8 })} BTC</p></div>
                                    </>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </section>

            <section className='m-section'>
                <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: "flex-start", alignItems: "center", marginBottom: "10px", position: "relative" }}>
                    <p className="m-heading is-size-xl is-accent" style={{ marginRight: 'var(--space-s)'}}>Methodology</p>
                    <FlaskConical className='m-text is-accent' size={23} strokeWidth={1} />
                </div>
                
                <div>
                    <div className='m-box'>
                        {mediumDevice ?
                            <div className='secret-sauce-equations-grid-mobile' style={{display: "flex", flexDirection: 'column'}}>
                                <p className='m-text is-size-l' style={{display: "flex", justifyContent: "flex-start", alignItems: "center", margin: "var(--space-xxl) 0 var(--space-s) 0"}}>Aggregate Share Difficulty: </p>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <p className='m-text is-size-l' style={{ margin: '0 1rem 0 0' }}>Hashrate</p> 
                                    <div className='division-bar' />
                                    <div className='multiply-horizontal' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <ClickAwayListener onClickAway={() => setTwoHover(false)}>
                                            <Tooltip 
                                                title={<p className='m-text'>Bitcoin difficulty is often denoted as the relative difficulty with respect to the genesis block, which required approximately 2^32 hashes.</p>}
                                                open={twoHover}
                                                arrow
                                                placement='top'
                                            >
                                            <span className='two-hover m-text is-size-l' style={{marginLeft: "20px", textDecoration:"underline", textDecorationStyle: "dotted", textUnderlineOffset: "4px", margin: '0 1.2rem 0 0'}} onClick={() => setTwoHover(true)} >
                                                2
                                            <span className='m-text' style={{position: "absolute", marginBottom: "12px", fontSize: "10px"}}>
                                                32
                                            </span>
                                        </span> 
                                            </Tooltip>
                                        </ClickAwayListener>
                                        
                                        <X size={16} className='m-text is-size-l' style={{ margin: '0 .5rem 0 0' }}/> 
                                        <p className='seconds-per-day-hover m-text is-size-l' style={{textDecoration:"underline", textDecorationStyle: "dotted", textUnderlineOffset: "4px" }} onClick={() => setSecondsPerDayHover(true)} >
                                            <ClickAwayListener onClickAway={() => setSecondsPerDayHover(false)}>
                                                <Tooltip 
                                                title={<p className='m-text'>Amount of hashes are measured in hashes per second.</p>}
                                                open={secondsPerDayHover}
                                                arrow
                                                placement='top'
                                                >
                                                    <p className='m-text is-size-l'>Seconds per Day</p>
                                                </Tooltip>
                                            </ClickAwayListener>
                                        </p>
                                    </div>
                                </div>
                                
                                <p className='m-text is-size-l' style={{display: "flex", justifyContent: "flex-start", alignItems: "center", margin: "var(--space-xxl) 0 var(--space-s) 0"}}>PPS Base:</p> 
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: "0 0 var(--space-xxl) 0"}} >
                                    <p className='m-text is-size-l' style={{ margin: '0 1rem 0 0' }}>Aggregate Share Difficulty </p> 
                                    <div className='division-bar' style={{width: '250px'}} />
                                    <div className='multiply-horizontal' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <p className='network-hover m-text' style={{textDecoration:"underline", textDecorationStyle: "dotted", textUnderlineOffset: "4px"}} onClick={() => setNetworkHover(true)} >
                                            <ClickAwayListener onClickAway={() => setNetworkHover(false)}>
                                                <Tooltip 
                                                title={<p className='m-text'>The Network Difficulty based on the selected date.</p>}
                                                open={networkHover}
                                                arrow
                                                placement='top'
                                                >
                                                    <p className='m-text is-size-l' style={{ whiteSpace: 'nowrap'}}>Network Difficulty</p>
                                                </Tooltip>
                                            </ClickAwayListener>
                                        </p> 
                                        <X size={16} className='m-text' style={{ margin: '0 var(--space-s)'}}/> 
                                        <p className='subsidy-hover m-text' style={{textDecoration:"underline", textDecorationStyle: "dotted", textUnderlineOffset: "4px"}} onClick={() => setSubsidyHover(true)} >
                                            <ClickAwayListener onClickAway={() => setSubsidyHover(false)}>
                                                <Tooltip 
                                                title={<p className='m-text'>The current Block Subsidy in BTC.</p>}
                                                open={subsidyHover}
                                                arrow
                                                placement='top'
                                                >
                                                    <p className='m-text is-size-l'>Block Subsidy (BTC)</p>
                                                </Tooltip>
                                            </ClickAwayListener>
                                        </p> 
                                    </div>
                                </div>
                                    <div className='flex-row'>
                                        <Link
                                            className="mobile-info"
                                            target='_blank'
                                            href='https://pool-faq.foundrydigital.com/what-is-foundry-usa-pools-payout-methodology'
                                        >
                                            <p className='m-text is-size-l' style={{display: "flex", textDecoration:"underline", textDecorationStyle: "dotted", textUnderlineOffset: "4px", justifyContent: "flex-start", alignItems: "center", margin: "10px 2px 10px 0"}}>FPPS Payout Amount Calculation: </p> 
                                        </Link>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: "center", alignItems: "center"}}>
                                        <p className='m-text is-size-l'>PPS Base</p> 
                                        <X size={16} style={{ margin: '0 3px'}} className='m-text' /> 
                                        <p className='m-text is-size-l'> FPPS Rate</p> 
                                        <X size={16} style={{ margin: '0 3px'}} className='m-text' /> 
                                        <p style={{fontSize:'20px', marginRight: '.5rem'}} className='m-text is-size-l'>&#10222;</p>
                                        <p className='m-text is-size-l' style={{ whiteSpace: 'nowrap', marginRight: 'var(--space-s)'}}>1 -</p>
                                        <p className='m-text is-size-l'>Pool Fee %</p>
                                        <Divide size={16} className='m-text' style={{margin: '0 .5rem'}}/> 
                                        <p className='m-text is-size-l'>100</p>
                                        <p style={{fontSize:'20px', margin: '0 .5rem'}} className='m-text is-size-l'>&#10223;</p>
                                    </div>
                            </div>
                        :
                            <div className='results-box'>
                                <div className='results-equation'>
                                    <p className='m-text is-size-l results-title'>Aggregate Share Difficulty: </p>
                                    <div className='flex-row'>
                                        <p className='m-text is-size-l' style={{ margin: '0 var(--space-s) 0 0' }}>Hashrate</p> 
                                        <Divide size={16} className='m-text is-size-l' style={{ margin: '0 var(--space-s) 0 0' }} /> 
                                        <ClickAwayListener onClickAway={() => setTwoHover(false)}>
                                            <Tooltip 
                                                title={<p className='m-text'>Bitcoin difficulty is often denoted as the relative difficulty with respect to the genesis block, which required approximately 2^32 hashes.</p>}
                                                open={twoHover}
                                                arrow
                                                placement='top'
                                            >
                                                    <span className='two-hover m-text is-size-l' style={{marginLeft: "20px", textDecoration:"underline", textDecorationStyle: "dotted", textUnderlineOffset: "4px", margin: '0 1.2rem 0 0', cursor: 'default'}} onMouseEnter={() => setTwoHover(true)} onMouseLeave={() => setTwoHover(false)}>
                                                        2
                                                    <span className='m-text' style={{position: "absolute", marginBottom: "12px", fontSize: "10px"}}>
                                                        32
                                                    </span>
                                                </span> 
                                            </Tooltip>
                                        </ClickAwayListener>
                                        <X size={16} className='m-text is-size-l' style={{ margin: '0 var(--space-s) 0 0' }}/> 
                                        <p className='seconds-per-day-hover m-text is-size-l' style={{textDecoration:"underline", textDecorationStyle: "dotted", textUnderlineOffset: "4px", cursor: 'default' }} onMouseEnter={() => setSecondsPerDayHover(true)} onMouseLeave={() => setSecondsPerDayHover(false)}>
                                            <ClickAwayListener onClickAway={() => setSecondsPerDayHover(false)}>
                                                <Tooltip 
                                                    title={<p className='m-text'>Amount of hashes are measured in hashes per second.</p>}
                                                    open={secondsPerDayHover}
                                                    arrow
                                                    placement='top'
                                                >
                                                    <p className='m-text is-size-l'>Seconds per Day</p>
                                                </Tooltip>
                                            </ClickAwayListener>
                                        </p>
                                    </div>
                                </div>
                                
                                <div className='results-equation'>
                                    <p className='m-text is-size-l results-title'>PPS Base:</p> 
                                    <div className='flex-row'>
                                        <p className='m-text is-size-l' style={{ margin: '0 var(--space-s) 0 0' }}>Aggregate Share Difficulty </p> 
                                        <Divide size={16} className='m-text is-size-l' style={{ margin: '0 var(--space-s) 0 0' }} /> 
                                        <p className='network-hover m-text is-size-l' style={{textDecoration:"underline", textDecorationStyle: "dotted", textUnderlineOffset: "4px", cursor: 'default'}} onMouseEnter={() => setNetworkHover(true)} onMouseLeave={() => setNetworkHover(false)}>
                                            <ClickAwayListener onClickAway={() => setNetworkHover(false)}>
                                                <Tooltip 
                                                    title={<p className='m-text'>The Network Difficulty based on the selected date.</p>}
                                                    open={networkHover}
                                                    arrow
                                                    placement='top'
                                                >
                                                    <p className='m-text is-size-l'>Network Difficulty</p>
                                                </Tooltip>
                                            </ClickAwayListener>
                                        </p> 
                                        <X size={16} className='m-text is-size-l' style={{ margin: '0 var(--space-s) 0 var(--space-s)' }}/> 
                                        <p className='subsidy-hover m-text is-size-l' style={{textDecoration:"underline", textDecorationStyle: "dotted", textUnderlineOffset: "4px", cursor: 'default'}} onMouseEnter={() => setSubsidyHover(true)} onMouseLeave={() => setSubsidyHover(false)}>
                                            <ClickAwayListener onClickAway={() => setSubsidyHover(false)}>
                                                <Tooltip 
                                                    title={<p className='m-text'>The current Block Subsidy in BTC.</p>}
                                                    open={subsidyHover}
                                                    arrow
                                                    placement='top'
                                                >
                                                    <p className='m-text is-size-l'>Block Subsidy (BTC)</p>
                                                </Tooltip>
                                            </ClickAwayListener>
                                        </p> 
                                    </div>
                                </div>
                                <div className='results-equation' style={{ position: 'relative'}}>
                                    <p className='m-text is-size-l results-title'>FPPS Payout Amount Calculation: </p> 
                                    <div style={{ position: 'absolute', top: '3rem', left: '6rem'}}>
                                        <Link
                                            target='_blank'
                                            href='https://pool-faq.foundrydigital.com/what-is-foundry-usa-pools-payout-methodology'
                                        >
                                            <Info size={16} className='m-text' />
                                        </Link>
                                    </div>
                                    <div className='flex-row'>
                                        <p className='m-text is-size-l' style={{ whiteSpace: 'nowrap'}}>PPS Base</p> 
                                        <X size={16} style={{marginLeft: "var(--space-s)"}} className='m-text is-size-l' /> 
                                        <p style={{marginLeft: "var(--space-s)", whiteSpace: 'nowrap'}} className='m-text is-size-l'> FPPS Rate</p> 
                                        <X size={16} style={{marginLeft: "var(--space-s)"}} className='m-text is-size-l' /> 
                                        <p style={{fontSize:'20px', marginLeft: "var(--space-s)"}} className='m-text is-size-l'>&#10222;</p>
                                        <p style={{marginLeft: "var(--space-s)", whiteSpace: 'nowrap'}} className='m-text is-size-l'>1</p>
                                        <Minus size={16} className='m-text' style={{margin: '0 var(--space-s)'}}/>
                                        <p style={{marginLeft: "var(--space-s)", whiteSpace: 'nowrap'}} className='m-text is-size-l'>Pool Fee %</p>
                                        <Divide size={16} className='m-text' style={{margin: '0 0 0 .5rem'}}/> 
                                        <p style={{marginLeft: "var(--space-s)", whiteSpace: 'nowrap'}} className='m-text is-size-l'>100</p>
                                        <p style={{fontSize:'20px', marginLeft: "var(--space-s)"}} className='m-text is-size-l'>&#10223;</p>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </section>
            <section className='m-section'>
                <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: "flex-start", alignItems: "center", marginBottom: "10px", position: "relative" }}>
                    <p className="m-heading is-size-xl is-accent" style={{ marginRight: 'var(--space-s)'}}>Calculation Breakdown</p>
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2,2fr)', gridTemplateRows: 'repeat(2,2fr)'}}>
                        <X size={12} className='m-text is-accent' />
                        <Divide size={12} className='m-text is-accent'/>
                        <Minus size={12} className='m-text is-accent' />
                        <Equal size={12} className='m-text is-accent' />
                    </div>
                </div>
                
                <div>
                    <div className='m-box'>
                        {!mediumDevice && 
                            <ExportCSVComponent 
                                fileName={"FPPS Rate"}
                                exportInProcess={exportInProcess} 
                                getCSVData={getCSVData}
                                singleDate={true}
                                reportDate={dayjs(date).format('MMMM DD, YYYY')}
                                reportName="FPPS Rate"
                                groupReport={true}
                                pdfOption={false}
                                variant={"contained"}
                            />
                        }
                        {mediumDevice && 
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <TableSortControls 
                                selectorValues={{
                                    clear: 'Clear',
                                    height: 'Blockheight',
                                    included: 'Status',
                                    total_fee_amt: 'Transaction Fees Per Block'
                                }}
                                onChange={handleMobileCardsSort}
                                defaultSort='height'
                                defaultOrder='desc'
                                metal
                            />
                            <ExportCSVComponent 
                                fileName={"FPPS Rate"}
                                exportInProcess={exportInProcess} 
                                getCSVData={getCSVData}
                                singleDate={true}
                                reportDate={dayjs(date).format('MMMM DD, YYYY')}
                                reportName="FPPS Rate"
                                groupReport={true}
                                pdfOption={false}
                                variant={"contained"}
                            />
                        </div>
                    }
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <p className='m-text is-size-l' style={{ margin: "var(--space-s) 0" }}>FPPS Rate: </p>
                        <div className='click-to-collapse' style={{ opacity: collapse ? 1 : 0 }} >
                            <div onClick={() => setCollapse(false)} style={{ display: "flex", alignItems: "center", cursor: collapse ? 'pointer' : 'default' }}>
                                <ArrowDownCircle className='m-text up-arrow' size={16} style={{ color: 'var(--color-text-link-on-danger)', transform: collapse ? 'rotate(180deg)' : 'rotate(360deg)' }}/>
                                <p className='m-text' style={{ color: 'var(--color-text-link-on-danger)'}}>Click to collapse</p>
                            </div>
                        </div>
                    </div>
                    {!mediumDevice && 
                        <div style={{ position: 'relative'}}>
                            <div className='click-to-expand' style={{flex: 1, display: 'flex', flexDirection: 'row',  justifyContent: "center", alignItems: "center", opacity: collapse ? 0 : 1, cursor: collapse ? 'default' : 'pointer'}} onClick={() => setCollapse(true)}>
                                <p className='m-text'>Click to expand</p>
                                <ArrowDownCircle className='m-text down-arrow' size={16}/>
                            </div>
                            <div className={collapse ? 'animate-collapsible open-collapsible' : 'animate-collapsible collapsible'}>
                                <table className={'m-table m-text table'}>
                                    <thead className="m-table-header table-header">
                                        <tr className="m-table-row">
                                            <th className="m-table-cell m-table-column-header-cell">
                                                <p className='m-text'>Mining Pool</p>
                                            </th>
                                            <th className="m-table-cell m-table-column-header-cell" 
                                                onClick={() => handleColumnSort('height')}
                                                title='Sort by blockheight'
                                            >
                                                <div className='sort-label'>
                                                    <p>Blockheight</p>
                                                    <ArrowDownWideNarrow className='sort-arrow' style={{ transform: order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)', opacity: orderBy === 'height' ? 1 : 0 }} />
                                                </div>
                                            </th>
                                            
                                            <th className="m-table-cell m-table-column-header-cell" 
                                                onClick={() => handleColumnSort('included')}
                                                title='Sort by status'
                                            >
                                                <div className='sort-label'>
                                                    <p className='m-text'>Status</p>
                                                    <ArrowDownWideNarrow className='sort-arrow' style={{ transform: order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)', opacity: orderBy === 'included' ? 1 : 0 }} />
                                                </div>
                                            </th>
                                            
                                            <th className="m-table-cell m-table-column-header-cell" 
                                                onClick={() => handleColumnSort('total_fee_amt')}
                                                title='Sort by transaction fees per block'
                                                style={{ width: '30ch' }}
                                            >
                                                <div className='sort-label'>
                                                    <p className='m-text'>Transaction Fees Per Block (BTC)</p>
                                                    <ArrowDownWideNarrow className='sort-arrow' style={{ transform: order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)', opacity: orderBy === 'total_fee_amt' ? 1 : 0 }} />
                                                </div>
                                            </th>

                                            <th className="m-table-cell m-table-column-header-cell"
                                            >
                                                <p className='m-text'>Block Subsidy (BTC)</p>
                                            </th>
                                        </tr>
                                    </thead>
                                    
                                    <tbody className="m-table-body">
                                        {
                                            blocksOfDay.map(row=> {
                                                return (
                                                    <tr key={row.height} className="m-table-row" style={{ backgroundColor: row.pool_slug === "foundryusa" ? "#172921 !important" : "", color: !row.included && !dayjs(date).isBefore(dayjs('2023/11/30')) ? "#adb5b277" : '' }}>
                                                        <td className="m-table-cell">
                                                            {loading ? 
                                                                <Skeleton variant="rounded" width={"100%"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                                            :
                                                                row.pool_slug === "foundryusa" ?
                                                                    <div className="m-avatar is-size-xxl is-rounded" title="Foundry USA Pool">
                                                                        <img className="m-avatar-img" style={{ filter: !row.included && !dayjs(date).isBefore(dayjs('2023/11/30')) ? "brightness(0.3)" : '' }} src={row.pool_slug === "foundryusa" ? '/android-chrome-192x192.png' : ""} alt="pool icon" />
                                                                    </div> 
                                                                    : <p className="m-text is-size-s" style={{ color: !row.included && !dayjs(date).isBefore(dayjs('2023/11/30')) ? "#adb5b277" : '' }}>Other</p>
                                                                
                                                            }
                                                        </td>
                                                        <td className="m-table-cell">
                                                            {loading ? 
                                                                <Skeleton variant="rounded" width={"100%"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                                            :
                                                                row.height
                                                            }
                                                        </td>
                                                        
                                                        <td className="m-table-cell">
                                                            {loading ? 
                                                                <Skeleton variant="rounded" width={"100%"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                                            :
                                                                dayjs(date).isBefore(dayjs('2023/11/30')) ? "Included" : row.included ? "Included" : "Excluded"
                                                            }
                                                        </td>
                                                        
                                                        <td className="m-table-cell">
                                                            {loading ? 
                                                                <Skeleton variant="rounded" width={"100%"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                                            :
                                                                row.total_fee_amt / 100000000
                                                            }
                                                        </td>

                                                        <td className="m-table-cell">
                                                            {loading ? 
                                                                <Skeleton variant="rounded" width={"100%"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                                            :
                                                                blockSubsidyByBlockHeight(row.height)
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className={collapse ? 'gradient-off' : 'gradient-on'} onClick={() => setCollapse(true)}></div>
                        </div>
                    }
                    {mediumDevice && 
                        <div style={{ position: 'relative'}}>
                            <div style={{border: "none"}} className={collapse ? 'animate-collapsible open-collapsible' : 'animate-collapsible collapsible'}>
                                {applySorting(blocksOfDay, getComparator(order, orderBy)).map((row) => {
                                    return (
                                        <div key={row.height} className="m-box mobile-card" style={row.included ? {} : {border: "1px solid var(--color-text-link-on-danger)"}}>
                                            <div className='card-rows' style={{justifyContent: "space-between"}}>
                                                <p className="m-text">Mining Pool</p>
                                                {loading ? 
                                                    <Skeleton variant="rounded" width={"100%"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                                :
                                                    row.pool_slug === "foundryusa" ?
                                                        <div className="m-avatar is-size-xxl is-rounded" title="Foundry USA Pool">
                                                            <img className="m-avatar-img" src={row.pool_slug === "foundryusa" ? '/android-chrome-192x192.png' : ""} alt="pool icon" />
                                                        </div> 
                                                        : <p className="m-text is-size-s">Other</p>
                                                }
                                            </div>
                                            <div className='card-rows' style={{justifyContent: "space-between"}}>
                                                <p className="m-text">Blockheight</p>
                                                {loading ? 
                                                    <Skeleton variant="rounded" width={"100%"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                                :
                                                    <p className="m-text">{row.height}</p>
                                                }
                                            </div>
                                            <div className='card-rows' style={{justifyContent: "space-between"}}>
                                                <p className="m-text">Status</p>
                                                {loading ? 
                                                    <Skeleton variant="rounded" width={"100%"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                                :
                                                    <p className="m-text" style={row.included ? {} : {color: "var(--color-text-link-on-danger)"}}>{row.included ? "Included" : "Excluded"}</p>
                                                }
                                            </div>
                                            <div className='card-rows' style={{justifyContent: "space-between"}}>
                                                <p className="m-text">Transaction Fees Per Block</p>
                                                {loading ? 
                                                    <Skeleton variant="rounded" width={"100%"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                                :
                                                    <p className="m-text">{row.total_fee_amt / 100000000}</p>
                                                }
                                            </div>
                                            <div className='card-rows' style={{justifyContent: "space-between"}}>
                                                <p className="m-text">Block Subsidy</p>
                                                {loading ? 
                                                    <Skeleton variant="rounded" width={"100%"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                                :
                                                    <p className="m-text">{blockSubsidyByBlockHeight(row.height)}</p>
                                                }
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='click-to-expand' style={{flex: 1, display: 'flex', flexDirection: 'row',  justifyContent: "center", alignItems: "center", opacity: collapse ? 0 : 1, cursor: collapse ? 'default' : 'pointer'}} onClick={() => setCollapse(true)}>
                                <p className='m-text'>Click to expand</p>
                                <ArrowDownCircle className='m-text down-arrow' size={16} style={{ transform: collapse ? 'rotate(180deg)' : 'rotate(0deg)' }}/>
                            </div>
                            <div className={collapse ? 'gradient-off' : 'gradient-on'} onClick={() => setCollapse(true)}></div>
                        </div>
                    }

                    {mediumDevice ?
                        <div className='calc-brkdn-ttls-container-mobile' style={{  display:"flex", flexDirection:"column", justifyContent: "space-between"}}>
                            <div style={{  display:"flex", justifyContent: "space-between", alignItems: "center"}}>
                                <p className='m-text is-size-l calc-ttls-label' style={{display: "flex", justifyContent: "flex-start", alignItems: "center", margin: "10px 0"}}>Total Network Blocks:</p>
                                {loading ? 
                                    <Skeleton variant="rounded" style={{maxWidth: "300px" }} width={"20ch"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                :
                                    <p className='m-text is-size-l calc-ttls-value' style={{margin: "10px 0"}}>{(blocksOfDay.length)}</p>
                                }
                            </div>
                            <div style={{  display:"flex", justifyContent: "space-between", alignItems: "center"}}>
                                <p className='m-text is-size-l calc-ttls-label' style={{display: "flex", justifyContent: "flex-start", alignItems: "center", margin: "10px 0"}}>Total Blocks Used For The FPPS Rate Calculation:</p>
                                {loading ? 
                                    <Skeleton variant="rounded" style={{maxWidth: "300px" }} width={"20ch"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                :
                                    <p className='m-text is-size-l calc-ttls-value' style={{margin: "10px 0"}}>{ dayjs(date).isBefore(dayjs('2023/11/30')) ? blocksOfDay.length : blocksOfDay.length - 6 }</p>
                                }
                            </div>
                            <div style={{  display: dayjs(date).isBefore(dayjs('2023/11/30')) ? "none" : "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <p className='m-text is-size-l calc-ttls-label' style={{display: "flex", justifyContent: "flex-start", alignItems: "center", margin: "10px 0"}}>Total Fees Used For The FPPS Rate Calculation:</p>
                                {loading ? 
                                    <Skeleton variant="rounded" style={{maxWidth: "300px" }} width={"20ch"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                :
                                    <p className='m-text is-size-l calc-ttls-value' style={{margin: "10px 0"}}>{totalFeesMinusExcluded / 100000000}</p>
                                }
                            </div>
                            <div style={{  display:"flex", justifyContent: "space-between", alignItems: "center"}}>
                                <p className='m-text is-size-l calc-ttls-label' style={{display: "flex", justifyContent: "flex-start", alignItems: "center", margin: "10px 0"}}>Total Subsidy For The FPPS Rate Calculation:</p>
                                {loading ? 
                                    <Skeleton variant="rounded" style={{maxWidth: "300px" }} width={"20ch"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                :
                                    <p className='m-text is-size-l calc-ttls-value' style={{margin: "10px 0"}}>{ dayjs(date).isBefore(dayjs('2023/11/30')) ? (blocksOfDay.length * blockSubsidy).toFixed(4).replace(/\.?0+$/, '') : ((blocksOfDay.length - 6) * blockSubsidy).toFixed(4).replace(/\.?0+$/, '') } BTC</p>
                                }
                            </div>
                            <div style={{  display:"flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div className='flex-row'>
                                    <Link
                                        className="mobile-info"
                                        target='_blank'
                                        href='https://pool-faq.foundrydigital.com/how-is-fpps-rate-calculated'
                                    >
                                        <p className='m-text is-size-l calc-ttls-label' style={{display: "flex", justifyContent: "flex-start", alignItems: "center", margin: "2px 2px 10px 0", textDecoration:"underline", textDecorationStyle: "dotted", textUnderlineOffset: "4px"}}>FPPS Rate:</p>
                                    </Link>
                                </div>
                                {loading ? 
                                    <Skeleton variant="rounded" style={{maxWidth: "300px" }} width={"20ch"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                :
                                    <p className='m-text is-size-l calc-ttls-value' style={{margin: "10px 0"}}>{handleFPPSCalc().toLocaleString('en-US', {minimumFractionDigits:3, maximumFractionDigits: 3}) }%</p>
                                }
                            </div>
                        </div>
                    :
                        <div className='results-box'>
                            <div className='results-equation totals'>
                                <p className='m-text is-size-l'>Total Network Blocks:</p>
                                {loading ? 
                                    <Skeleton variant="rounded" style={{maxWidth: "300px" }} width={"200px"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                :
                                    <p className='m-text is-size-l'>{ blocksOfDay.length }</p>
                                }
                            </div>
                            <div className='results-equation totals'>
                                <p className='m-text is-size-l'>Total Blocks Used For The FPPS Rate Calculation:</p>
                                {loading ? 
                                    <Skeleton variant="rounded" style={{maxWidth: "300px" }} width={"200px"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                :
                                    <p className='m-text is-size-l'>{ dayjs(date).isBefore(dayjs('2023/11/30')) ? blocksOfDay.length : blocksOfDay.length - 6 }</p>
                                }
                            </div>
                            <div className='results-equation totals' style={{ display: dayjs(date).isBefore(dayjs('2023/11/30')) && 'none' }}>
                                <p className='m-text is-size-l'>Total Fees Used For The FPPS Rate Calculation:</p>
                                {loading ? 
                                    <Skeleton variant="rounded" style={{maxWidth: "300px" }} width={"200px"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                :
                                    <p className='m-text is-size-l'>{ totalFeesMinusExcluded / 100000000 }</p>
                                }
                            </div>
                            <div className='results-equation totals'>
                                <p className='m-text is-size-l'>Total Subsidy For The FPPS Rate Calculation:</p>
                                {loading ? 
                                    <Skeleton variant="rounded" style={{maxWidth: "300px" }} width={"200px"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                :
                                    <p className='m-text is-size-l'>{ dayjs(date).isBefore(dayjs('2023/11/30')) ? (blocksOfDay.length * blockSubsidy).toFixed(4).replace(/\.?0+$/, '') : ((blocksOfDay.length - 6) * blockSubsidy).toFixed(4).replace(/\.?0+$/, '') } BTC</p>
                                }
                            </div>
                            <div className='results-equation totals' style={{position: 'relative'}}>
                                    <p className='m-text is-size-l' style={{margin: "var(--space-l) 4px var(--space-l) 0"}}>FPPS Rate:</p>
                                    <div style={{position: 'absolute', top:'var(--space-l)', left: '11ch'}}>
                                        <Link
                                            target='_blank'
                                            href='https://pool-faq.foundrydigital.com/how-is-fpps-rate-calculated'
                                        >
                                            <Info size={16} className='m-text' />
                                        </Link>
                                    </div>
                                {loading ? 
                                    <Skeleton variant="rounded" style={{maxWidth: "300px" }} width={"200px"} height={43} sx={{ bgcolor: 'var(--neutral-7)' }}/>
                                :
                                    <p className='m-text is-size-l'>{handleFPPSCalc().toLocaleString('en-US', {minimumFractionDigits:3, maximumFractionDigits: 3}) }%</p>
                                }
                            </div>
                        </div>
                    }
                    </div>
                </div>
            </section>
            <section className='m-section' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <button className='m-button is-ghost' onClick={() => window.location.href = 'mailto:support@foundrydigital.com'}>Give us feedback</button>
            </section>
        </div>
    </div>
    )
  };
  
  export default Calculator;