import React, {
  useRef,
  useState
} from 'react';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Menu,
  MenuItem,
  Typography,
  Switch,
  Divider,
  useMediaQuery,
  useTheme
} from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { BTC, SATS, BCH } from '../../../constants'
import useSelectedSubAccount from 'src/hooks/useSelectedSubAccount';
import { makeStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';
import { Settings, LogOut } from 'lucide-react';

const useStyles = makeStyles()((theme) => ({
  avatar: {
    height: 32,
    width: 32
  },
  popover: {
    width: 200,
    background: `${theme.palette.background.default} !important`
  },
  menuItem: {
    padding: theme.spacing(2)
  },
  list: {
    padding: theme.spacing(0)
  },
  accountOpen: {
    padding: "0 5px"
  },
  metalPopover: {
    marginTop: 0,
    width: 210,
    backgroundColor: "var(--neutral-2)",
    paddingTop: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0
  },
  metalMenuItem: {
    padding: theme.spacing(1)
  },
  metalAccountOpen: {
    backgroundColor: "var(--neutral-2)", 
    padding: "0 5px", 
    height: "50px", 
    marginRight: 0,
    borderTopRightRadius: "4px",
    borderTopLeftRadius: "4px",
    boxShadow: "0 0 1px 0 rgba(0,0,0,0.70), 0 5px 8px -2px rgba(0,0,0,0.50);"
  }
}));

const Account = (props) => {
  const { t }  = useTranslation();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const ref = useRef(null);
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false); 
  const coinUnit = props.coinUnit
  const coin = props.coin
  const metal = props.metal
  const handleCoinUnitChange = props.onCoinUnitSelectionChange
  const { selectedSubAccount } = useSelectedSubAccount();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('lg'));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {

      //clearing the selected drop down values from session storage
      sessionStorage.clear()

      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t('unableToLogout'), {
        variant: 'error'
      });
    }
  };

  const handleCoinUnitChangeCheck = (event) => {
    handleCoinUnitChange(event.target.checked ? SATS : coin == BTC ? BTC : BCH)
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
        disableRipple={metal}
        className={metal ? isOpen ? classes.metalAccountOpen : classes.accountOpen : null }
      >
        <Avatar
          alt="User"
          className={classes.avatar}
          src={user.avatar}
          style={ !mobileDevice ? {marginRight: theme.spacing(1)} :  {marginRight: theme.spacing(0.5) }}
        />
        {
          !mobileDevice && <Typography
          variant="h6"
          color="textPrimary"
          >
            {user.name}
          </Typography>
        }
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: metal ? classes.metalPopover : classes.popover }}
        anchorEl={ref.current}
        open={isOpen}
        classes={{list:classes.list}}
      >
        {metal && <Divider></Divider>}
        <MenuItem className={metal ? classes.metalMenuItem  : classes.menuItem}>
          <div style={metal ? {display: "flex", justifyContent: "center", alignItems: "center"} : {}}>
            {metal && <Settings  size={18} style={{marginRight: theme.spacing(2)}}/>}
            <label style={metal ? {marginRight: 5} : {}}>{coin == BTC ? BTC : BCH}</label>
            <Switch
              size={metal ? "small": "medium"}
              onChange={handleCoinUnitChangeCheck}
              checked={coin == coinUnit ? false : true}
              disabled={window.location.pathname.includes("admin/") && (selectedSubAccount === 'No Sub-Account Selected')}
              key="coinUnitSwitch">
            </Switch>
            <label style={metal ? {marginLeft: 5} : {}}>{SATS}</label>
          </div>
        </MenuItem>
        {!metal && <Divider></Divider>}
        <MenuItem className={metal ? classes.metalMenuItem : classes.menuItem} onClick={handleLogout}>
          <div style={metal ? {display: "flex", justifyContent: "center", alignItems: "center"} : {}}>
            {metal && <LogOut size={18} style={{marginRight: theme.spacing(2)}}/>}
            {t('logout')}
          </div>
        </MenuItem>
      </Menu>
    </>
  );
}

export default Account;
